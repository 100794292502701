<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h4 class="col-12 text-center mx-0">{{ refinement.name }}</h4>
    <hr class="col-12 mx-0 px-0" />
    <h5 class="col-12 mx-0 px-0 text-center">Valores</h5>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Péssimo</th>
              <th scope="col">Mal-feito</th>
              <th scope="col">Bom</th>
              <th scope="col">Excepcional</th>
              <th scope="col">Sobrenatural</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-9"
                    v-model="refinement.quality_1"
                    :id="refinement.id+'_quality_1'"
                  /><p class="ml-2" v-if="isChance()">%</p>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-9"
                    v-model="refinement.quality_2"
                    :id="refinement.id+'_quality_2'"
                  /><p class="ml-2" v-if="isChance()">%</p>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-9"
                    v-model="refinement.quality_3"
                    :id="refinement.id+'_quality_3'"
                  /><p class="ml-2" v-if="isChance()">%</p>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-9"
                    v-model="refinement.quality_4"
                    :id="refinement.id+'_quality_4'"
                  /> <p class="ml-2" v-if="isChance()"> %</p>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-9"
                    v-model="refinement.quality_5"
                    :id="refinement.id+'_quality_5'"
                  /><p class="ml-2" v-if="isChance()">%</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 mx-0 px-0 my-2" v-if="isDamage()">
        <p class="ml-2">Dano inicial: </p><input type="numeric" v-model="example_initial_damage">

        <p class="ml-2">({{example_weapon_level}}) Nivel da arma: </p><input type="range" min="1" max="11" v-model="example_weapon_level" class="slider col-4 my-0 px-0">

        <p class="ml-2">Péssimo: {{ (example_initial_damage * ((1+parseFloat(refinement.quality_1))**example_weapon_level)).toFixed(2)}}</p>
        <p class="ml-2">Mal-feito: {{ (example_initial_damage * ((1+parseFloat(refinement.quality_2))**example_weapon_level)).toFixed(2)}}</p>
        <p class="ml-2">Bom: {{ (example_initial_damage * ((1+parseFloat(refinement.quality_3))**example_weapon_level)).toFixed(2)}}</p>
        <p class="ml-2">Exceptional: {{ (example_initial_damage * ((1+parseFloat(refinement.quality_4))**example_weapon_level)).toFixed(2)}}</p>
        <p class="ml-2">Sobrenatural: {{ (example_initial_damage * ((1+parseFloat(refinement.quality_5))**example_weapon_level)).toFixed(2)}}</p>
      </div>

    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateRefinement()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>
        </transition-group>

      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>
        </transition-group>

      </div>
  </div>
</template>
<script>
import Refinement from '../../mixins/RefinementMixin'
export default {
  name: "RefinementCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      refinement: this.p_refinement,
      is_armor: false,
      example_initial_damage: 2.0,
      example_weapon_level: 5,
    };
  },
  props: {
    p_refinement: Object
  },
  mixins: [Refinement],
  methods: {
    isDamage(){
      return this.refinement.name.includes("Dano por");
    },
    isChance(){
      return this.refinement.name.includes("Chance");
    },
    updateRefinement(){
      let quality_1 = this.refinement.quality_1
      let quality_2 = this.refinement.quality_2
      let quality_3 = this.refinement.quality_3
      let quality_4 = this.refinement.quality_4
      let quality_5 = this.refinement.quality_5

      this.updateRefinementRequest(this.refinement.id,{
        refinement: {
          quality_1,quality_2,quality_3,quality_4,quality_5
        }})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Refinamento atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar este valor.")

          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  }
};
</script>
