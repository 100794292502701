<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-center">
    <h4 class="col-12 text-center mx-0 px-2 my-0">{{getSkillName()}}</h4>
    <hr class="col-12 mx-0 px-0 my-0">
    <div v-for="property in getChangedProperties()" :key="property" class="col-12 mx-0 px-0 row my-2 py-0 justify-content-center">

      <div class="col-12 mx-0 px-0 my-2 py-0 row justify-content-center text-center">
        <h5 class="col-12 text-center mx-0 px-2 my-0">{{getPerkName(property)}}</h5>
         <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Valor Nivel 1</th>
              <th scope="col">Valor Nivel 15</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"> Antes </th>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                 {{ getChangeForProperty(property).old_initial_value }} <span class="col-2 text-left"> {{ getUnity(property) }}</span>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                 {{ getChangeForProperty(property).old_final_value }}<span class="col-2 text-left"> {{ getUnity(property) }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row"> Depois </th>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                 {{ getChangeForProperty(property).new_initial_value }} <span class="col-2 text-left"> {{ getUnity(property) }}</span>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                 {{ getChangeForProperty(property).new_final_value }}<span class="col-2 text-left"> {{ getUnity(property) }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br>
    Alteração feita por: {{skillLogAttributes.user.nickname}}

  </div>
</template>
<script>
import SkillLog from './../../mixins/SkillLogMixin'
export default {
  name: "SkillLogCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      skillLogAttributes: this.skill_log,
      changes: {}
    };
  },
  props: {
    skill_log: Object,
  },
  mixins: [SkillLog],
  methods: {
    getChangedProperties(){
      let props = []
      if (!this.skillLogAttributes || !this.skillLogAttributes.old_perks) {
        return [];
      }
      let changed_keys = Object.keys(this.skillLogAttributes.old_perks)
      for (let i = 0; i < changed_keys.length; i++){
        let property = changed_keys[i]
        if (!["id", "created_at", "updated_at", "skill"].includes(property)) {
          props.push(property)
        }
      }

      return props
    },
    getSkillName(){
      for (let i = 0; i < this.$parent.skill_names.length; i++){
        let skill_name = this.$parent.skill_names[i]
        if (skill_name.skill_code == this.skillLogAttributes.skill_code){
          return skill_name.name
        }
      }

      return "---"
    },
    getPerkName(property){
      if (property == "mana") return "Mana";

      if (property == "cooldown") return "Cooldown";
      for (let i = 0; i < this.$parent.perk_names.length; i++){
        let perk_name = this.$parent.perk_names[i]

        if (perk_name.skill_code == this.skillLogAttributes.skill_code){
          console.log("Perk Name:" +perk_name.skill_code+ " -> "+perk_name.property + " ("+property+")")
          if (perk_name.property == property){
            return perk_name.name
          }
        }
      }

      return "---"
    },
    getUnity(property){
      if (property == "mana") return "";

      if (property == "cooldown") return "s";

      for (let i = 0; i < this.$parent.perk_names.length; i++){
        let perk_name = this.$parent.perk_names[i]

        if (perk_name.skill_code == this.skillLogAttributes.skill_code && perk_name.property == property){
          return perk_name.unity
        }
      }

      return ""
    },
    getProgressive(property){
      if (property == "mana") return false;

      if (property == "cooldown") return false;

      for (let i = 0; i < this.$parent.perk_names.length; i++){
        let perk_name = this.$parent.perk_names[i]

        if (perk_name.skill_code == this.skillLogAttributes.skill_code && perk_name.property == property){
          return perk_name.progressive
        }
      }

      return true
    },
    getChangeForProperty(property){
      if (this.changes[property]){
        return this.changes[property]
      }
      let hash = {
        old_initial_value: this.skillLogAttributes.old_initial_values[property].toFixed(2),
        new_initial_value: this.skillLogAttributes.new_initial_values[property].toFixed(2),
        old_final_value: this.getFinalValue(this.skillLogAttributes.old_initial_values[property], this.skillLogAttributes.old_perks[property], this.getProgressive(property)),
        new_final_value: this.getFinalValue(this.skillLogAttributes.new_initial_values[property], this.skillLogAttributes.new_perks[property], this.getProgressive(property))
      }
      this.changes[property] = hash
      return hash
    },
    getClassFromCode(code) {
      if (code.includes("Lad")) return "Ladino";
      if (code.includes("Alq")) return "Alquimista";
      if (code.includes("Mag")) return "Mago";
      if (code.includes("Fer")) return "Ferreiro";
      if (code.includes("Pal")) return "Paladino";
      if (code.includes("Cav")) return "Cavaleiro da Morte";
      if (code.includes("Min")) return "Minerador";
      if (code.includes("Faz")) return "Fazendeiro";
      if (code.includes("Eng")) return "Engenheiro";
      if (code.includes("Len")) return "Lenhador";
    },
    getFinalValue(base, perkModifier, progressive) {
      if (progressive) {
        let finalValue = ((1 + (100000 * perkModifier) / 148057) * base).toFixed(2);
        return finalValue;
      } else{
        return ((1 + (100000 * -perkModifier) / 148057) * base).toFixed(2);
      }

    },
  },
};
</script>
