import {get, patch} from './../utils/Request'
export default {
  methods: {
    getSkills(from_class){
      return get("/skills/from-class?class="+from_class)
      .then((response) => response.json());
    },
    showSkill(id){
      return get("/skills/"+id)
      .then((response) => response.json());
    },
    updateRequest(body ={}) {
      return patch("/skills/", body)
      .then((response) => {

        return response
      })
    }
  },
}