import {get, put} from './../utils/Request'
export default {
  methods: {
    getWeaponAttributes(from_quality){
      return get("/weapon_attributes/from-quality?quality="+from_quality)
      .then((response) => response.json());
    },
    getArmorAttributes(from_quality){
      return get("/weapon_attributes/armors/from-quality?quality="+from_quality)
      .then((response) => response.json());
    },
    showWeaponAttribute(id){
      return get("/weapon_attributes/"+id)
      .then((response) => response.json());
    },
    updateRequest(id, body ={}) {
      return put("/weapon_attributes/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}