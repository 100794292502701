<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around staticcard">
    <div class="col-12 mx-0 px-0">
      <table class="table-dark table-bordered table-hover col-lg-12 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col">Multiplicador de Preço de Venda</th>
              <th scope="col">Multiplicador de Preço de Compra</th>
              <th scope="col">Preço Final de Venda</th>
              <th scope="col">Preço Final de Compra</th>
              <th scope="col">Slot</th>
              <th scope="col">Salvar</th>
              <th scope="col">Deletar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                   <select class="mx-0 col-12 px-0 py-0 my-0" style="width: 400px" :id="merchant_item.id+'item'" name="item" v-model="merchant_item.item_id"  ref="select multiple">
                    <!--<option v-for="item in this.$parent.$parent.items" :key=item.id :value=item.id>
                      {{item.name}}
                    </option>-->
                  </select>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="merchant_item.sell_price_multiplier"
                    :id="merchant_item.id+'_sell_price_multiplier'"
                  />
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="merchant_item.buy_price_multiplier"
                    :id="merchant_item.id+'_buy_price_multiplier'"
                  />
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  {{calculatedSellPrice()}}
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  {{calculatedBuyPrice()}}
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    type="numeric"
                    min="0" step="1"
                    class="col-5"
                    v-model="merchant_item.slot"
                    :id="merchant_item.id+'_slot'"
                  />
                </div>
              </td>
              <td>
               <div class="row col-12 justify-content-center mx-0 px-0">
                 <i
                    class="fas fa-check fa-2x mx-5 clickable-icon"
                    v-on:click="updateMerchantItem(merchant_item.id)"
                    v-tippy="{ content: 'Salvar' }"
                    style="color: darkgreen"
                  ></i>
                </div>
              </td>
              <td>
                 <div class="row col-12 justify-content-center mx-0 px-0">
                  <i
                    class="fas fa-trash-alt fa-2x mx-5 clickable-icon"
                    v-on:click="deleteMerchantItem(merchant_item.id)"
                    v-tippy="{ content: 'Deletar' }"
                    style="color: darkred"
                  ></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>
      </transition-group>

    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import MerchantItem from '../../mixins/MerchantItemMixin'
import $ from 'jquery'
import selectize from '@selectize/selectize'

export default {
  name: "MerchantItemCard",
  data() {
    return {
      merchant_i: {},
      updateErrors: [],
      updateMessages: [],
      merchantItemAttributes: this.merchant_item,
    };
  },
  props: {
    merchant_item_p: Object,
  },
  mixins: [MerchantItem],
  beforeMount(){
    selectize
    this.merchant_i = this.merchant_item_p
  },
  computed:
  {
    merchant_item(){
      return this.merchant_i
    }
  },
  methods: {
    deleteMerchantItem(){
      this.deleteMerchantItemRequest(this.merchant_item.id)
      .then(data =>{
        if (data.status){
          this.$parent.updateMessages.push("Item de Mercador removido com sucesso!")
          this.$parent.reloadMerchant()

          setTimeout(() => {
            this.$parent.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.$parent.updateErrors.push("Houve um erro ao tentar remover este Item de Mercador.")
          setTimeout(() => {
            this.$parent.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    updateMerchantItem(){
      this.updateMerchantItemRequest(this.merchant_item.id, this.merchant_item)
      .then(data =>{
        if (data.status){
          this.$parent.updateMessages.push("Item de Mercador atualizado com sucesso!")
          setTimeout(() => {
            this.$parent.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.$parent.updateErrors.push("Houve um erro ao tentar atualizar este Item de Mercador.")
          setTimeout(() => {
            this.$parent.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    fullItem(){
      let f_item = {}
      for (let i =0; i < this.$parent.$parent.items.length; i++){
        if (this.$parent.$parent.items[i].id == this.merchant_item.item_id){
          f_item = this.$parent.$parent.items[i];
          break;
        }
      }
      return f_item;
    },
    getItemCategory(item){
        let c_item = {}
      for (let i =0; i < this.$parent.$parent.item_categories.length; i++){
        if (this.$parent.$parent.item_categories[i].id == item.item_category_id){
          c_item = this.$parent.$parent.item_categories[i];
          break;
        }
      }
      return c_item;
    },
    calculatedSellPrice(){
      let item = this.fullItem()
      let category = this.getItemCategory(item)
      let ref_item = category.item

      let ref_price = category.sell_price ? category.sell_price : 0
      let ref_difficulty = ref_item ? (ref_item.difficulty ? ref_item.difficulty : 0) : 0
      return (((ref_price * item.difficulty)/ref_difficulty)*this.merchant_item.sell_price_multiplier).toFixed(2)
    },
    calculatedBuyPrice(){
      let item = this.fullItem()
      let category = this.getItemCategory(item)
      let ref_item = category.item

      let ref_price = category.buy_price? category.buy_price : 0
      let ref_difficulty = ref_item ? (ref_item.difficulty ? ref_item.difficulty : 0) : 0
      return (((ref_price * item.difficulty)/ref_difficulty)*this.merchant_item.buy_price_multiplier).toFixed(2)
    },
    changeMerchantItem(value){
      this.merchant_item.item_id = value
    },
  },
  mounted(){
    let items = this.$parent.$parent.items
    let changeMerchantItem = this.changeMerchantItem
    let mItem = this.merchant_item.item_id
    let mId = this.merchant_item.id
    $(document).ready(function () {
      $("#"+mId+"item").selectize({
          options: items,
          sortField: 'text',
          labelField: 'name',
          valueField: 'id',
          searchField: ['name'],
          items: [mItem],
          onChange(value){
            changeMerchantItem(value)
          }
      });
    });
  }
};
</script>
