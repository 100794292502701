<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <i
      class="col-12 my-0 fas fa-trash-alt fa-2x mx-5 text-right clickable-icon"
      v-on:click="destroyItem()"
      v-tippy="{ content: 'Deletar' }"
      style="color: darkred; font-size: 16px;"
    ></i>
    <div class="col-12 text-center mx-0" style="font-size: 14px" v-html=" replaceColorCodes(item.name, false) "></div>

    <hr class="col-12 mx-0 px-0" />

    Categoria: <select id="category" name="category" v-model="item.item_category_id">
                <option v-for="item_category in this.$parent.item_categories" :key=item_category.id :value=item_category.id>
                  {{item_category.name}}
                </option>
              </select>
    Grau de Dificuldade: <input
                            autocomplete="false"
                            type="numeric"
                            class="col-5"
                            v-model="item.difficulty"
                            :id="item.id+'_difficulty'"
                          />
    <p class="col-12 text-center mx-0">Preço Base de Venda: {{ calculateSellPrice() }} esm</p>
    <p class="col-12 text-center mx-0">Preço Base de Compra: {{ calculateBuyPrice(item) }} esm</p>

    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateItem()">Salvar</button>
    </div>

    <hr class="col-12 mx-0 px-0"/>
    <div v-if="item.id == category.item_id">
      Item Base da categoria {{category.name}}
    </div>

    <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>
      </transition-group>

    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import Item from '../../mixins/ItemMixin'
export default {
  name: "ItemCard",
  data() {
    return {
      item: {},
      updateErrors: [],
      updateMessages: [],
      itemAttributes: this.item,
      lore: undefined,
      name: undefined,
    };
  },
  props: {
    item_p: Object,
  },
  mixins: [Item],
  beforeMount(){
    this.item = this.item_p
    this.name = this.item.name
    this.lore = this.item.lore
  },
  computed:
  {
    category(){
      let category = {}
      for (let i = 0; i < this.$parent.item_categories.length; i++) {
        if(this.$parent.item_categories[i].id == this.item.item_category_id) {
            category = this.$parent.item_categories[i];
        }
      }
      return category;
    },
  },
  methods: {
    destroyItem(){
      this.destroyRequest(this.item.id)
      .then(data =>{
        console.log(data)
        if (!data.error){
          this.updateMessages.push("Item removido com sucesso! "+data.message +" ou "+data.error)
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
            this.$parent.loadItems();
          }, 5000);
        } else {
          this.updateErrors.push(data.error)
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    updateItem(){
      this.updateRequest(this.item.id, this.item)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Item atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar este Item.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    categoryName(){
      let category = "Sem categoria"

      for (let i = 0; i < this.$parent.item_categories.length; i++) {
        if(this.$parent.item_categories[i].id == this.item.item_category_id) {
            category = this.$parent.item_categories[i].name;
        }
      }
      return category
    },
    itemFromCategory(){
      let cItem = {}
      for (let i = 0; i < this.$parent.item_list.length; i++) {
        if(this.$parent.item_list[i].id == this.category.item_id) {
            cItem = this.$parent.item_list[i];
        }
      }
      return cItem;
    },
    calculateSellPrice() {
      let ref_item = this.itemFromCategory()
      let ref_price = this.category.sell_price
      let ref_difficulty = ref_item.difficulty
      return (ref_price * this.item.difficulty)/ref_difficulty
    },
     calculateBuyPrice() {
      let ref_item = this.itemFromCategory()
      let ref_price = this.category.buy_price
      let ref_difficulty = ref_item.difficulty
      return (ref_price * this.item.difficulty)/ref_difficulty
    }
  },
};
</script>
