<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        Tabela de Balanceamento de Refinamentos
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>
      <div v-if="isAdmin() && is_loading_refinements" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>
      <div  v-if="isAdmin() && !is_loading_refinements" class="staff-team row col-12 mx-0 px-0 justify-content-around">

        <div
          v-for="refinement in refinements"
          :key="refinement.id"
          :class="'staticcard col-sm-11 col-md-6 col-lg-5 my-2 mx-2 px-0 row align-content-start py-5 justify-content-center'"
        >

          <RefinementCard :p_refinement="refinement" @reload-refinements="loadRefinements()"></RefinementCard>

        </div>

        <div v-if="refinements.length == 0" class="col-7">
          <h4>Sem valores de refinamento registrados no momento...</h4>
        </div>
      </div>
      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>
    </div>
  </div>
</template>

<script>
import Refinement from "./../../mixins/RefinementMixin";
import { directive } from "vue-tippy";
import RefinementCard from "@/components/refinements/RefinementCard";

export default {
  name: "RefinementBalanceTable",
  props: {},
  components: {
    RefinementCard,
  },
  data() {
    return {
      refinement_list: [],
      editErrors: [],
      editMessages: [],
      is_loading_refinements: true
    };
  },
  computed: {
    refinements() {
      return this.refinement_list;
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    loadRefinements() {

      this.is_loading_refinements = true;
      this.getRefinements().then((refinements) => {
        this.refinement_list = this.refinements.concat(refinements);
        this.is_loading_refinements = false;
      });
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [Refinement],
  beforeMount() {
    this.loadRefinements();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin,
.programmer,
.lorer,
.builder {
  cursor: pointer;
}
.red { background-color: rgba(255, 40, 40, 0.2); }
.dark_red { background-color: rgba(121, 1, 1, 0.563); }
.yellow { background-color: rgba(233, 183, 17, 0.15); }
.green { background-color: rgba(0, 128, 0, 0.2); }
.purple { background-color: rgba(114, 85, 160, 0.4); }
</style>
