<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 justify-content-center align-content-center mx-0">
      <form
        id="ask-password-reset-form"
        class="col-12 row justify-content-center align-content-center mx-0 px-0"
        ref="askPasswordResetForm"
      >
        <fieldset class="whitefield my-5 py-4 col-lg-5 col-8 mx-0 my-4">
          <h4>Solicite um reset de senha:</h4>

          <div
            v-if="passwordResetErrors.length > 0"
            role="alert"
            class="mx-0 px-4 alert alert-danger"
          >
            <ul v-for="error in passwordResetErrors" :key="error">
              <li>{{ error }}</li>
            </ul>
          </div>
          <div
            v-if="passwordResetMessages.length > 0"
            role="alert"
            class="mx-0 px-4 alert alert-success"
          >
            <ul v-for="message in passwordResetMessages" :key="message">
              <li>{{ message }}</li>
            </ul>
          </div>
          <hr />

          <p>Email:</p>
          <input
            id="email"
            type="email"
            class="mineinput"
            name="email"
            required
          />
          <br /><br />
          <button
            v-on:click.prevent="sendAskPasswordResetForm"
            class="minebutton mb-2"
          >
            Solicitar
          </button>
          <br />
        </fieldset>
      </form>
    </div>
  </div>
</template>
<script>
import { post } from "./../../utils/Request";

export default {
  name: "Staff",
  data() {
    return {
      passwordResetMessages: [],
      passwordResetErrors: [],
    };
  },

  methods: {
    sendAskPasswordResetForm() {
      this.passwordResetErrors = [];
      this.passwordResetMessages = [];
      var email = this.$refs.askPasswordResetForm.email.value;

      post("/users/password", {
        user: {
          email
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors || data.error) {
            this.passwordResetErrors.push(
              "Houve um erro ao solicitar a troca de senha! Tente novamente em alguns instantes, por favor. Se o erro persistir, contate um dos admnistradores."
            );
          } else {
            this.passwordResetMessages.push(
              "Troca solicitada! Em breve você receberá um email contendo as informações para trocar sua senha!."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
