import {get, patch} from './../utils/Request'
export default {
  methods: {
    getXps(from_class){
      return get("/xps/from-class?class="+from_class)
      .then((response) => response.json());
    },
    showXp(id){
      return get("/xps/"+id)
      .then((response) => response.json());
    },
    updateRequest(id, body ={}) {
      return patch("/xps/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}