<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        {{merchant.name}}
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <div v-if="isAdmin() && is_loading_merchant" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>

      <div  v-if="isAdmin() && !is_loading_merchant && !is_loading_items && !is_loading_item_categories" class="staff-team row col-12 mx-0 px-0 justify-content-around">

        <div
          class="staticcard col-lg-9 my-3 mx-3 px-0 row align-content-start py-5 justify-content-center"
        >
          <MerchantCard v-if="loadedMerchant" :merchant_p="merchant" @reload-merchants="loadMerchant()"></MerchantCard>
        </div>
      </div>

      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>

    </div>
  </div>
</template>

<script>
import Merchant from "./../../mixins/MerchantMixin";
import Item from "./../../mixins/ItemMixin";
import ItemCategory from "./../../mixins/ItemCategoryMixin";

import { directive } from "vue-tippy";
import MerchantCard from "@/components/merchants/MerchantCard";

export default {
  name: "MerchantEditTable",
  props: {},
  components: {
    MerchantCard,
  },
  data() {
    return {
      merchant: [],
      editErrors: [],
      editMessages: [],
      is_loading_merchants: true,
      loadedMerchant: false,
      filter_selected: "all",
      old_filter_selected: "all",
      items: {},
      is_loading_items: true,
      item_categories: {},
      is_loading_item_categories: true,
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    loadMerchant() {
      this.is_loading_merchant = true;
      this.showMerchant(this.$route.params.id).then((merch) => {
        this.merchant = merch
        this.loadedMerchant = true;
        this.is_loading_merchant = false;
      });
    },
    loadItems() {
     this.is_loading_item_categories = true
     this.getItems().then((items) => {
        this.items = items
        this.is_loading_items= false
      });
    },
    loadItemCategories() {
      this.is_loading_item_categories = true
      this.getItemCategories().then((item_categories) => {
        this.item_categories = item_categories
        this.is_loading_item_categories = false
      });
    },
    reloadMerchant(merchant) {
      this.is_loading_merchant = true;
      this.showMerchant(merchant.id).then((merch) => {
        merchant = merch
      });
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [Merchant,Item,ItemCategory],
  beforeMount() {
    this.loadItems()
    this.loadItemCategories()
    this.loadMerchant();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin,
.programmer,
.lorer,
.builder {
  cursor: pointer;
}
</style>
