import {get, patch, destroy} from './../utils/Request'
export default {
  methods: {
    getLoots(){
      return get("/loots")
      .then((response) => response.json());
    },
    showLoot(id){
      return get("/loots/"+id)
      .then((response) => response.json());
    },
    updateRequest(id, body ={}) {
      return patch("/loots/"+id, body)
      .then((response) => {

        return response
      })
    },
    destroyRequest(id, body ={}) {
      return destroy("/loots/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}