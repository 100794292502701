<template>
  <div
    class=" col-12 row mx-0 px-0 main-content justify-content-center align-content-start"
  >
    <h2 class="col-12 text-center">Editar Tarefa</h2>
    <div
      v-if="!isAdmin()"
      class="col-xl-6 col-lg-9 col-11 justify-self-center text-center my-4"
    >
      <div role="alert" class="alert alert-danger">
        Somente admnistradores podem editar tarefas!
      </div>
    </div>

    <div
      v-if="isAdmin()"
      class="staff staff-team row col-12 mx-0 px-0 justify-content-around"
    >
      <div
        v-if="isLoading"
        class="col-xl-6 col-lg-9 col-11 mx-auto px-0 my-0 py-0"
      >
        <img :src="'../../img/loading.gif'" alt="" class="loader-gif" />
      </div>
      <div v-if="!isLoading" class="col-xl-6 col-lg-9 col-11">
        <form
          class="col-12 row justify-content-center align-content-around py-1 mx-0 px-0"
          ref="updateTaskForm"
        >
          <h3 class="col-9 mx-0 px-0 text-center">{{title}}</h3>
          
          <br />

          <div class="builder my-4 py-0 pb-1 px-0 col-9 mx-0 px-0">
            <div class="mt-0">Categoria:</div>
            <select id="category" name="category" v-bind:value="category">
              <option value="building">Construção</option>
              <option value="lore">Roteiro</option>
              <option value="dev">Programação</option>
            </select>
          </div>
          <div style="font-size: 12px" class="my-2 col-9 mx-0 px-0">
            <div class="mt-0">Descrição:</div>
            <div class="textEditor col-12 mx-0 px-0">
              <textarea
                name="description"
                class="col-12 mx-0 px-0"
                style="min-height: 100px"
                id="description"
                v-bind:value="description"
              ></textarea
              ><br />
            </div>
          </div>
          <div style="font-size: 12px" class="my-4 col-9 mx-0 px-0">
            Lider da Tarefa:
            <select id="leader" name="leader" v-bind:value="leader">
              <option v-for="admin_user in admin_users"
                :key="admin_user" :value="admin_user.nickname">{{admin_user.nickname}}</option>
            </select>
          </div>

          <div class="my-4 row col-9 mx-0 px-0">
            <p class="col-12" style="font-size: 12px">Membros:</p>
            <div
              class="col-12 my-2 mx-0 px-0 justify-content-around row text-center"
            >
              <div
                v-on:click="toggleFromMemberIds(admin_user)"
                v-bind:class="{
                  unchecked: !member_ids.includes(admin_user.id),
                }"
                style="font-size: 6px"
                class="col-xl-2 col-lg-3 clickable col-md-3 col-4 my-2 justify-content-center text-center"
                v-for="admin_user in admin_users"
                :key="admin_user"
              >
                <span
                  v-if="member_ids.includes(admin_user.id)"
                  class="badge  ml-0 mr-0 px-0"
                  style="float: right; position: absolute; "
                  ><i style="color: lightgreen" class="fas fa-check-circle"></i
                ></span>
                <img
                  class="my-0 mx-auto px-0 py-0 rounded justify-self-center"
                  :src="
                    'https://minotar.net/helm/' +
                      admin_user.nickname +
                      '/100.png'
                  "
                  style="width: 25px;"
                />

                <p
                  style="font-size: 8px"
                  class=" mx-auto px-0 col-12 text-center"
                >
                  {{ admin_user.nickname }}
                </p>
              </div>
            </div>
          </div>

          <div style="font-size: 12px" class="my-2 col-9 mx-0 px-0">
            Card no Trello:
            <select
              id="trelloCard"
              name="trello_card"
              v-bind:value="trello_card"
            >
              <option
                v-for="card in cardsOnBoard"
                :key="card.id"
                :value="card.id"
                >{{ card.name }}</option
              >
            </select>
          </div>
          <div
            v-if="!need_help"
            class="col-12 mx-0 px-0 justify-content-center"
          >
            <label style="font-size: 12px" class="mx-1 mt-3 px-0 col-9"
              >Criar Canal de Voluntários para esta Tarefa
              <button id="need_help" v-on:click.prevent="createChannel()" class="mx-1 px-0 col-10 minebutton">
                Criar Canal para Voluntários
              </button>
            </label>
          </div>
          <div v-else class="col-12 mx-0 px-0 justify-content-center">
            <label style="font-size: 12px" class="mx-1 mt-3 px-0 col-9"
              >Remover Canal de Voluntários desta tarefa
              <button id="need_help" v-on:click.prevent="deleteChannel()" class="mx-1 px-0 col-10 minebutton">
                Deletar Canal para Voluntários
              </button>
            </label>
          </div>

          <button
            class="minebutton col-8 my-3"
            v-on:click.prevent="sendUpdateTaskForm()"
          >
            Aplicar edições
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { get, patch, post, destroy } from "./../../utils/Request";
import Task from "../../mixins/TaskMixin";
import _ from "lodash";

export default {
  name: "Edit",
  data() {
    return {
      cardsOnBoard: [],
      boardSelected: "",
      title: "",
      category: "",
      description: "",
      leader: "",
      members: "",
      trello_card: "",
      need_help: false,
      admin_users: [],
      member_ids: [],
      isLoading: true,
      bot_api_url: process.env.VUE_APP_BOT_API_URL
    };
  },
  mixins: [Task],
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    hasChannel() {
      get(this.bot_api_url+"/task?taskName="+this.title)
      .then((response) => response.json())
      .then((data) => {
        if (data.status){
          if (!this.need_help){
            this.deleteChannel();
          } 
        } else {
          if (this.need_help){
            this.createChannel();

          } 
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    deleteChannel() {
      destroy(this.bot_api_url+"/task", {taskName: this.title})
      .then((response) => response.json())
      .then((data) => {
        if (data.status){
          this.updateNeedHelp(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    createChannel() {
      post(this.bot_api_url+"/task", {
        taskName: this.title,
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.status){
          this.updateNeedHelp(true)
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
    updateNeedHelp(need) {
      patch("/tasks/" + this.$route.params.id, {
        task: {
          need_help: need
        },
      })
        .then((response) => response.json())
        .then(() => {
          this.$router.go("/progresso/editar-tarefa/"+this.$route.params.id);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleFromMemberIds(admin_user) {
      if (this.member_ids.includes(admin_user.id)) {
        for (var i = 0; i < this.member_ids.length; i++) {
          if (this.member_ids[i] === admin_user.id) {
            this.member_ids.splice(i, 1);
            i--;
          }
        }
      } else this.member_ids.push(admin_user.id);
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    getBoardCards() {
      get(
        "https://api.trello.com/1/boards/i19iMX2n/cards?key=461775ab1ea8a50c527472a368629c14&token=891ec56ec9f94c58fc10b810c906dc32b121da97107992e5fbb2e738964eb593"
      )
        .then((response) => response.json())
        .then((data) => {
          this.cardsOnBoard = data;
        });
    },
    getTask() {
      this.isLoading = true;
      this.showTask(this.$route.params.id).then((data) => {
        this.title = data[0].name;
        this.category = data[0].category;
        this.description = data[0].description;
        this.leader = data[0].leader;
        this.trello_card = data[0].trello_card;
        this.members = data[0].members;
        this.need_help = data[0].need_help;
        this.isLoading = false;
        this.hasChannel()
        _.each(this.members, (member) => {
          this.member_ids.push(member.id);
        });
      });
    },
    getAdminUsers() {
      get("/users/admin-users")
        .then((response) => response.json())
        .then((data) => {
          this.admin_users = data;
        });
    },
    sendUpdateTaskForm() {
      let form = this.$refs.updateTaskForm;
      this.registerErrors = [];
      let taskname = this.title
      let category = form.category.value;
      let description = form.description.value;
      let leader = form.leader.value;
      let trello_card = form.trello_card.value;
      let need_help = form.need_help.value;

      if (
        [taskname, category, description, leader, trello_card].includes(null) ||
        [taskname, category, description, leader, trello_card].includes(
          undefined
        )
      ) {
        return;
      }
      patch("/tasks/" + this.$route.params.id, {
        task: {
          name: taskname,
          category,
          description,
          leader,
          trello_card,
          need_help,
          member_ids: this.member_ids,
        },
      })
        .then((response) => response.json())
        .then(() => {
          this.$router.push("/progresso");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  beforeMount() {
    this.getTask();
    this.getBoardCards();
    this.getAdminUsers();
  },
};
</script>
<style scoped>
.unchecked {
  filter: opacity(25%);
}
.unchecked:hover {
  filter: opacity(75%);
}
.clickable {
  cursor: pointer;
}
.loader-gif {
  max-width: 250px;
  min-width: 250px;
  width: 250px;
}
</style>
