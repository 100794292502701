<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center">
    <div class="col-12 row justify-content-center my-auto align-content-start">
        <h2 class="col-7 mx-0 px-0 mt-3 mb-2 pb-0 text-center"
        style="background-image: url('img/grass.png'); background-size: 30px; background-repeat: repeat-x; background-position: bottom; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801);"
      >
        Tarefas Concluidas
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>
    <div class="col-12 align-content-start mt-0 mb-0 py-0 mx-0 px-0 row justify-content-center shopborder" style="max-height: 100px">
      <h4 class="col-12 text-center mb-4"> Filtrar por categoria: </h4>
      <div class="row col-12 mx-0 px-0 justify-content-around">
        <div
          class="admin col-lg-2 col-4"
          v-on:click="change_category('all')"
        >
          Todas
        </div>
        <div
          class="programmer col-lg-2 col-4"
          v-on:click="change_category('dev')"
        >
          Programação
        </div>
        <div
          class="lorer col-lg-2 col-4"
          v-on:click="change_category('lore')"
        >
          Roteiro
        </div>
        <div
          class="builder col-lg-2 col-4"
          v-on:click="change_category('building')"
        >
          Construção
        </div>
      </div>
    </div>
    <div class="staff col-12 my-0 row justify-content-center align-content-start mx-0">
      <div
        v-if="deleteErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in deleteErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <div
        v-if="setAsNonCompletedErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in setAsNonCompletedErrors" :key="error">
            {{ error }}
          </div>
        </transition-group>
      </div>
      <div
        v-if="deleteMessages.length > 0"
        class="alert alert-success col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="msg in deleteMessages" :key="msg">{{ msg }}</div>
        </transition-group>
      </div>
      <div class="staff-team row col-12 mx-0 px-0 justify-content-around">
        <a
          v-for="task in tasks"
          :key="task.id"
          v-show="task.finished"
          class=" shopcard col-11 my-2 mx-0 px-0 row align-content-start py-5 justify-content-center"
          :href="goToEditPage(task.id)"
        >
          <div
            class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around"
          >
            <h4 class="col-3 mx-0">{{ task.name }}</h4>
            <div v-if="task.category == 'building'" class="builder col-2">
              Construção
            </div>
            <div v-else-if="task.category == 'lore'" class="lorer col-2">
              Roteiro
            </div>
            <div v-else-if="task.category == 'dev'" class="programmer col-2">
              Programação
            </div>
            <div v-else class="admin col-2">Sem Categoria</div>

            <p style="font-size: 12px" class="col-3">
              {{ task.description }}
            </p>
            <p style="font-size: 12px" class="col-2">
              Lider da Task: {{ task.leader }}
            </p>
          </div>
        </a>

        <div v-if="tasks.length == 0" class="col-7">
          <h4>Sem tasks concluidas no momento...</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Task from "./../../mixins/TaskMixin";
import { directive } from "vue-tippy";
import _ from "lodash";

export default {
  name: "Progress",
  props: {},
  data() {
    return {
      task_list: [],
      classes_info:
        "Progresso do desenvolvimento das Classes, Habilidades ativas, passivas e itens que compõem o jogo.",
      cardsOnBoard: [],
      deleteErrors: [],
      deleteMessages: [],
      setAsNonCompletedErrors: [],
      last_page: 1,
      has_more: true,
      is_loading_tasks: false,
      category_selected: "all",
      old_category_selected: "all",
    };
  },
  computed: {
    tasks(){
      return this.task_list
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
    getCompletedTasksCount() {
      let count = 0;
      _.each(this.tasks, (task) => {
        if (task.finished) {
          count++;
        }
      });
      return count;
    },
  },
  methods: {
    goToEditPage(task_id) {
      if (this.isAdmin()) {
        return "/progresso/editar-tarefa/" + task_id
      } else {
        return "/progresso/ver-tarefa/" + task_id
      }
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    volunteer(task_id, user_id) {
      this.askToVolunteer(task_id, user_id).then(this.loadTasks());
    },
    deleteTask(id) {
      this.deleteRequest(id).then((data) => {
        if (data.ok) {
          for (var i = 0; i < this.tasks.length; i++) {
            if (this.tasks[i].id == id) {
              this.tasks.splice(i, 1);
            }
          }
          this.deleteMessages.push("Task " + id + " deletada com sucesso");
          setTimeout(() => {
            this.deleteMessages.splice(0, 1);
          }, 5000);
        } else {
          this.deleteErrors.push(
            "Houve um erro ao tentar deletar a Task " + id
          );
          setTimeout(() => {
            this.deleteErrors.splice(0, 1);
          }, 5000);
        }
      });
    },
    completeTask(id) {
      this.updateRequest(id, { finished: true }).then((data) => {
        if (data.ok) {
          this.$router.go("/progresso");
        } else {
          this.setAsCompletedErrors.push(
            "Houve um erro ao tentar marcar a Task " + id + " como completa"
          );
          setTimeout(() => {
            this.setAsCompletedErrors.splice(0, 1);
          }, 5000);
        }
      });
    },
    taskQuantityText(quantity) {
      if (quantity == 1) {
        return "1 tarefa";
      }
      let text = quantity + " tarefas";
      return text;
    },
    getCard(card_id) {
      let card = {};
      _.each(this.cardsOnBoard, (trello_card) => {
        if (trello_card.id == card_id) {
          card = trello_card;
        }
      });
      return card;
    },
    change_category(category){
      this.category_selected = category
      this.loadTasks(this.last_page)
    },
    loadTasks(page) {
      if (this.category_selected != this.old_category_selected){
        this.last_page = 1
        page = 1
        this.task_list = []
      }
      this.old_category_selected = this.category_selected
      this.is_loading_tasks = true;
      this.getTaskPage(page, this.category_selected).then((tasks) => {
        
        this.task_list = this.tasks.concat(tasks);
        if (tasks.length == 0) {
          this.has_more = false;
        }
        this.last_page = this.last_page + 1;
        this.is_loading_tasks = false;
      });
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [Task],
  beforeMount() {
    this.loadTasks(this.last_page);
  },
  mounted(){
    window.onscroll = () => {
        let scrollPosition =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) + window.innerHeight;
        let bottomOfWindow =
          scrollPosition >= document.documentElement.offsetHeight - 100;

        if (bottomOfWindow) {
          if (this.has_more && !this.is_loading_tasks) {
            this.loadTasks(this.last_page);
          }
        }
      };
  }
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
 .builder, .programmer, .lorer {
    max-height: 40px;
    min-height: 40px;
    height: 40px;
  }
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin, .programmer, .lorer, .builder {
  cursor: pointer;
}
</style>
