<template>
<div class="classmodalbody">

  <span class="close" v-on:click="$parent.dynamicSelect('about')">&times;</span>

  <div style="clear: right"></div>

  <div class="classtitle" >
      <h1 style="margin: 0px"> Fazendeiro </h1>
      <hr>
      <h2 style="margin: 0px; color:rgb(255, 230, 128)"> Amigo da Natureza </h2>
  </div>
    <div class="row justify-content-center">

    <div class="charpic col-8 col-md-3"> 	<img src="/img/farmerchar.png" style="width: 100%;" alt=""> </div>
    <div style="clear: left;"/>
    <div class="col-12 col-md-4">
      Ativas:
      <hr style="width: 50%; margin-left: 0px"> 
      <ul> 
        <li v-for="skill in skills" :key="skill"  :class="{ skillArrow: !skill.isArrowFlipped, skillArrowFlipped: skill.isArrowFlipped}"  v-on:click="openTab(skill.skillNumber)">
          <div>
            <h4 class="skillTitle">{{skill.skillName}}</h4>
            <div
              v-if="skill.isSkillBoxOn"
              :id="'skill'+skill.skillNumber"
              :ref="'skill'+skill.skillNumber"
              :class="{ skillBoxOn: skill.isSkillBoxOn}"
              style="display: none; text-align: left;"
            >
              <p style="text-align: center">Descrição da Habilidade:</p>
              <div
                style="text-align: center"
                class="row justify-content-center"
                >
              </div>
              <hr style="width: 92.5%" />

              <span v-html="skill.description"></span>
              <hr style="width: 92.5%" />
              <div v-if="skill.howToUse">
                Como usar:
                <span v-html="skill.howToUse"></span>
              </div>
            </div>
          </div>
        </li>
      </ul>
        
    </div>

    <div class="col-12 col-md-4">
        Passivas:
        <hr style="width: 50%; margin-left: 0px">
        <ul>
        <li>Domínio da Agropecuária</li>
        <li>Domador de Animais</li>
        <li>Pescador Excêntrico</li>
        <li>Armadilha de Grama</li>
        <li>Benzedura da Vida</li>
        <li>Companheiros Caninos</li>
        </ul>
    </div>

    <div style="clear: left;"></div>

    <div>
      <div>
          <hr>
          <p>"Fazendeiros são a base de qualquer civilização, sem eles não existiriam alimentos e a econômia seria sem propósito."<br></p>
          <p>Os únicos que conseguem cuidar apropriadamente de um celeiro e entender o plantio de plantas tão complexas. Além de seus lobos darem um ótimo suporte em combate.<br></p><hr>
      </div>
      <div> 
          <h2 style="text-align: center; margin-top: 0px">Combos</h2>
          <p>As Habilidades Ativas são executadas através de combos! <br> Abaixo estarão os combos necessários para ativar as habilidades do Fazendeiro</p>
          <div class="combosubtitle">
              <h4>Legenda:</h4>
              
              <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> : Olhando para cima / <i class="fas fa-arrow-down"> </i><i class="fas fa-eye"></i> : Olhando para baixo / <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> : Shift <br>
              <i class="fas fa-running runact"></i> : Correndo / <i class="fas fa-mouse leftclick"></i> : Clique Esquerdo / <i class="fas fa-mouse rightclick"></i> : Clique Direito
          </div>
          <br>
          <div class="row justify-content-center">
              <div class="combosubtitle" style="float: left">
                  Plantio Eficiente: <br>
                  <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                  Cuidados Fertilizantes: <br>
                  <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse rightclick"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                  Gosma Envenenada: <br>
                  <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse leftclick"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                  Surpresa Farinhenta: <br>
                  <i class="fas fa-running runact"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>
              </div>
              <div style="clear: left;"></div>
          </div>
      </div>
      <div style="clear: left;"></div>
      <div style="clear: right;"></div>

      <br> <hr>
      <div>
          <h2 style="text-align: center">Video:</h2>

          <iframe style="margin-left: 15%; padding:0px;" class="wikiMainInfo" width="70%" height="415" src="https://www.youtube.com/embed/RFuOEQcYJWI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      </div>
    </div>
  </div>
</div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Farmer",
  data() {
    return {
      skills: [
        {
          skillNumber: 1,
          skillName: "Plantio Eficiente",
          description: "Colhe rapidamente uma pequena área em volta.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>`,
        },
        {
          skillNumber: 2,
          skillName: "Cuidados Fertilizantes",
          description: "Faz as plantas por perto terem um avanço em seu crescimento  durante um tempo.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 3,
          skillName: "Gosma Envenenada",
          description: "Joga os restos orgânicos da Enxada no inimigo.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse leftclick"></i>`,
        },
        {
          skillNumber: 4,
          skillName: "Surpresa Farinhenta",
          description: "Joga a enxada amarrada a uma linha Joga a enxada amarrada a uma linha. Se acertar, deixa o alvo cego e lhe dá um pequeno puxão.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-running runact"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
      ],
    };
  },
  methods: {
    openTab(number) {

      _.each(this.skills, (skill) => {
        if (skill.skillNumber == number) {
          skill.isSkillBoxOn = !skill.isSkillBoxOn;

          skill.isArrowFlipped = !skill.isArrowFlipped;
        } else {
          if (skill.isSkillBoxOn) {
            skill.isSkillBoxOn = false;
          }
          if (skill.isArrowFlipped) {
            skill.isArrowFlipped = false;
          }
        }
      });

    },
  },
};
</script>
