import {get, put} from '../utils/Request'
export default {
  methods: {
    getQualities(){
      return get("/qualities")
      .then((response) => response.json());
    },
    showQuality(id){
      return get("/qualities/"+id)
      .then((response) => response.json());
    },
    updateQualityRequest(id, body ={}) {
      return put("/qualities/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}
