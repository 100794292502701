import { get, post } from "./../utils/Request";
import crypto from 'crypto';

export default {
  methods: {
    redeem(user_id, code) {
      return post("/redeem_codes/redeem", {
        user_id: user_id,
        code: code
      }).then((response) => response.json());
    },
    createRedeem(product_id){
      var code = crypto.randomBytes(20).toString('hex');
      return post("/redeem_codes", {
        product_id: product_id,
        code: code
      }).then((response) => response.json());
    },
    getAllRedeemCodes(){
      return get("/redeem_codes").then((response) => response.json())
    }
  },
};
