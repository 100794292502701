import {get, patch} from './../utils/Request'
export default {
  methods: {
    getSkillNames(){
      return get("/skill_names")
      .then((response) => response.json());
    },
    showSkillName(id){
      return get("/skill_names/"+id)
      .then((response) => response.json());
    },
    updateSkillNameRequest(body ={}) {
      return patch("/skill_names/", body)
      .then((response) => {

        return response
      })
    }
  },
}
