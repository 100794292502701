<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h3 class="col-12 text-center mx-0"> VIPs adquiridos</h3>
    <hr class="col-12 mx-0 px-0" />

    <div class="col-12 row justify-content-center row mx-0 px-0">
      <p class="col-12 mx-auto my-3" style="font-size:14px">Observações:
        <br/>
      - VIPs desativam no momento que o jogador loga no servidor com os dias expirados
        <br/>
      - Só aparece aqui jogadores que ativaram o VIP in-game
      </p>
      <div class="col-12 my-2 py-0 mx-0 px-0 row justify-content-center staticcard">
        <div class="col mx-0 my-1 px-0 text-center">
          <span>Somente ativos: </span>
          <input
            id="active_filter"
            type="checkbox"
            name="active_filter"
            v-model="active_filter"
          />
        </div>
        <div class="col mx-0 my-1 px-0 text-center">
          <span>Nome: </span>
        <input
          id="name_filter"
          class="mineinput"
          name="name_filter"
          v-model="name_filter"
        />
        </div>
        <div class="col-12 mx-0 px-0 row justify-content-center my-1">
            <button class="minebutton mx-0 px-3" v-on:click="loadVips()">Buscar</button>
        </div>
      </div>
      <img v-if="is_loading" :src="'img/loading.gif'" alt="" class="loader-gif" />

      <table v-else class="table-dark table-bordered table-hover col-12 dark-box">
        <thead>
          <tr>
            <th scope="col">Nickname</th>
            <th scope="col">Tier</th>
            <th scope="col">Dias comprados</th>
            <th scope="col">Ativo?</th>
            <th scope="col">Ativado em</th>
            <th scope="col">Bônus Coletado?</th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="vip in vips" :key="vip.id">
            <td class="text-center">{{vip.nickname}}</td>
            <td class="text-center">{{vip.tier}}</td>
            <td class="text-center">{{vip.days_left}}</td>

            <td class="text-center">{{(vip.active ? "Sim" : "Não")}}</td>
            <td class="text-center">{{ dateTime(vip.activated_at) }} </td>

            <td class="text-center">{{(vip.bonus_colected ? "Sim" : "Não")}}</td>
            <td class="text-center" >{{vip.last_daily_kit ? dateTime(vip.last_daily_kit) : '----'}}</td>
          </tr>
        </tbody>
      </table>

      <hr class="col-12 mx-0 px-0"/>


    </div>
  </div>
</template>
<script>
import moment from 'moment';

import Vip from '../../mixins/VipMixin'
export default {
  name: "VipCard",
  data() {
    return {
      is_loading: true,
      vips: [],
      name_filter: "",
      active_filter: false
    };
  },
  mixins: [Vip],
  methods: {
    loadVips(){
      this.is_loading = true;
      this.getVipsWithUsers(this.active_filter, this.name_filter).then((vip_list) => {
        this.vips = vip_list
        this.is_loading = false
      });
    },
    dateTime(value) {
      return moment(value).format('DD/MM/YYYY');
    },

  },
  beforeMount(){
    this.loadVips();
  }
};
</script>
