<template>
  <div class="row col-12 px-0 mx-0 justify-content-center" style="height: max-content;">
    <h2 class="col-12 mx-0 px-0">Sua conta:</h2>
    <div class="col-12 row mx-0 px-0">
      <div
        v-if="user.attributes.verified"
        class="col-12 mx-0 px-0 row justify-content-center"
      >
        <div class="col-12">
          <img
            :src="
              'https://minotar.net/helm/' +
                user.attributes.nickname +
                '/100.png'
            "
            class="col-1 px-0 mt-3 text-center"
            style="margin-bottom: -10px; width: 50px;"
            alt="Conta Comum"
          />
        </div>
        <h2
          class="col-12 text-center simpleborder-1 my-4"
          style="border-top: none; border-left: none; border-right: none;"
        >
          {{ user.attributes.nickname }}
        </h2>
      </div>

      <div v-else class="row col-12 mx-0 px-0  justify-content-center">
        <div id="verifyBox" class="staticcard staticcard-rare col-8 mx-auto " >
          <h3>Verifique sua conta</h3>
          <h4>Entre no Knights of Minecraft e digite o comando:</h4>
          <span class="logconfirm">
            /kom confirmar {{ user.attributes.code }}
          </span>
          <h4>para registrar seu nickname</h4>
        </div>
        <div class="staticcard staticcard-rare col-8 mx-auto">

            <h4 class="mt-2 mb-0 mx-0 px-auto py-auto">
              IP: kom.nevercraft.com.br
            </h4>

        </div>
      </div>

      <form class="col-12">
        <br />
        <input
          type="submit"
          v-on:click.prevent="logoff()"
          class="minebutton"
          value="Deslogar"
        />
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserPanel",
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
  },
  methods: {
    logoff() {
      this.$store.commit("cleanUser");
    },
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
</style>
