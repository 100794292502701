import {get, post, patch, destroy} from './../utils/Request'
export default {
  methods: {
    getItemCategories(){
      return get("/item_categories")
      .then((response) => response.json());
    },
    showItemCategory(id){
      return get("/item_categories/"+id)
      .then((response) => response.json());
    },
    createItemCategoryRequest( body ={}) {
      return post("/item_categories", body)
      .then((response) => {

        return response
      })
    },
    updateItemGategoryRequest(id, body ={}) {
      return patch("/item_categories/"+id, body)
      .then((response) => {

        return response
      })
    },
    destroyItemCategoryRequest(id, body ={}) {
      return destroy("/item_categories/"+id, body)
      .then((response) => {

        return response
      })
    },
  },
}
