<template>
  <footer id="footer">
    <p>Copyright &copy; Nevercraft 2021</p>
    <h6 style="padding-right: 30px">Criado por: Erick Ferreira</h6>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
  },
};
</script>
