<template>
  <div>
    <div class="slideshow-container">
      <div class="mySlides" id="login">
        <h2 class="my-2">Login</h2>
        <form id="login-form" ref="loginForm">
          <fieldset class="whitefield py-2">
            <div v-if="loginErrors.length > 0" id="poslogin">
              <ul v-for="error in loginErrors" :key="error">
                <li>{{ error }}</li>
              </ul>
            </div>

            <hr />

            <p>E-mail:</p>
            <input
              id="email"
              type="email"
              class="mineinput"
              name="email"
              required
              autofocus
            />
            <p>Senha:</p>
            <input
              id="password"
              type="password"
              class="mineinput"
              name="password"
              required
            />
            <br /><br />
            <button
              v-on:click.prevent="sendLoginForm"
              class="minebutton mb-2"
              value="login"
            >
              Entrar
            </button>
            <br />

            <a class="minebutton" disabled href="/esqueci-minha-senha">
              Esqueceu sua senha?
            </a>
          </fieldset>
        </form>
        <br />Não possui uma conta? Arraste para o lado e crie uma!
        <a
          style="color: white; font-size: 36px"
          class="next"
          v-on:click="plusSlides(1)"
          >&#10095;</a
        >
      </div>

      <div class="mySlides" id="register">
        <h2 class="my-2">Registre-se</h2>
        <form id="register-form" ref="registerForm">
          <fieldset class="whitefield py-2">
            <div v-if="registerErrors.length > 0" id="posreg">
              <ul v-for="error in registerErrors" :key="error">
                <li>{{ error }}</li>
              </ul>
            </div>
            <div id="regbody">
              <hr />
              <p>E-mail:</p>
              <input
                id="email"
                type="email"
                class="mineinput @error('email') is-invalid @enderror"
                name="email"
                required
                autocomplete="email"
              />
              <p>Senha:</p>
              <input
                id="password"
                type="password"
                class="mineinput"
                name="password"
                required
                autocomplete="new-password"
              />

              <p>Confirme sua senha:</p>
              <input
                id="password-confirm"
                type="password"
                class="mineinput"
                name="password_confirmation"
                required
                autocomplete="new-password"
              />

              <br />

              <button
                type="submit"
                class="minebutton mb-2 mt-3"
                v-on:click.prevent="sendRegisterForm()"
              >
                Registrar
              </button>
            </div>
          </fieldset>
        </form>
        <br />Já possui uma conta? Arraste para o lado e logue-se!
        <a
          style="color: white; right: 95%; font-size: 36px"
          class="prev"
          v-on:click="plusSlides(-1)"
          >&#10094;</a
        >
      </div>
    </div>
    <div style="text-align: center">
      <span class="dot active" v-on:click="currentSlide(1)"></span>
      <span class="dot" v-on:click="currentSlide(2)"></span>
    </div>
  </div>
</template>
<script>
import { post } from "./../../utils/Request";
import _ from "lodash";

export default {
  name: "LoginForms",
  data() {
    return {
      slideIndex: 2,
      registerErrors: [],
      loginErrors: [],
    };
  },
  methods: {
    sendRegisterForm() {
      this.registerErrors = [];
      var email = this.$refs.registerForm.email.value;
      var password = this.$refs.registerForm.password.value;
      var password_confirmation = this.$refs.registerForm.password_confirmation
        .value;
      if (password.length < 6 || password_confirmation.length < 6) {
        this.registerErrors.push(
          "A senha deve possuir no mínimo 6 caracteres."
        );
      }
      if (password != password_confirmation) {
        this.registerErrors.push("As senhas não coincidem.");
        return;
      }
      if (email == undefined || email == "" || email == null) {
        this.registerErrors.push("Insira um e-mail válido.");
        return;
      }
      post("/users", {
        email,
        password,
        password_confirmation,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            if (data.errors.password_confirmation) {
              _.each(data.errors.password_confirmation, (error) => {
                this.registerErrors.push(error);
              });
            }
            if (data.errors.email) {
              _.each(data.errors.email, (error) => {
                this.registerErrors.push(error);
              });
            }
            return;
          } else {
            this.$store.commit("setUser", data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendLoginForm() {
      this.loginErrors = [];
      var email = this.$refs.loginForm.email.value;
      var password = this.$refs.loginForm.password.value;

      post("/auth", {
        email,
        password,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            this.loginErrors.push("Credenciais inválidas");

            return;
          } else {
            this.$store.commit("setUser", data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((this.slideIndex = n));
    },
    showSlides(n) {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");
      if (n < slides.length) {
        this.slideIndex = 1;
      }
      if (n > 1) {
        this.slideIndex = slides.length;
      }

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      slides[this.slideIndex - 1].style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.showSlides(1);
  },
};
</script>
