<script>
export default {
  name: 'Redirector',
  data() {
    const platform = this.$route.matched[0].props.default.platform.toLowerCase();
    switch (platform) {
      case 'twitch':
        return window.location.href = "https://www.twitch.tv/redenevercraft"
      case 'discord':
        return window.location.href = "https://discord.gg/quwxRXF"
    }
  },
};
</script>