<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-5 justify-content-center mx-0">
      <h2
        class="col-7 mx-0 px-0 mt-3 pb-0 text-center"
        style="background-image: url('../img/grass.png'); background-size: 30px; background-repeat: repeat-x; background-position: bottom; margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801); width: 50%; margin-left: 25%"
      >
        Guia
      </h2>
      <hr style="width: 52.5%; margin: 0% 23.75%; " />
      <h4
        id="pagetitle"
        style="margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801); width: 50%; margin-left: 25%"
      >
        Aprenda como funciona as mecânicas de jogo:
      </h4>
      <br />
      <div class="row justify-content-center">
        <div class="wikiMainInfo">
          <a href="/wiki"
            ><i class="fas fa-times fa-2x backbtn" style="float: right"></i
          ></a>
          <img
            style="filter: contrast(225%); width: 30%; margin-left: 35%"
            src="/img/chest.png"
            alt=""
          /><br />
          <h1
            style="text-align: center; background-image: url('../img/grass.png'); background-size: 30px; background-repeat: repeat-x; background-position: bottom; margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801); width: 50%; margin-left: 25%"
          >
            Aventura:
          </h1>

          <!-- Capitulo Inimigos -->
          <hr style="width: 65%; margin-top: 0px;" />
          <br />
          <hr style="margin-top: 0px; margin-bottom: 25px;" />
          <h2 style="text-align: center; margin-top: 0px;">Inimigos:</h2>
          <h3>Categorias de Inimigos:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          <ul>
            <li>Pilhadores</li>
            <li>Monstros</li>
            <li>Demônios</li>
          </ul>

          <h3>Sobre os Saqueadores:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          Soldados do Culto dos Feiticeiros que são encontrados geralmente
          próximos a bases de Guildas e locais habitados, atacando em bandos.
          Possuem os seguintes combatentes: Besteiros, Guerreiros, Feiticeiras e
          em alguns casos é possível encontra-los com seus rinocerontes
          domesticados.
          <h3>Sobre os Monstros:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          Criaturas monstruosas e violentas que surgem das mais diversas formas
          depois dos eventos celestiais que trouxeram a energia de Ubaj para o
          mundo dos humanos. Monstros já surgem de forma natural no mundo e
          geralmente estão ligados a morte, porém ocultistas já possuem estudos
          na invocação de monstros e alguns casos raros, a energia maligna de
          Ubaj pode ser tão forte em um local que corrompe toda vida na área.
          Monstros vagam pelas terras desprotegidas ameaçando aqueles que
          decidem se aventurar. Os monstros são popularmente conhecidos como
          Zumbis, Esqueletos, Aberrações e Corrompidos. Claramente, os
          aventureiros mais viajados conhecem diferentes versões destes monstros
          e têm inúmeras historias sobre as mais diferentes criaturas, falando
          até de criaturas explosivas cheias de pragas, seres demoníacos e
          outros.
          <h3>Sobre os Demônios:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          Servos diretos de Ubaj, nascidos no submundo. São fortes e não são
          vistos comumente. Geralmente só se encontram em locais altamente
          corrompidos e com conexão direta com o reino de Ubaj. Ouve-se
          histórias sobre portais unidirecionais criados pelo Culto dos
          Feiticeiros ou até mesmo diretamente por Ubaj, trazendo estes demônios
          para a terra dos vivos. São criaturas de aparência perturbadora,
          desfiguradas ou fantasmagóricas.
          <hr style="margin-top: 25px;" />

          <!-- Video -->
          <h2 style="text-align: center">Video:</h2>
          <iframe
            style="margin-left: 15%"
            class="wikiMainInfo"
            width="70%"
            height="415"
            src="https://www.youtube.com/embed/C1RSz5vWu3M"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div style="clear: right; clear: left;"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdventureWiki",
  data() {
    return {};
  },
};
</script>
