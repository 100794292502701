import {get, put, destroy} from '../utils/Request'
export default {
  methods: {
    getChances(){
      return get("/chances")
      .then((response) => response.json());
    },
    showChance(id){
      return get("/chances/"+id)
      .then((response) => response.json());
    },
    updateChanceRequest(id, body ={}) {
      return put("/chances/"+id, body)
      .then((response) => {

        return response
      })
    },
    destroyChanceRequest(id) {
      return destroy("/chances/"+id)
      .then((response) => {

        return response
      })
    }
  },
}
