<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        Lista de Categoria de Items
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >

      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <div
        v-if="editMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in editMessages" :key="message">{{ message }}</div>
        </transition-group>
      </div>

      <div class="col-12 text-center justify-self-center justify-content-center row mx-0 px-0 my-0 py-0" style="border: 1px black;">
         <p class="text-center col-7 mx-0 px-0"> - As categorias carregam o preço base que será usado para calcular todos itens da categoria </p>
         <p class="text-center col-7 mx-0 px-0"> - A dificuldade do item base escolhido implicará no preço de todos os outros itens da categoria</p>
      </div>

      <div v-if="isAdmin() && is_loading_item_categories" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>

      <div  v-if="isAdmin() && !is_loading_item_categories" class="staff-team row col-12 mx-0 px-0 justify-content-around">

        <div
          v-for="item_category in item_categories"
          :key="item_category.id"
          class="staticcard col-lg-5 my-3 mx-3 px-0 row align-content-start py-5 justify-content-center"
        >
          <ItemCategoryCard v-if="loadedItemCategory" :item_category_p="item_category" @reload-item_categories="loadItemCategories()"></ItemCategoryCard>
        </div>
        <div
          class="staticcard col-lg-5 my-3 mx-3 px-0 row align-content-start py-5 justify-content-center"
        >
          <h4 class="col-12 mx-o mb-3">Criar Categoria</h4>
          <p class="col-12 mx-0">Nome:</p> <input class="col-8 mx-0" type="text" v-model="new_category_name">
          <div class="col-12 row px-4 justify-content-center mx-0 my-3">
            <i class="far fa-plus-square fa-4x mx-0 px-0 py-2 col-2 minebutton-large" v-on:click="createItemCategory()"></i>
          </div>
        </div>

        <div v-if="item_categories.length == 0" class="col-7">
          <h4>Sem Categoria de Itens registradas no momento...</h4>
        </div>
      </div>


      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>

    </div>
  </div>
</template>

<script>
import ItemCategory from "./../../mixins/ItemCategoryMixin";

import { directive } from "vue-tippy";
import ItemCategoryCard from "@/components/items/ItemCategoryCard";

export default {
  name: "ItemManageTable",
  props: {},
  components: {
    ItemCategoryCard,
  },
  data() {
    return {
      item_categories: [],
      editErrors: [],
      editMessages: [],
      is_loading_item_categories: true,
      loadedItemCategory: false,
      filter_selected: "all",
      old_filter_selected: "all",
      new_category_name: "Nova Categoria"
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
     createItemCategory(){
        this.createItemCategoryRequest({name: this.new_category_name, item_id: 1, price: 1})
        .then(data =>{
            if (data.status){
            this.editMessages.push("Categoria criada com sucesso!")
            this.reloadMerchant();

            setTimeout(() => {
                this.editMessages.splice(0, 1);
            }, 5000);
            } else {
            this.editErrors.push("Houve um erro ao tentar criar esta Categoria.")
            setTimeout(() => {
                this.editErrors.splice(0, 1);
            }, 5000);
            }
        })
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    loadItemCategories() {
      this.getItemCategories().then((categories) => {
        this.item_categories = categories;
        this.loadedItemCategory = true;
        this.is_loading_item_categories = false;
      });
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [ItemCategory],
  beforeMount() {
    this.loadItemCategories();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin,
.programmer,
.lorer,
.builder {
  cursor: pointer;
}
</style>
