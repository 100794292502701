<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div
      class="main-content row col-11 px-0 justify-content-center align-content-center mx-0"
    >
      <form
        id="password-reset-form"
        class="col-12 row justify-content-center align-content-center mx-0 px-0"
        ref="passwordResetForm"
        v-if="reset_password_token"
      >
        <fieldset class="whitefield my-5 py-4 col-lg-5 col-8 mx-0 my-4">
          <h4>Crie uma nova senha:</h4>

          <div
            v-if="passwordResetErrors.length > 0"
            role="alert"
            class="mx-0 px-4 alert alert-danger"
          >
            <ul v-for="error in passwordResetErrors" :key="error">
              <li>{{ error }}</li>
            </ul>
          </div>
          <div
            v-if="passwordResetMessages.length > 0"
            role="alert"
            class="mx-0 px-4 alert alert-success"
          >
            <ul v-for="message in passwordResetMessages" :key="message">
              <li>{{ message }}</li>
            </ul>
          </div>
          <hr />

          <p>Senha:</p>
          <input
            id="password"
            type="password"
            class="mineinput"
            name="password"
            required
          />
          <p>Confirmação da senha:</p>
          <input
            id="password_confirmation"
            type="password"
            class="mineinput"
            name="password_confirmation"
            required
          />
          <br /><br />
          <button
            v-on:click.prevent="sendPasswordResetForm"
            class="minebutton mb-2"
          >
            Definir senha
          </button>
          <br />
        </fieldset>
      </form>
    </div>
  </div>
</template>
<script>
import { patch } from "./../../utils/Request";

export default {
  name: "Staff",
  data() {
    return {
      passwordResetMessages: [],
      passwordResetErrors: [],
    };
  },
  computed: {
    reset_password_token() {
      return this.$route.query.reset_password_token;
    },
  },
  methods: {
    sendPasswordResetForm() {
      this.passwordResetErrors = [];
      this.passwordResetMessages = [];
      var password_confirmation = this.$refs.passwordResetForm.password_confirmation.value;
      var password = this.$refs.passwordResetForm.password.value;

      patch("/users/password", {
        user: {
          password: password,
          password_confirmation: password_confirmation,
          reset_password_token: this.reset_password_token
        }
      })
        .then((response) =>{
          if (response.status >= 200 && response.status <=204){
             this.passwordResetMessages.push(
              "Senha trocada com sucesso! Tente fazer login com sua nova senha."
            );
          } else {
            return response.json()
          }
        })
        .then((data) => {
          if (data.errors) {
            this.passwordResetErrors.push(
              "Houve um erro ao tentar trocar a senha! Inicie o processo de troca de senha novamente, por favor. Se o erro persistir, contate um dos admnistradores."
            );
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
  },
};
</script>
