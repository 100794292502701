import {get, patch, destroy} from './../utils/Request'
export default {
  methods: {
    getItems(){
      return get("/items")
      .then((response) => response.json());
    },
    getFilteredItems(item_category_id){
      return get("/items/by_category?item_category_id="+item_category_id)
      .then((response) => response.json());
    },

    showItem(id){
      return get("/items/"+id)
      .then((response) => response.json());
    },
    updateRequest(id, body ={}) {
      return patch("/items/"+id, body)
      .then((response) => {

        return response
      })
    },
    destroyRequest(id, body ={}) {
      return destroy("/items/"+id, body)
      .then((response) => {

        return response.json()
      })
    },
    filteredLore(lore){
      if (lore !== "" && lore !== undefined && lore !== null){
        var loreArray = lore.split(";");
        var loreText = "";
        for (let i = 0; i<loreArray.length; i++){
          loreArray[i] = this.replaceColorCodes(loreArray[i], true);
          loreText += loreArray[i];
        }
        return loreText;
      }
      return lore
    },
    replaceColorCodes(loreLine, shouldBreak){
      if (!loreLine.includes("§x")) {
        loreLine = loreLine.replace("§f", "");

        loreLine = loreLine.replace("§4", "<span class='mx-0' style='color: #AA0000;'>");
        loreLine = loreLine.replace("§c", "<span class='mx-0' style='color: #FF5555;'>");
        loreLine = loreLine.replace("§6", "<span class='mx-0' style='color: #FFAA00;'>");
        loreLine = loreLine.replace("§e", "<span class='mx-0' style='color: #FFFF55;'>");
        loreLine = loreLine.replace("§2", "<span class='mx-0' style='color: #00AA00;'>");
        loreLine = loreLine.replace("§a", "<span class='mx-0' style='color: #55FF55;'>");
        loreLine = loreLine.replace("§b", "<span class='mx-0' style='color: #55FFFF;'>");
        loreLine = loreLine.replace("§3", "<span class='mx-0' style='color: #00AAAA;'>");

        loreLine = loreLine.replace("§1", "<span class='mx-0' style='color: #0000AA;'>");
        loreLine = loreLine.replace("§9", "<span class='mx-0' style='color: #5555FF;'>");
        loreLine = loreLine.replace("§d", "<span class='mx-0' style='color: #FF55FF;'>");
        loreLine = loreLine.replace("§5", "<span class='mx-0' style='color: #AA00AA;'>");

        if (shouldBreak){
          return loreLine+"<br>";
        } else {
          return loreLine+"</span>";
        }
      }
      loreLine = loreLine.replace("§x", "");
      let colorCode = loreLine.substring(0, 12);
      loreLine =loreLine.replace(colorCode, "");

      colorCode = colorCode.replace("§", "");
      colorCode = colorCode.replace("§", "");
      colorCode = colorCode.replace("§", "");
      colorCode = colorCode.replace("§", "");
      colorCode = colorCode.replace("§", "");
      colorCode = colorCode.replace("§", "");

      loreLine = `<span style="color: #`+colorCode+`;">`+loreLine+`</span><br>`;
      return loreLine;
    },
  },
}
