<template>
  <div
    class=" col-12 row mx-0 px-0 main-content justify-content-center align-content-start"
  >
    <h2 class="col-12 text-center">Editar Loot</h2>
    <div
      v-if="!isAdmin()"
      class="col-xl-6 col-lg-9 col-11 justify-self-center text-center my-4"
    >
      <div role="alert" class="alert alert-danger">
        Somente admnistradores podem editar loots!
      </div>
    </div>

    <div
      v-if="isAdmin()"
      class="staff staff-team row col-12 mx-0 px-0 justify-content-around"
    >
      <div
        v-if="isLoading"
        class="col-xl-6 col-lg-9 col-11 mx-auto px-0 my-0 py-0"
      >
        <img :src="'../../img/loading.gif'" alt="" class="loader-gif" />
      </div>

      <div v-if="!isLoading" class="col-xl-9 col-lg-11 col-12 row mx-0 px-0 justify-content-center">
         <a class="col-12 mx-0 py-0 my-0 h-0 text-right" href="/loots"
            ><i class="fas fa-times fa-2x backbtn"></i
          ></a>
        <h3 class="col-9 mx-0 px-0 text-center">{{name}}</h3>
        
        <br />

        
        <div style="font-size: 12px" class="my-2 col-9 mx-0 px-0">
          <div class="mt-0" v-if="observation">Observação:</div>
          {{observation}}
        </div>

        <div class="row col-12 mx-0 px-0 my-2 py-2">
          <h5 class="col-12 mx-0 px-5 text-center my-1">Itens neste Loot:</h5>
          <table class="table-dark table-bordered table-hover col-lg-12 col-12 mx-0 py-0 px-0 my-0 dark-box">
            <thead>
              <tr>
                <th scope="col">Item</th>
                <th scope="col">Peso Base</th>
                <th scope="col">Peso por Nv. Monstro</th>
                <th scope="col">Peso por Nv. Jogador</th>
                <th scope="col">Qnt. Minima</th>
                <th scope="col">Qnt. Máxima</th>
                <th scope="col">Aplicar</th>
                <th scope="col">Deletar</th>
              </tr>
            </thead>

            <tbody v-for="loot_item in loot_items" :key="loot_item.id">
              <tr>
                <th scope="row">{{ loot_item.item.name }}</th>
                <td>
                  <div class="row col-12 justify-content-center mx-0 px-0">
                    <input
                      autocomplete="false"
                      type="numeric"
                      class="col-5"
                      v-model="loot_item.base_weight"
                      :id="loot_item.id+'_base_weight'"
                    />
                  </div>
                </td>
                <td>
                  <div class="row col-12 justify-content-center mx-0 px-0">
                    <input
                      autocomplete="false"
                      type="numeric"
                      class="col-5"
                      v-model="loot_item.mob_level_weight"
                      :id="loot_item.id+'_mob_level_weight'"
                    />
                  </div>
                </td>
                <td>
                  <div class="row col-12 justify-content-center mx-0 px-0">
                    <input
                      autocomplete="false"
                      type="numeric"
                      class="col-5"
                      v-model="loot_item.player_level_weight"
                      :id="loot_item.id+'_player_level_weight'"
                    />
                  </div>
                </td>
                <td>
                 <div class="row col-12 justify-content-center mx-0 px-0">
                    <input
                      autocomplete="false"
                      type="numeric"
                      class="col-5"
                      v-model="loot_item.min_quantity"
                      :id="loot_item.id+'_min_quantity'"
                    />
                  </div>
                </td>
                <td>
                  <div class="row col-12 justify-content-center mx-0 px-0">
                    <input
                      autocomplete="false"
                      type="numeric"
                      class="col-5"
                      v-model="loot_item.max_quantity"
                      :id="loot_item.id+'_max_quantity'"
                    />
                  </div>
                </td>
                <td>
                  <div class="row col-12 justify-content-center mx-0 px-1 py-1">
                    <i v-on:click="updateLootItem(loot_item.id)" style="color: green; cursor: pointer" class="fas fa-check fa-2x"></i>
                  </div>
                </td>
                <td>
                  <div class="row col-12 justify-content-center mx-0 px-1 py-1">
                      <i v-on:click="destroyLootItem(loot_item.id)" style="color: darkred; cursor: pointer" class="fas fa-trash-alt fa-2x"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="col-12 mx-0 px-0 justify-self-center my-0 pb-2 pt-5 row justify-content-center">
            <h5 class="col-12 mx-0 px-0 text-center my-1"> Escolha um item para adicionar na lista </h5>
            <select class="col-6 mx-5 px-0 text-left" name="new_item" id="new_item_id" v-model="new_item_id">
              <option v-for="item in items" :key="item.id" :value="item.id"> 
                {{item.name}}
                |
                {{item.lore}}
              </option>
            </select>
            <button
              class="minebutton col-5 my-3 mx-5"
              v-on:click.prevent="createLootItem()"
            >
              Adicionar item
            </button>
          </div>

          <div class="col-12 mx-0 px-0 justify-self-center my-0 pb-2 pt-5 row justify-content-center">
             <h5 class="col-12 mx-0 px-0 text-center my-1"> Copie esta tabela para outro Loot: </h5>
            <select class="col-6 mx-5 px-0 text-left" name="other_loot" id="other_loot_id" v-model="other_loot_id">
              <option v-for="other_loot in other_loots" :key="other_loot.id" :value="other_loot.id"> 
                {{other_loot.name}}
              </option>
            </select>
            <button
              class="minebutton col-5 my-3 mx-5"
              v-on:click.prevent="copyLootTableTo('paste-table')"
            >
              Substituir
            </button>
            <button
              class="minebutton col-5 my-3 mx-5"
              v-on:click.prevent="copyLootTableTo('add-table')"
            >
              Adicionar
            </button>
          </div>
          <div
            class="col-12 my-0 row justify-content-center align-content-start mx-0"
          >
            <div
              v-if="editErrors.length > 0"
              class="alert alert-danger col-8 mx-5"
              role="alert"
            >
              <transition-group name="slide-fade" mode="out-in">
                <div v-for="error in editErrors" :key="error">{{ error }}</div>
              </transition-group>
            </div>
            
            <div
              v-if="editMessages.length > 0"
              class="alert alert-success col-8 mx-5 mt-4"
              role="alert"
            >
              <transition-group name="slide-fade" mode="out-in">
                <div v-for="message in editMessages" :key="message">{{ message }}</div>         
              </transition-group>
            </div>
          </div>

          <div class="col-12 row mx-0 px-0 justify-self-center justify-content-around align-content-start my-2 pb-2 pt-5 text-left">
            <h5 class="col-12 mx-0 px-0 text-center mb-3"> Resultados esperados: </h5>
            
            <h6 class="col-12 mx-0 px-0 text-center mb-0"> Level 1: </h6>
            
           <div class="col-2 shopcard mx-0 px-0 row justify-content-center align-content-around" v-for="loot_item in loot_items" :key="loot_item.id">
              <div class="col-12 mx-0 px-0 mb-3 text-center" v-html="replaceColorCodes(loot_item.item.name)"></div>
              <hr class="col-10 mx-0 px-0 my-0">
              <div class="col-12 mx-0 my-2 py-2 px-1 text-center">  Chance: {{ getChanceFor(loot_item) }}% </div>
            </div>
            <h6 class="col-12 mx-0 px-0 text-center mb-0"> Level 50: </h6>
           
            <div class="col-2 shopcard mx-0 px-0 py-0 row justify-content-center align-content-around" v-for="loot_item in loot_items" :key="loot_item.id">
              <div class="col-12 mx-0 px-0 mb-3 text-center" v-html="replaceColorCodes(loot_item.item.name)"></div>
              <hr class="col-10 mx-0 px-0 my-0">
              <div class="col-12 mx-0 my-2 py-2 px-1 text-center"> Chance: {{ getChanceForLvlMax(loot_item) }}% </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post, patch, destroy } from "../../utils/Request";
import Loot from "../../mixins/LootMixin";
import Item from '../../mixins/ItemMixin'

export default {
  name: "Edit",
  data() {
    return {
      other_loot_id: "",
      other_loots: [],
      new_item_id: "",
      name: "",
      observation: "",
      loot_id: "",
      loot_items: [],
      items: "",
      isLoading: true,
      bot_api_url: process.env.VUE_APP_BOT_API_URL,
      editErrors: [],
      editMessages: [],
    };
  },
  mixins: [Loot, Item],
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    filterDescription(description) {
      description = this.filteredLore(description);

      if (description.length >= 43+30) {
        description = description.slice(0, 43+29) + "...";
        return description;
      }
      return description;
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    getLoot() {
      this.isLoading = true;
      this.loot_id = this.$route.params.id
      this.showLoot(this.loot_id).then((data) => {
        if (data != undefined){
          this.name = data.name;
          this.observation = data.observation;
          this.isLoading = false;
          this.getLootItems();
        }
      });
    },
    getLootItems() {
      get("/loots/"+this.loot_id+"/loot_items")
        .then((response) => response.json())
        .then((data) => {
          this.loot_items = data;
        });
    },
    getOtherLoots() {
      get("/loots")
        .then((response) => response.json())
        .then((data) => {
           this.other_loots = data;
          for (var i = 0; i < this.other_loots.length; i++) {
            if (this.other_loots[i].id == this.loot_id) {
              this.other_loots.splice(i, 1);
            }
          }
        });
    },
    copyLootTableTo(route){
      let hash = { loot_id: this.other_loot_id, loot_items: this.loot_items }
      post("/loots/"+route, hash)
        .then((response) => response.json())
        .then((data) => {
          if (!this.hasDataError(data)){
             this.editMessages.push("O Loot foi atualizado a partir deste com sucesso!")
              setTimeout(() => {
                this.editMessages.splice(0, 1);
              }, 5000);
          } else {
            this.editErrors.push("Não foi possível atualizar o loot a partir deste! :(")
              setTimeout(() => {
                this.editMessages.splice(0, 1);
              }, 5000);
          }
        })
    },
    getItems() {
      get("/items")
        .then((response) => response.json())
        .then((data) => {
          this.items = data;
        });
    },
    createLootItem(){
      
      post("/loot_items", {loot_id: this.loot_id, item_id: this.new_item_id})
        .then((response) => response.json())
        .then((data) => {
          if (!this.hasDataError(data)){
            this.$router.go(this.$router.currentRoute)
          }
        })
    },
    updateLootItem(loot_item_id){
      let baseWeight= parseFloat(document.getElementById(loot_item_id+"_base_weight").value).toFixed(4)
      let mobWeight = parseFloat(document.getElementById(loot_item_id+"_mob_level_weight").value).toFixed(4)
      let playerWeight = parseFloat(document.getElementById(loot_item_id+"_player_level_weight").value).toFixed(4)
      let minQnt = parseInt(document.getElementById(loot_item_id+"_min_quantity").value)
      let maxQnt = parseInt(document.getElementById(loot_item_id+"_max_quantity").value)

      patch("/loot_items/"+loot_item_id, { loot_item: {id: loot_item_id, base_weight: baseWeight, mob_level_weight: mobWeight, player_level_weight: playerWeight, min_quantity: minQnt, max_quantity: maxQnt}})
        .then((response) => response.json())
        .then((data) => {
          if (!this.hasDataError(data)){
             this.editMessages.push("Item de Loot atualizado com sucesso!")
              setTimeout(() => {
                this.editMessages.splice(0, 1);
              }, 5000);
          } else {
            this.editErrors.push("Não foi possível atualizar este Item de Loot! :(")
              setTimeout(() => {
                this.editMessages.splice(0, 1);
              }, 5000);
          }
        })
    },
    destroyLootItem(loot_item_id){
      destroy("/loot_items/"+loot_item_id)
        .then((response) => response)
        .then(() => {
          this.getLootItems();
          this.editMessages.push("Item de Loot removido com sucesso!")
           setTimeout(() => {
            this.editMessages.splice(0, 1);
          }, 5000);
        })
    },
    hasDataError(data){
      if (data.errors !== undefined && data.errors !== null && data.errors !== []){
        return true;
      }
      if (data.error !== undefined || data.error !== null && data.error === []){
        return true;
      }
      return false;
    },
    getFullWeight(){
      let weight = 0;
      for (let i = 0; i < this.loot_items.length; i++){
        let loot_item = this.loot_items[i]
        weight += (loot_item.base_weight*1) + (loot_item.mob_level_weight*1) + (loot_item.player_level_weight*1)
      }
      return weight;
    },
    getFullWeightForLvlMax(){
      let weight = 0;
      for (let i = 0; i < this.loot_items.length; i++){
        let loot_item = this.loot_items[i]
        weight += (loot_item.base_weight*1) + (loot_item.mob_level_weight*50) + (loot_item.player_level_weight*50)
      }
      return weight;
    },
    getChanceFor(loot_item){
      let fullWeight = this.getFullWeight();
      let weight = (loot_item.base_weight*1) + (loot_item.mob_level_weight*1) + (loot_item.player_level_weight*1)
      return ((weight/fullWeight)*100).toFixed(2);
    },
    getChanceForLvlMax(loot_item){
      let fullWeight = this.getFullWeightForLvlMax();
      let weight = (loot_item.base_weight*1) + (loot_item.mob_level_weight*50) + (loot_item.player_level_weight*50)
      return ((weight/fullWeight)*100).toFixed(2);
    }
  },

  beforeMount() {
    this.getLoot();
    this.getItems();
    this.getOtherLoots()
  },
};
</script>
<style scoped>
.unchecked {
  filter: opacity(25%);
}
.unchecked:hover {
  filter: opacity(75%);
}
.clickable {
  cursor: pointer;
}
.loader-gif {
  max-width: 250px;
  min-width: 250px;
  width: 250px;
}
</style>
