<template>
  <div id="loginModal" class="modal row" style="display: block">
    <!-- Modal content -->
    <div class="modal-content row col-12">
      <span
        class="close col-12 text-right px-0"
        v-on:click="$emit('closeLoginModal')"
        >&times;</span
      >

      <component v-if="isAuthenticated" :is="panel"></component>
      <LoginForms v-else></LoginForms>

      <div style="clear: left"></div>
      <div style="clear: right"></div>

      <br />
    </div>
  </div>
</template>
<script>
import LoginForms from "@/components/login/LoginForms.vue";
import UserPanel from "@/components/login/UserPanel.vue";
import AdminPanel from "@/components/login/AdminPanel.vue";

export default {
  name: "LoginModal",
  components: {
    LoginForms,
    UserPanel,
    AdminPanel,
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
    panel() {
      if (this.isAuthenticated && this.user.attributes.admin) {
        return "AdminPanel";
      } else {
        return "UserPanel";
      }
    },
  },
  watch: {
    "$store.state.user": function () {
      this.isAuthenticated;
    },
  },
};
</script>