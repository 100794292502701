<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-12 px-0 mb-5 justify-content-center mx-0 my-0">
      <h2 class="col-7 mx-0 px-0 mt-3 pb-0 text-center">
        Kompedia
      </h2>
      <hr class="col-7 mx-0 px-0 my-0" />
      <br />

      <div class="container row justify-content-around mx-0 mt-5 px-0 col-12 px-5">
        <div
          class="lastposts col-lg-3 row mx-0 px-0 justify-content-center align-content-start"
        >
          <div class="lpctitle col-12 mx-0 px-0 mt-5 text-center" id="postPart">
            Tópicos:
          </div>

          <hr class="col-12 mx-0 px-0" />

         
          <KompediaPageList v-if="isDataLoaded" :pages="wikiPages" class="col-12 mx-0 px-0 my-0"></KompediaPageList>
        

          <hr class="col-12 mx-0 px-0" />
        </div>
        <div
          id="postvp"
          class="post col-lg-9 col-11 row mx-auto px-0 my-3 justify-content-center align-content-start"
        >
          <div
            v-if="isLoading"
            class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0"
          >
            <img :src="'img/loading.gif'" alt="" class="loader-gif" />
          </div>
          <div
            v-if="!isLoading && actualPage != undefined"
            class="col-12 mx-0 px-0 my-0 py-0 row"
          >
            <h2 class="display-4 text-center col-12 mx-0 px-0">
              {{ actualPage.title }}
            </h2>
            <hr class="col-12 mx-0 px-0" />
            <div class="col-12 mx-0 px-0" v-html="actualPage.content"></div>

            <hr v-if="isAdmin()" class="col-12 mx-0 px-0" />
            <div
              v-if="isAdmin()"
              class="justify-content-center row col-12 mx-0 px-0"
            >
              <i
                class="fas fa-trash-alt fa-2x text-center clickable-icon col-6"
                v-on:click="deletePage(actualPage.id)"
                v-tippy="{ content: 'Apagar' }"
                style="color: darkred"
              ></i>
            </div>
          </div>
          <div
            v-else-if="!isLoading && actualPage == undefined"
            class="col-12 mx-0 px-0 my-0 py-0"
          >
            Você ainda não selecionou uma pagina para ler. Selecione uma no menu ao lado. :)
          </div>
        </div>
        <div style="clear: right"></div>
        <br /><br />

        <div
          class="createPost col-lg-8 col-11 row mx-0 px-0 justify-content-center mt-5"
          v-if="isAdmin()"
        >
          <form ref="createPageForm">
            <br />
            <legend>Nova Subpágina</legend>
            <br />
            Titulo: <br />
            <input id="nptitle" type="text" name="title" /><br /><br />

            Conteúdo: <br />
            <div class="textEditor">
              <editor
                v-model="bodyContent"
                api-key="iq4ail00zfbsrpzfag23785l09rqjcltejm6n3pm2ft3sp2e"
                :init="{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help',
                }"
              />
            </div>
            <div
              v-if="createErrors.length > 0"
              class="alert alert-danger col-8 mx-auto mt-3 mb-0"
              role="alert"
            >
              <transition-group name="slide-fade" mode="out-in">
                <div v-for="error in createErrors" :key="error">
                  {{ error }}
                </div>
              </transition-group>
            </div>
            <input
              
              class="minebutton my-3"
              v-on:click.prevent="requestCreatePage()"
              value="Postar"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import PageMixin from "./../mixins/PageMixin";
import KompediaPageList from "@/components/kompedia/KompediaPageList.vue";

export default {
  name: "Kompedia",
  data() {
    return {
      actualPage: undefined,
      wikiPages: {},
      isLoading: true,
      isDataLoaded: false,
      bodyContent: "",
      createErrors: [],
      api_url: process.env.VUE_APP_API_URL,
    };
  },
  components: {
    Editor, KompediaPageList
  },
  mixins: [PageMixin],
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    requestCreatePage() {
      let form = this.$refs.createPageForm;
      let title = form.title.value;
      let content = this.bodyContent;
      let parent_id = null;
      if (this.actualPage != undefined) {
        parent_id = this.actualPage.id;
      }

      if (
        [content, title].includes(null) ||
        [content, title].includes(undefined) ||
        [content, title].includes("")
      ) {
        this.createErrors.push(
          "Você não inseriu titulo e corpo da página para cria-la"
        );
        setTimeout(() => {
          this.createErrors.splice(0, 1);
        }, 5000);
        return;
      }
      this.createPage({ title, content, parent_id, user_id: this.user.attributes.id }).then(
        () => {
          this.$router.go("/kompedia");
        }
      );
    },
    deletePage(id) {
      this.isLoading = true;
      this.destroyPage(id).then(() => {
        this.$router.go("/kompedia");
      });
    },
    loadPage(id) {
      this.isLoading = true;
      this.showPage(id).then((page) => {
        this.isLoading = false;
        this.actualPage = page;
      });
    },
    loadAll() {
      this.isLoading = true;
      this.getHierarchyPages().then((pages) => {
        this.isLoading = false;
        this.isDataLoaded = true;
        this.wikiPages = pages;
      });
    },
  },
  beforeMount() {
    this.loadAll();
  },
};
</script>
