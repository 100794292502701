<template>
  <div class="classmodalbody">
    <span class="close" onclick="dynamic_Select('classesInfo/aboutClasses')"
      >&times;</span
    >

    <div style="clear: right"></div>

    <div class="classtitle">
      <h1 style="margin: 0px">Alquimista</h1>
      <hr />
      <h2 style="margin: 0px; color:darkgreen">O Manipulador da Natureza</h2>
    </div>
    <div class="row justify-content-center">
      <div class="charpic col-8 col-md-3">
        <img src="/img/alchemistchar.png" style="width: 100%;" alt="" />
      </div>
      <div style="clear: left;"/>

      <div class="col-12 col-md-4">
        Ativas:
        <hr style="width: 50%; margin-left: 0px" />
        <ul>
          
          <li v-for="skill in skills" :key="skill"  :class="{ skillArrow: !skill.isArrowFlipped, skillArrowFlipped: skill.isArrowFlipped}"  v-on:click="openTab(skill.skillNumber)">
            <div>
              <h4 class="skillTitle">{{skill.skillName}}</h4>

              <div
                v-if="skill.isSkillBoxOn"
                :id="'skill'+skill.skillNumber"
                :ref="'skill'+skill.skillNumber"
                :class="{ skillBoxOn: skill.isSkillBoxOn}"
                style="display: none; text-align: left;"
              >
                <p style="text-align: center">Descrição da Habilidade:</p>
                <div
                  style="text-align: center"
                  class="row justify-content-center"
                >
                </div>
                <hr style="width: 92.5%" />

                <span v-html="skill.description"></span>
                <hr style="width: 92.5%" />
                <div v-if="skill.howToUse">
                  Como usar:
                  <span v-html="skill.howToUse"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        Passivas:
        <hr style="width: 50%; margin-left: 0px" />
        <ul>
          <li>Conhecimento Aprofundado da Natureza</li>
          <li>Imune a Veneno</li>
          <li>Vulnerabilidade Explorada</li>
          <li>Controle do Frio</li>
          <li>Conhecimento do Oculto</li>
          <li>Cinto</li>
        </ul>
      </div>
    </div>
    <div style="clear: left;"></div>

    <div>
      <div>
        <hr />
        <p>
          "Por possuir alto conhecimento sobre a natureza e sobre magia, o
          alquimista é capaz de manipular matéria e criar substâncias de efeitos
          colaterais altíssimos; as famosas poções almejadas por todos."<br />
        </p>
        <p>
          Sua genialidade e a audaciosa relação que faz da magia com a ciência
          lhe permite ser um combatente extremamente perigoso, que usa da
          manipulação de energia, espaço, tempo e matéria para oprimir seus
          oponentes de forma cruel.<br />
        </p>
        <hr />
      </div>

      <h2 style="text-align: center; margin-top: 0px">Combos</h2>
      <p>
        As Habilidades Ativas são executadas através de combos! <br />
        Abaixo estarão os combos necessários para ativar as habilidades do
        Alquimista
      </p>
      <div class="combosubtitle">
        <h4>Legenda:</h4>

        <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> : Olhando
        para cima / <i class="fas fa-arrow-down"> </i
        ><i class="fas fa-eye"></i> : Olhando para baixo /
        <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> :
        Shift <br />
        <i class="fas fa-running runact"></i> : Correndo /
        <i class="fas fa-mouse leftclick"></i> : Clique Esquerdo /
        <i class="fas fa-mouse rightclick"></i> : Clique Direito
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="combosubtitle" style="float: left">
          Aura Entoxicante: <br />
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          +
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Ilusão de Chuva Tóxica: <br />
          <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
          <i class="fas fa-mouse leftclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Sopro Profano: <br />
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          + <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Distorção: <br />
          <i class="fas fa-running runact"></i> +
          <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div style="clear: left;"></div>
      </div>
    </div>
    <div style="clear: left;"></div>
    <div style="clear: right;"></div>

    <br />
    <hr />
    <div>
      <h2 style="text-align: center">Video:</h2>

      <iframe
        class="wikiMainInfo"
        style="margin-left: 15%; padding:0px"
        width="70%"
        height="415"
        src="https://www.youtube.com/embed/sFN8OXknPfk"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: "Alchemist",
  data() {
    return {
      skills: [
        { 
          skillNumber: 1,
          skillName: 'Aura Entoxicante',
          description: 'Libera gáses ao seu redor que entoxica e cega quem chega perto. <br /> Duração: 5s',
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
                +
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>`
        },
        { 
          skillNumber: 2,
          skillName: 'Ilusão de Chuva Tóxica',
          description: `Atordoa a mente de adversários próximos fazendo-os
          crer que estão ardendo em uma chuva tóxica.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `  <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
          <i class="fas fa-mouse leftclick"></i> +
          <i class="fas fa-mouse rightclick"></i>`
        },
        { 
          skillNumber: 3,
          skillName: 'Sopro Profano',
          description: `O Alquimista sopra
          seus gáses venenosos em uma direção, envenenado e jogando para trás
          quem estiver no caminho.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: ` <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          + <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>`
        },
        { 
          skillNumber: 4,
          skillName: 'Distorção',
          description: `Prende seu alvo numa
          distorção no espaço-tempo, oprimindo-o de maneira obscura.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-running runact"></i> +
          <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>`
        },
      ],
    };
  },
  methods:{ 
    openTab(number) {
      _.each(this.skills, skill => {
        if (skill.skillNumber == number){
              skill.isSkillBoxOn = !skill.isSkillBoxOn
           
              skill.isArrowFlipped = !skill.isArrowFlipped
        } else {
          if (skill.isSkillBoxOn){
              skill.isSkillBoxOn = false
           }
          if (skill.isArrowFlipped){
              skill.isArrowFlipped = false
           }
        }
      });
    },
  },
};
</script>
