import Cookie from "js-cookie"

function get(path, query, header_options = null) {

    var headers = {}
    if (Cookie.get("user") != undefined ){
    
        let token = JSON.parse(Cookie.get("user")).token 
        if (!(header_options)) {
            headers = Object.assign(headers, {"Authorization": "Bearer "+token})
        }
    }
    if (header_options != null) {
        headers = Object.assign(headers, header_options)
    }
    

    var url_string = process.env.VUE_APP_API_URL+path
    if (path.includes("https://") || path.includes("http://")){
        url_string = path
    }
    var url = new URL(url_string);

    if (query != null) url.search = new URLSearchParams(query).toString();

    return fetch(url, {
        mode: "cors",
        method: "get",
        headers
    })
}

function post(path, body, header_options = null) {
    let headers = {
        'Content-Type': 'application/json',
    }
    if (header_options != null) {
        headers = Object.assign(headers, header_options)
    }
    
    if (Cookie.get("user") != undefined ){
    
        let token = JSON.parse(Cookie.get("user")).token 
        if (!(header_options)) {
            headers = Object.assign(headers, {"Authorization": "Bearer "+token})
        }
    }
    var url_string = process.env.VUE_APP_API_URL+path
    if (path.includes("https://") || path.includes("http://")){
        url_string = path
    }
    var url = new URL(url_string);
    let requestOptions = {
        mode: "cors",
        method: "post",
        headers
    }
    if (body != null) {
        requestOptions = Object.assign(requestOptions, { body: JSON.stringify(body) })
    }

    return fetch(url, requestOptions)
}

function put(path, body, header_options = null) {
    let headers = {
        'Content-Type': 'application/json',
    }
    if (header_options != null) {
        headers = Object.assign(headers, header_options)
    }
    
    if (Cookie.get("user") != undefined ){
    
        let token = JSON.parse(Cookie.get("user")).token 
        if (!(header_options)) {
            headers = Object.assign(headers, {"Authorization": "Bearer "+token})
        }
    }
    var url_string = process.env.VUE_APP_API_URL+path
    if (path.includes("https://") || path.includes("http://")){
        url_string = path
    }
    var url = new URL(url_string);
    let requestOptions = {
        mode: "cors",
        method: "put",
        headers
    }
    if (body != null) {
        requestOptions = Object.assign(requestOptions, { body: JSON.stringify(body) })
    }

    return fetch(url, requestOptions)
}

function patch(path, body, header_options = null) {
    let headers = {
        'Content-Type': 'application/json',
    }
    if (header_options != null) {
        headers = Object.assign(headers, header_options)
    }
    
    if (Cookie.get("user") != undefined ){
    
        let token = JSON.parse(Cookie.get("user")).token 
        if (!(header_options)) {
            headers = Object.assign(headers, {"Authorization": "Bearer "+token})
        }
    }
    var url_string = process.env.VUE_APP_API_URL+path
    if (path.includes("https://") || path.includes("http://")){
        url_string = path
    }
    var url = new URL(url_string);
    let requestOptions = {
        mode: "cors",
        method: "put",
        headers
    }
    if (body != null) {
        requestOptions = Object.assign(requestOptions, { body: JSON.stringify(body) })
    }

    return fetch(url, requestOptions)
}

function destroy(path, query, header_options = null) {
    let headers = {
        'Content-Type': 'application/json',
    }
    
    if (header_options != null) {
        headers = Object.assign(headers, header_options)
    }
    if (Cookie.get("user") != undefined ){
    
        let token = JSON.parse(Cookie.get("user")).token 
        if (!(header_options)) {
            headers = Object.assign(headers, {"Authorization": "Bearer "+token})
        }
    }
    var url_string = process.env.VUE_APP_API_URL+path
    if (path.includes("https://") || path.includes("http://")){
        url_string = path
    }
    var url = new URL(url_string);
    url.search = new URLSearchParams(query).toString();
    
    return fetch(url, {
        mode: "cors",
        method: "delete",
        headers
    })
}

export {
    destroy,
    get,
    post,
    patch,
    put,
}