<template>
  <div class="classmodalbody">
    <span class="close" v-on:click="$parent.dynamicSelect('About')"
      >&times;</span
    >

    <div style="clear: right"></div>

    <div class="classtitle">
      <h1 style="margin: 0px">Lenhador</h1>
      <hr />
      <h2 style="margin: 0px; color:rgb(107, 145, 61)">O Reflorestador</h2>
    </div>
    <div class="row justify-content-center">
      <div class="charpic col-8 col-md-3">
        <img src="/img/lumberjackchar.png" style="width: 100%;" alt="" />
      </div>
      <div style="clear: left;"/>

      <div class="col-12 col-md-4">
        Ativas:
        <hr style="width: 50%; margin-left: 0px" />
         <ul>
          
          <li v-for="skill in skills" :key="skill"  :class="{ skillArrow: !skill.isArrowFlipped, skillArrowFlipped: skill.isArrowFlipped}"  v-on:click="openTab(skill.skillNumber)">
            <div>
              <h4 class="skillTitle">{{skill.skillName}}</h4>

              <div
                v-if="skill.isSkillBoxOn"
                :id="'skill'+skill.skillNumber"
                :ref="'skill'+skill.skillNumber"
                :class="{ skillBoxOn: skill.isSkillBoxOn}"
                style="display: none; text-align: left;"
              >
                <p style="text-align: center">Descrição da Habilidade:</p>
                <div
                  style="text-align: center"
                  class="row justify-content-center"
                >
                </div>
                <hr style="width: 92.5%" />

                <span v-html="skill.description"></span>
                <hr style="width: 92.5%" />
                <div v-if="skill.howToUse">
                  Como usar:
                  <span v-html="skill.howToUse"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        Passivas:
        <hr style="width: 50%; margin-left: 0px" />
        <ul>
          <li>Carpinteiro Iniciante</li>
          <li>Construtor Habilidoso</li>
          <li>Carpinteiro Aspirante</li>
          <li>Corte Eficiente</li>
          <li>Amigo da Natureza</li>
          <li>Instinto de Sobrevivência</li>
        </ul>
      </div>
    </div>
    <div style="clear: left;"></div>

    <div>
      <div>
        <hr />
        <p>
          "Lenhadores são responsáveis pela matéria-prima mais importante: a
          madeira."<br />
        </p>
        <p>
          São eximios reflorestadores, amigáveis com a natureza, usam seu
          machado extremamente afiado para lhe auxiliar no trabalho e no
          combate.<br />
        </p>
        <hr />
      </div>

      <h2 style="text-align: center; margin-top: 0px">Combos</h2>
      <p>
        As Habilidades Ativas são executadas através de combos! <br />
        Abaixo estarão os combos necessários para ativar as habilidades do
        Lenhador
      </p>
      <div class="combosubtitle">
        <h4>Legenda:</h4>

        <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> : Olhando
        para cima / <i class="fas fa-arrow-down"> </i
        ><i class="fas fa-eye"></i> : Olhando para baixo /
        <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> :
        Shift <br />
        <i class="fas fa-running runact"></i> : Correndo /
        <i class="fas fa-mouse leftclick"></i> : Clique Esquerdo /
        <i class="fas fa-mouse rightclick"></i> : Clique Direito
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="combosubtitle" style="float: left">
          Concentração: <br />
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          +
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Salto do Lenhador: <br />
          <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
          <i class="fas fa-mouse leftclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Arremesso Destruidor: <br />
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          + <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Machadada Épica: <br />
          <i class="fas fa-running runact"></i> +
          <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div style="clear: left;"></div>
      </div>
    </div>
    <div style="clear: left;"></div>
    <div style="clear: right;"></div>

    <br />
    <hr />
    <div>
      <h2 style="text-align: center">Video:</h2>

      <iframe
        style="margin-left: 15%; padding:0px;"
        class="wikiMainInfo"
        width="70%"
        height="415"
        src="https://www.youtube.com/embed/Xkk5vEF4hIg"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Lumberjack",
  data() {
    return {
      skills: [
        {
          skillNumber: 1,
          skillName: "Concentração",
          description: `Aumenta as chances de realizar o trabalho com sucesso. <br> Duração: 16s`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>`,
        },
        {
          skillNumber: 2,
          skillName: "Salto do Lenhador",
          description: "Dá um salto fortemente impulsionado para a frente.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 3,
          skillName: "Arremesso Destruidor",
          description: "Arremessa o machado com força.Pode quebrar blocos de madeira se pegar bem.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 4,
          skillName: "Machadada Épica",
          description: "Se prepara para dar uma machadada muito forte!",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-running runact"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
      ],
    };
  },
  methods: {
    openTab(number) {

      _.each(this.skills, (skill) => {
        if (skill.skillNumber == number) {
          skill.isSkillBoxOn = !skill.isSkillBoxOn;

          skill.isArrowFlipped = !skill.isArrowFlipped;
        } else {
          if (skill.isSkillBoxOn) {
            skill.isSkillBoxOn = false;
          }
          if (skill.isArrowFlipped) {
            skill.isArrowFlipped = false;
          }
        }
      });

    },
  },
};
</script>
