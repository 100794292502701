import {get, patch, post} from './../utils/Request'
export default {
  methods: {
    getGameConfigs(scope){
      return get("/game_configs/from-scope?scope="+scope)
      .then((response) => response.json());
    },
    showGameConfig(id){
      return get("/game_configs/"+id)
      .then((response) => response.json());
    },
    updateGameConfigRequest(id, body ={}) {
      return patch("/game_configs/"+id, body)
      .then((response) => {

        return response
      })
    },
    updateConfigField(id, body) {
      return post("/game_configs/"+id+"/config-field", body)
      .then((response) => {

        return response
      })
    }
  },
}
