<template>
  <Header></Header>
  <div class="container col-12 row mx-0 px-0">
  <router-view />
  </div>
  <Footer></Footer>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from './components/Footer.vue';
import {get} from './utils/Request'
export default {
  components: {
    Header,
    Footer,
  },
  computed: {
     user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      if (this.$store.state.user == "null" || this.$store.state.user == undefined || this.$store.state.user == {}){
        return false
      }
      if (Object.prototype.hasOwnProperty.call(this.$store.state.user, 'error')){
        return false;
      }
      return true;
    },
  },
  beforeMount(){
    if (this.isAuthenticated){
      if(Object.prototype.hasOwnProperty.call(this.user, "error")){
         this.$store.commit("cleanUser")
      } else {
        get('/users/'+this.user.attributes.id)
        .then((response) => response.json())
        .then(data =>{
            if (data == undefined || data.error) {
                this.$store.commit("cleanUser")
            } else {
              let oldUserInfo = this.user
              oldUserInfo.attributes = data
              this.$store.commit("setUser", oldUserInfo)
            }
          
          
        })
        .catch(err =>{
          this.$store.commit("cleanUser")
          console.log(err)
        })
      }
    }
  }
};
</script>
<style>
.container{
  min-height: 80vh;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
