<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        Tabela de Balanceamento de Armas e Ferramentas
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>
    <div
      class="col-12 align-content-start mt-0 mb-0 py-0 mx-0 px-0 row justify-content-center align-content-start shopborder"
      style="max-height: 100px"
    >
      <h4 class="col-12 text-center mb-4">Filtrar por qualidade:</h4>
      <div class="row col-12 mx-0 px-0 justify-content-center align-content-start">
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_quality('all')"
        >
          Todas
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_quality(1)"
        >
          Péssimo
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_quality(2)"
        >
          Mal-feito
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_quality(3)"
        >
          Bom
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_quality(4)"
        >
          Excepcional
        </div>
         <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_quality(5)"
        >
          Sobrenatural
        </div>
      </div>
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <input class="col-lg-4 col-7 mx-2 px-2 mt-3 mb-0 py-2 simpleborder-1" type="text" v-model="search_result" placeholder="Procurar por nome..." />

      <div v-if="isAdmin() && is_loading_weapon_attributes" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>
      <div  v-if="isAdmin() && !is_loading_weapon_attributes" class="staff-team row col-12 mx-0 px-0 justify-content-around">
        <template v-for="weapon_attribute in weapon_attributes" :key="weapon_attribute.id">
          <div
            v-if="onSearchResult(weapon_attribute.weapon_type)"
           :class="'staticcard '+getQualityCSSClass(weapon_attribute.quality)+' col-sm-11 col-md-6 col-lg-5 my-2 mx-2 px-0 row align-content-start py-5 justify-content-center'"
          >
          <WeaponAttributeCard :weapon="weapon_attribute" @reload-weapon_attributes="loadWeaponAttributes()"></WeaponAttributeCard>
          </div>
        </template>


        <div v-if="weapon_attributes.length == 0" class="col-7">
          <h4>Sem armas registradas no momento...</h4>
        </div>
      </div>
      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>
    </div>
  </div>
</template>

<script>
import WeaponAttribute from "./../../mixins/WeaponAttributeMixin";
import MagicAttribute from '../../mixins/MagicAttributeMixin'

import { directive } from "vue-tippy";
import WeaponAttributeCard from "@/components/weapon_attributes/WeaponAttributeCard";

export default {
  name: "WeaponAttributeBalanceTable",
  props: {},
  components: {
    WeaponAttributeCard,
  },
  data() {
    return {
      weapon_attribute_list: [],
      editErrors: [],
      editMessages: [],
      is_loading_weapon_attributes: true,
      quality_selected: "all",
      magic_attributes_list: [],
      is_loading_magic_attributes_list: true,
      old_quality_selected: "all",
      search_result: undefined
    };
  },
  computed: {
    weapon_attributes() {
      return this.weapon_attribute_list;
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isArmor(attr){
      if (attr.weapon_type.includes("chestplate")
      || attr.weapon_type.includes("leggings")
      || attr.weapon_type.includes("boots")
      || attr.weapon_type.includes("helmet")) {
        return true;
      }
      return false;
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    change_quality(quality_code) {
      this.quality_selected = quality_code;
      this.weapon_attribute_list = [];

      this.loadWeaponAttributes();
    },
    loadWeaponAttributes() {
      this.old_quality_selected = this.quality_selected;
      this.is_loading_weapon_attributes = true;
      this.getWeaponAttributes(this.quality_selected).then((weapon_attributes) => {
        this.weapon_attribute_list = this.weapon_attributes.concat(weapon_attributes);
        this.is_loading_weapon_attributes = false;
      });
    },
    getQualityCSSClass(quality){
      if (quality == 1) return 'dark_red';
      if (quality == 2) return 'red';
      if (quality == 3) return 'yellow';
      if (quality == 4) return 'green';
      if (quality == 5) return 'purple';

      return 'dark_red';
    },
    onSearchResult(weapon_type){
      if (this.search_result && this.search_result !== ""){
        return weapon_type.toLowerCase().includes(this.search_result.toLowerCase())
      }
      return true;
    },
    loadMagicAttributeList(){
      this.is_loading_magic_attributes_list = true;
      this.getNonSystemMagicAttributes().then((list) => {
        console.log(list)
        this.magic_attributes_list = list
        this.is_loading_magic_attributes_list = false
      });
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [WeaponAttribute, MagicAttribute],
  beforeMount() {
    this.loadMagicAttributeList();
    this.loadWeaponAttributes();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin,
.programmer,
.lorer,
.builder {
  cursor: pointer;
}
.red { background-color: rgba(255, 40, 40, 0.2); }
.dark_red { background-color: rgba(121, 1, 1, 0.563); }
.yellow { background-color: rgba(233, 183, 17, 0.15); }
.green { background-color: rgba(0, 128, 0, 0.2); }
.purple { background-color: rgba(114, 85, 160, 0.4); }
</style>
