import {get, put} from '../utils/Request'
export default {
  methods: {
    getRefinements(){
      return get("/refinements")
      .then((response) => response.json());
    },
    showRefinement(id){
      return get("/refinements/"+id)
      .then((response) => response.json());
    },
    updateRefinementRequest(id, body ={}) {
      return put("/refinements/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}
