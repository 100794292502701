<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <div class="col-12 text-center mx-0" style="font-size: 14px">
      <p v-if="!updating_name">{{ item_category.name }}</p>
      <input v-if="updating_name" type="text" :name="item_category.id+'_name'" :id="item_category.id+'_id'" v-model="item_category.name">

      <span v-if="!updating_name" v-on:click="toggleUpdating()" class="mx-2">
        <i class="fa-solid fa-pen-to-square"></i>
      </span>
      <span  class="mx-2" v-on:click="toggleUpdating()" v-if="updating_name">
        <i class="fa-solid fa-check"></i>
      </span>
    </div>

    <hr class="col-12 mx-0 px-0" />

    <p class="col-12 text-center mx-0"  v-if="item_category.items.length != 0"> Item Base: {{ itemName }} </p>
    <div v-if="item_category.items.length != 0" >
      <select class="col-12 mx-0 px-0 my-0 py-0 text-center" style="width: 500px" :id="item_category.id+'item'" name="item" v-model="item_category.item_id" ref="select multiple">

      </select>
    </div>

    <div class="col-12 text-center mx-0 px-0 my-3" v-if="item_category.items.length == 0">Esta categoria ainda não possui itens</div>

    <p class="col-12 text-center mx-0">Grau de Dificuldade do Item Base: {{ difficultyBase }}</p>

    <div class="col-12 text-center mx-0 px-0">Preço Base de Venda: <input class="col-3 mx-0 px-0" v-model="item_category.sell_price"> esm</div>
    <div class="col-12 text-center mx-0 px-0">Preço Base de Compra: <input class="col-3 mx-0 px-0" v-model="item_category.buy_price"> esm</div>
    <br class="col-12 mx-0 px-0">

    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateItemCategory()">Salvar</button>
    </div>

    <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>
      </transition-group>

    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import ItemCategory from '../../mixins/ItemCategoryMixin'
import $ from 'jquery'
import selectize from '@selectize/selectize'

export default {
  name: "ItemCategoryCard",
  data() {
    return {
      item_category: {},
      updateErrors: [],
      updateMessages: [],
      itemCategoryAttributes: this.item_category_p,
      name: undefined,
      updating_name: false,
    };
  },
  props: {
    item_category_p: Object,
  },
  mixins: [ItemCategory],
  beforeMount(){
    selectize
    this.item_category = this.item_category_p
    console.log("Item Category")
    console.log(this.item_category)
    this.name = this.item_category.name
  },
  computed:
  {
    difficultyBase(){

      return this.item_category.item.difficulty;
    },
    itemName(){

      return this.item_category.item.name;
    }
  },
  methods: {
    toggleUpdating(){
      this.updating_name = !this.updating_name
    },
    destroyItemCategory(){
      this.destroyItemCategoryRequest(this.item_category.id)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Categoria de Item removida com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
            this.$parent.loadItemCategories();
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar remover esta Categoria de Item.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    updateItemCategory(){
      this.updateItemGategoryRequest(this.item_category.id, this.item_category)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Categoria de Item atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar esta Categoria de Item.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    getItem(id){
      let item = null

      for (let i = 0; i < this.item_category.items.length; i++){
        let pitem = this.item_category.items[i]

        if (pitem && pitem.id == id){
          item = pitem;
        }
      }
      return item;
    },
    changeItemCategoryItem(value){
      this.item_category.item_id = value
      let item = this.getItem(value)
      if (item != null){
        this.item_category.item = item
      }

    },
  },
  mounted(){
    let items = this.item_category.items
    let changeItemCategoryItem = this.changeItemCategoryItem
    let mItem = this.item_category.item_id
    let mId = this.item_category.id
    $(document).ready(function () {
      $("#"+mId+"item").selectize({
          options: items,
          sortField: 'text',
          labelField: 'name',
          valueField: 'id',
          searchField: ['name'],
          items: [mItem],
          onChange(value){
            changeItemCategoryItem(value)
          }
      });
    });
  }
};
</script>
