import {get, destroy, patch, post} from './../utils/Request'
export default {
  methods: {
    getTasks(){
      return get("/tasks")
      .then((response) => response.json());
    },
    getTaskPage(page, category){
      return get("/tasks/page?page="+page+"&category="+category)
      .then((response) => response.json());
    },
    showTask(id){
      return get("/tasks/"+id)
      .then((response) => response.json());
    },
    getTaskProgress(card){
      if (card != undefined){
        if (card.badges != undefined){
          let checkItems = card.badges.checkItems
          let checked = card.badges.checkItemsChecked
          let progress= (checked/checkItems)*100
          return parseInt(progress)+"%";
        }
      }
      return 'Sem card no Trello'
    },
    askToVolunteer(task_id, user_id){
      return post("/task_volunteers", {
        task_id,
        user_id
      })
      .then((response) => response.json());
    },
    denyTaskVolunteer(task_id, user_id){
      return post("/task_volunteers/deny", {
        task_id,
        user_id
      })
      .then((response) => response.json());
    },
    acceptTaskVolunteer(task_id, user_id){
      return post("/task_volunteers/approve", {
        task_id,
        user_id
      })
      .then((response) => response.json());
    },
    deleteRequest(id){
      return destroy("/tasks/"+id)
      .then((response) => {

        return response
      })
    },
    updateRequest(id, body ={}) {
      return patch("/tasks/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}