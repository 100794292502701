<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <a class="col-12 mx-0 py-0 my-0 h-0 text-right mt-0 mb-0" href="/mercadores"
            ><i class="fas fa-times fa-2x backbtn"></i
    ></a>
    <div class="col-12 text-center mx-0" style="font-size: 14px">{{merchant.name}}</div>
    <div class="col-12 text-center mx-0" style="font-size: 12px">ID: {{merchant.id}}</div>

    <hr class="col-12 mx-0 px-0" />
    <div class="col-12 mx-0 px-0 my-1 py-0" v-for="merchant_item in merchant.merchant_items" :key="merchant_item.id">
        <MerchantItemCard :merchant_item_p="merchant_item"></MerchantItemCard>
    </div>
    <button v-on:click="createMerchantItem()" class="col-7 mx-0 px-0 my-3 py-0 minebutton">
        Adicionar Item a este Mercador
    </button>
    <hr class="col-12 mt-0 mx-0 px-0"/>

    <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>
      </transition-group>

    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import Merchant from '../../mixins/MerchantMixin'
import MerchantItem from '../../mixins/MerchantItemMixin'

import MerchantItemCard from "@/components/merchants/MerchantItemCard";
export default {
  name: "MerchantCard",
  data() {
    return {
      merchant: {},
      updateErrors: [],
      updateMessages: [],
      merchantAttributes: this.merchant,
    };
  },
  props: {
    merchant_p: Object,
  },
  components:{
    MerchantItemCard,
  },
  mixins: [Merchant,MerchantItem],
  beforeMount(){
    this.merchant = this.merchant_p
  },
  computed:
  {
  },
  methods: {
    createMerchantItem(){
        this.createMerchantItemRequest({merchant_id: this.merchant.id, item_id: 1, buy_price_multiplier: 1.0, sell_price_multiplier: 1.0})
        .then(data =>{
            if (data.status){
            this.updateMessages.push("Item de Mercador criado com sucesso!")
            this.reloadMerchant();

            setTimeout(() => {
                this.updateMessages.splice(0, 1);
            }, 5000);
            } else {
            this.updateErrors.push("Houve um erro ao tentar criar este Item de Mercador.")
            setTimeout(() => {
                this.updateErrors.splice(0, 1);
            }, 5000);
            }
        })
    },
    updateMerchant(){
      this.updateMerchantRequest(this.merchant.id, this.merchant)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Mercador atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar este Mercador.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    reloadMerchant() {
      this.is_loading_merchant = true;
      this.showMerchant(this.merchant.id).then((merch) => {
        this.merchant = merch
        this.is_loading_merchant = false
      });
    },
  },
};
</script>
