import {get, put} from './../utils/Request'
export default {
  methods: {
    getMagicAttributes(from_filter){
      return get("/magic_attributes/from-filter?filter="+from_filter)
      .then((response) => response.json());
    },
    getNonSystemMagicAttributes(){
      return get("/magic_attributes/from-filter?filter=non_system")
      .then((response) => response.json());
    },
    showMagicAttribute(id){
      return get("/magic_attributes/"+id)
      .then((response) => response.json());
    },
    updateRequest(id, body ={}) {
      return put("/magic_attributes/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}
