<template>
  <header id="header" class="col-12 row mx-0 px-0 my-0 py-0 align-content-start justify-content-center {{sizeClass}}">
    <login-modal class="col-12" v-if="showLoginModal" @closeLoginModal="toggleLoginModal()"></login-modal>
    <div class="header col-12 mx-0 px-5">

      <div class="logo">
        <a href="/"
          ><img src="/img/smalllogo.png" alt="MyServer logo"
        /></a>
      </div>

      <ul class="nav">
        <li><a href="/" class="woodbtn">Inicio</a></li>
        <li><a href="https://discord.gg/VNKvkuENuS" class="woodbtn2">Discord</a></li>
        <li><a href="/loja" class="woodbtn">Loja</a></li>
        <li><a href="https://knights-of-minecraft.fandom.com/pt-br/wiki/Knights_of_Minecraft_Wiki" class="woodbtn">Guia</a></li>
        <li><a href="/staff" class="woodbtn">Staff</a></li>
        <li v-if="!isAuthenticated" v-on:click="toggleLoginModal()"><a id='myBtn' class='woodbtn'>Login</a></li>
        <li v-else v-on:click="toggleLoginModal()"><a id='myBtn' class='woodbtn'>Perfil</a></li>
      </ul>

    </div>

    <div v-if="home" class="col-12 mx-0 px-0 mb-5">
      <h1 style="color: lightyellow" class="fadeIn">
        {{ greeting }}
      </h1>

      <div class="playercount">

        <p style="color: lightyellow">
          <b><span style="color: lightred">IP: kom.nevercraft.com.br</span></b>
        </p>

        <hr />
      </div>

    </div>
  </header>
</template>

<script>
import LoginModal from '@/components/login/LoginModal.vue'
export default {
  name: "Header",
  data(){
    return{
      showLoginModal: false,
      actualView: this.$router.currentRoute,
    }
  },
  props: {
  },
  components: {
    LoginModal,
  },
  computed: {
    home(){
      return this.actualView.name == "Home"
    },
    sizeClass(){
      if (this.home){
       return "home_height"
      } else return ""
    },
    user(){
      return JSON.parse(this.$store.state.user)
    },
    isAuthenticated(){
      return (this.$store.state.user)
    },
    greeting(){
      if (this.isAuthenticated){
        if (this.user.attributes.nickname){
          return "Bem-vindo, "+this.user.attributes.nickname;
        }
      }
      return "Bem-vindo, aventureiro"
    },
  },
  methods: {
    toggleLoginModal(){
      this.showLoginModal = !this.showLoginModal
    },
  },
};
</script>
<style scoped>
.home_height {
  min-height: 100vh;
}
</style>
