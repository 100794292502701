import {get, patch, destroy} from './../utils/Request'
export default {
  methods: {
    getMerchants(){
      return get("/merchants")
      .then((response) => response.json());
    },

    showMerchant(id){
      return get("/merchants/"+id)
      .then((response) => response.json());
    },
    updateMerchantRequest(id, body ={}) {
      return patch("/merchants/"+id, body)
      .then((response) => {

        return response
      })
    },
    destroyMerchantRequest(id, body ={}) {
      return destroy("/merchants/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}
