import {get, post, patch, destroy} from '../utils/Request'
export default {
  methods: {
    getHierarchyPages(){
      return post("/wiki_pages/hierarchy_pages")
      .then((response) => response.json());
    },
    showPage(id){
      return get("/wiki_pages/"+id)
      .then((response) => response.json());
    },
    destroyPage(id){
      return destroy("/wiki_pages/"+id)
      .then((response) => response);
    },
    createPage(body ={}) {
      return post("/wiki_pages/", body)
      .then((response) => {

        return  response.json()
      })
    },
    updatePage(body ={}) {
      return patch("/wiki_pages/", body)
      .then((response) => {

        return  response.json()
      })
    }
  },
}