<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-5 justify-content-center mx-0">
      <h2
        class="col-7 mx-0 px-0 mt-3 pb-0 text-center"
        style="background-image: url('../img/grass.png'); background-size: 30px; background-repeat: repeat-x; background-position: bottom; margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801); width: 50%; margin-left: 25%"
      >
        Guia
      </h2>
      <hr style="width: 52.5%; margin: 0% 23.75%; " />
      <h4
        id="pagetitle"
        style="margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801); width: 50%; margin-left: 25%"
      >
        Aprenda como funciona as mecânicas de jogo:
      </h4>
      <br />
      <div class="row justify-content-center">
        <div class="wikiMainInfo">
          <a href="/wiki"
            ><i class="fas fa-times fa-2x backbtn" style="float: right"></i
          ></a>
          <img
            style="filter: contrast(225%); width: 30%; margin-left: 35%"
            src="/img/fort.png"
            alt=""
          /><br />
          <div style="clear: left;"></div>
          <h2 style="text-align: center;">Sistema de Guildas:</h2>
          <hr style="width: 65%" />
          <br />
          <h3>Como criar:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          <ul>
            <li>
              Para formar uma <span class="gcolor">Guilda</span> os jogadores
              podem visitar o Oficial de Guildas em Rhodes ou Sorathes, na Zona
              das Guildas ou digitar o comando “/f criar Nome” no chat do jogo.
            </li>
            <li>
              Já criada, o Lider poderá escolher um Estandarte para representar
              oficialmente a sua <span class="gcolor">Guilda</span>.
            </li>
            <li>
              Custa 30 esmeraldas para criar uma
              <span class="gcolor">Guilda</span>.
            </li>
          </ul>
          <h3>Conquista de Terrenos:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          <ul>
            <li>
              Para conquistar um terreno o jogador poderá colocar seu estandarte
              em uma Terra Sem Dono ou digitar o comando “/f conquistar”. O
              preço de um terreno varia, quanto mais terrenos sua
              <span class="gcolor">Guilda</span> tem, mais caro será o próximo
              terreno; o primeiro é gratuito.
            </li>
            <li>
              Só é possível conquistar um terreno se sua
              <span class="gcolor">Guilda</span> tem poder suficiente (O Poder
              será explicado posteriormente!).
            </li>
            <li>
              Normalmente só é possível conquistar terrenos colados a seu
              território.
            </li>
            <li>
              Normalmente só é possível conquistar terrenos pelo menos a 3
              terrenos de distância do território de outra
              <span class="gcolor">Guilda</span>.
            </li>
          </ul>
          <h3>Aliança e Inimizade:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          <ul>
            <li>
              Para formar <span class="acolor">Alianças</span> o jogador pode
              visitar o Oficial de Guildas e entrar na aba de
              <span class="acolor">Alianças</span>. É possível convidar outras
              <span class="gcolor">Guildas</span> para formar
              <span class="acolor">Aliança</span> e também é possível pedir para
              se juntar à uma <span class="acolor">Aliança</span> já existente.
            </li>
            <li>
              Formar <span class="acolor">Aliança</span> significa formar uma
              confederação. Você une suas forças com outras
              <span class="gcolor">Guildas</span> e assim compartilham
              inimizades e <span class="wcolor">Guerras</span>!
            </li>
            <li>
              Líderes de <span class="gcolor">Guilda</span> podem declarar
              inimizade com outras <span class="gcolor">Guildas</span> a
              qualquer momento usando o comando “/f rival NomeDoAlvo” ou “/f
              inimigo NomeDoAlvo”. Ao fazerem isso, se forem parte de uma
              <span class="acolor">Aliança</span>, a inimizade é declarada entre
              todos os membros de ambas as <span class="acolor">Alianças</span>.
            </li>
          </ul>
          <h3>Poder e Ponto de Pilhagem:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          <h4>Poder:</h4>
          <ul>
            <li>
              Pontos de Poder serve para manter seus terrenos! Cada 1 terreno
              precisa de 1 Poder para ser sustentado. Uma
              <span class="gcolor">Guilda</span> que fica com menos Poder que
              terrenos perde terrenos facilmente.
            </li>
            <li>
              Todo jogador possui inicialmente 4 pontos de Poder e pode aumentar
              este valor consumindo uma Pedra do Poder, item semi-raro obtido de
              forma casual no Knights of Minecraft.
            </li>
            <li>
              Os Pontos de Poder de uma <span class="gcolor">Guilda</span> são
              os Pontos de Poder de cada jogador que faz parte dela.
            </li>
            <li>
              O poder de uma <span class="gcolor">Guilda</span> e, logo, de seus
              membros, é drenado em batalhas na
              <span class="wcolor">Guerra</span>.
            </li>
          </ul>
          <h4>Ponto de Pilhagem (PPs):</h4>
          <ul>
            <li>
              Toda vez que algum membro de
              <span class="gcolor">Guilda</span> mata outro membro de outra
              <span class="gcolor">Guilda</span>, a
              <span class="gcolor">Guilda</span> ganha Pontos de Pilhagem sobre
              a outra.
            </li>
            <li>
              Cada jogador em uma <span class="gcolor">Guilda</span> aumenta a
              força dela, fazendo com que tenham que ter mais PPs contra ela
              para torná-la vulnerável (Cada jogador soma 10 pontos de defesa
              contra Ponto de Pilhagem).
            </li>
            <li>
              Quando uma <span class="gcolor">Guilda</span> junta PPs suficiente
              contra outra <span class="gcolor">Guilda</span> (75% da Defesa da
              <span class="gcolor">Guilda</span>), ela pode saqueá-la (abrir
              baús).
            </li>
            <li>Líderes e co-líderes matando e/ou morrendo somam mais PPs.</li>
            <li>
              Uma <span class="gcolor">Guilda</span> precisa de 20% de PPs
              contra outra para declarar <span class="wcolor">Guerra</span>,
              sejam elas parte de <span class="acolor">Alianças</span> ou não!
            </li>
            <li>O PPs reseta a cada Restart do servidor.</li>
          </ul>
          <h3><span class="wcolor">Guerra</span>:</h3>
          <hr style="margin-left: 0%; width: 30%" />
          <ul>
            <li>
              A <span class="wcolor">Guerra</span> é um momento de conquista.
              Uma <span class="wcolor">Guerra</span> só deve ser declarada
              quando você está disposto a lutar até o fim pelo terreno de seu
              inimigo.
            </li>
            <li>
              Quando uma <span class="wcolor">Guerra</span> é iniciada entre
              duas <span class="gcolor">Guildas</span>, seus Aliados são
              convocados e TODOS entram em <span class="wcolor">Guerra</span>.
              Seja cauteloso e não tome decisões sozinho, você também os
              comprometerá.
            </li>
            <li>
              Só é possível declarar <span class="wcolor">Guerra</span> entre
              times de tamanho semelhante. Independente se a
              <span class="wcolor">Guerra</span> é formada entre
              <span class="acolor">Alianças</span> e
              <span class="gcolor">Guildas</span> de tamanhos distintos, no
              final, a soma de membro dos dois times deve cumprir alguns
              requisitos para evitar desequilíbrios!
            </li>
            <li>
              <span class="gcolor">Guildas</span> e
              <span class="acolor">Alianças</span> com menos de 8 jogadores são
              consideradas pequenas e podem todas lutar entre si. Acima disso,
              há um limite de no máximo 40% de diferença.
            </li>
            <li>
              Pode haver <span class="wcolor">Guerra</span> de
              <span class="acolor">Alianças</span> vs
              <span class="gcolor">Guilda</span>, se o número de jogador dos
              dois lados for semalhante.
            </li>
          </ul>
          <h4>Batalha:</h4>
          <ul>
            <li>
              Em <span class="wcolor">Guerra</span>, uma
              <span class="gcolor">Guilda</span> pode colocar seu Estandarte no
              território de outra <span class="gcolor">Guilda</span> e começar
              uma Conquista.
            </li>
            <li>
              Ao colocar o Estandarte e iniciar a Conquista, surge um Cristal
              com a vida do Ponto de Controle e uma contagem regressiva que
              aparece na tela. A <span class="gcolor">Guilda</span> em defesa
              deve lutar para destruir o Ponto de Controle do time adversário
              dentro do tempo da contagem. Se o tempo acabar, a contagem reseta
              e é drenado 1 de Poder da
              <span class="gcolor">Guilda</span> defensora. No momento que o
              Poder estiver abaixo da quantidade de terrenos, o terreno onde
              está o Estandarte é capturado e passa a pertencer a
              <span class="gcolor">Guilda</span> atacante. A
              <span class="gcolor">Guilda</span> defensora pode reconquistar o
              terreno da mesma forma, com Estandarte.
            </li>
            <li>
              O tempo de duração do turno do Ponto de Controle e a vida do
              Cristal são baseados em quanto PPs a
              <span class="gcolor">Guilda</span> tem contra seu alvo no momento
              em que põe o Estandarte no território inimigo.
            </li>
            <li>
              Uma vez conquistado um terreno inimigo, ele será seu enquanto você
              conseguir mantê-lo. Lembre-se, é necessário ter Poder para manter
              terrenos, mas, diferentemente do normal, em
              <span class="wcolor">Guerra</span> você consegue conquistar um
              terreno mesmo sem ter Poder (lembrando que nessa situação estará
              vulnerável se Poder estiver abaixo do número de terrenos). É
              somente neste momento de <span class="wcolor">Guerra</span> que
              você pode adquirir terrenos não conectados com seu território.
            </li>
            <li>
              Cuidado ao ter mais de um território, você se torna mais
              suscetível a ataques e pode ter problemas em defender.
            </li>
            <li>
              Você sempre pode se render em uma
              <span class="wcolor">Guerra</span>, mas isso fará com que você
              abandone todos os seus terrenos. Eles continuarão lá com todas as
              suas coisas mas não estará sob seu domínio e assim estará livre
              para qualquer um conquistá-lo.
            </li>
            <li>
              Há a opção de Bandeira Branca entre dois lados de uma
              <span class="wcolor">Guerra</span>, onde ambas decidem parar a
              <span class="wcolor">Guerra</span> sem abrir mão de seus
              territórios.
            </li>
            <li>
              Se você é de uma <span class="acolor">Aliança</span> e se rende
              numa <span class="wcolor">Guerra</span>, você abandona seus
              terrenos e abandona também a
              <span class="acolor">Aliança</span> da qual faz parte, deixando
              seus aliados na mão e ainda em <span class="wcolor">Guerra</span>.
              Se você é de uma <span class="acolor">Aliança</span> e foi
              abandonado por algum aliado, você sempre pode tentar negociar
              Bandeira Branca.
            </li>
          </ul>
          <h2 style="text-align: center">Video:</h2>
          <hr style="width: 90%; margin-bottom: 35px;" />

          <iframe
            style="margin-left: 15%"
            class="wikiMainInfo"
            width="70%"
            height="415"
            src="https://www.youtube.com/embed/S_Zpthz5yI8"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div style="clear: right; clear: left;"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GuildWiki",
  data() {
    return {};
  },
};
</script>
