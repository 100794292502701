<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h4 class="col-12 text-center mx-0">{{ xp.xp_type }}</h4>
    <hr class="col-12 mx-0 px-0" />
    Classe: {{ xp.main_class }}
    <hr class="col-12 mx-0 px-0" />

    <h5 class="col-12 mx-0 px-0 text-center">Valores</h5>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Primário</th>
              <th scope="col">Secundário</th>
              <th scope="col">Sem Classe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="xp_primary"
                    
                  /><span class="col-2 text-left"> XP</span>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="xp_secondary"
                  /><span class="col-2 text-left"> XP</span>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="xp_no_class"
                  /><span class="col-2 text-left"> XP </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateXp()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>         
        </transition-group>
        
      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>         
        </transition-group>
        
      </div>
  </div>
</template>
<script>
import Xp from '../../mixins/XpMixin'
export default {
  name: "XpCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      xpAttributes: this.xp,
      xp_primary: undefined,
      xp_secondary: undefined,
      xp_no_class: undefined,
    };
  },
  props: {
    xp: Object,
  },
  mixins: [Xp],
  beforeMount(){
    this.xp_no_class = this.xp.no_class_xp
    this.xp_primary = this.xp.primary_xp
    this.xp_secondary = this.xp.secondary_xp
  },
  methods: {
   
   
    updateXp(){
      this.updateRequest(this.xp.id, {primary_xp: this.xp_primary, secondary_xp: this.xp_secondary, no_class_xp: this.xp_no_class})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("XP atualizada com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar os valores dessa XP.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
};
</script>
