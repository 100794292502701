<template>
  <div
    class="modal"
    id="confirmModal"
    :style="{ top: scrollPosition + 'px !important' }"
  >
    <div class="confirmModalContent col-lg-7 col-10">
      <span
        v-on:click="$emit('close')"
        class="confirmclose"
        style="float: right"
        >&times;</span
      >

      <div style="clear: right"></div>
      <div class="col-12 mx-0 px-0">
        <h3
          style="margin: 0px auto 0px 0%; position: relative; display: block; width: 100%; text-align: center;"
        >
          Finalizando a compra
        </h3>
        <hr />
      </div>
      <div class="row justify-content-center mx-0 px-0">
        <div class="col-lg-5 col-12 my-lg-5 row justify-content-center align-content-center">
          <img
            :src="'img/products/' + product.image"
            alt=""
            style="width: 50%"
          />
        </div>
        <div
          class="col-lg-7 col-12 mt-3 mx-0 px-0"
          style="text-align: left; margin: 36px auto 16px auto;"
        >
          <span class="text-center col-12 mx-0 px-0 justify-content-center row" v-html="filterColor('h4', product.name)"></span>
          <hr />
          <span class="mx-0 px-0 my-0" v-html="splitLore(product.lore)"></span>
          <hr />
          <h5 style="margin: 0% 0% 5% 0%; line-height: 100%;">
            Preço: {{ finalPrice }} Cash
          </h5>
          <h5
            v-if="isAuthenticated && wallet.amount >= finalPrice"
            style="margin: 0% 0% 5% 0%; line-height: 100%;"
          >
            Saldo: {{ wallet.amount }} Cash
          </h5>

          <button
            v-if="isAuthenticated && wallet.amount >= finalPrice"
            class="minebutton"
            style="width: 100%;"
            v-on:click="$parent.buyItem(product)"
          >
            Comprar
          </button>
          <div
            v-else-if="isAuthenticated && wallet.amount < finalPrice"
          >
            <div class="alertBox simpleborder-1" style="text-align: center">
              Saldo insuficiente!
            </div>
            <br />
            <button class="col-12 buybutton" v-on:click="$emit('go-to-cash-section')">
              <i class="fas fa-shopping-cart"></i> Comprar Cash
            </button>
          </div>
          <div v-else>
            <div class="alertBox simpleborder-1" style="text-align: center">
              Você não esta logado para efetuar a compra
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>
<script>
import Product from "./../../mixins/ProductMixin";
import Wallet from "./../../mixins/WalletMixin";

export default {
  name: "ConfirmModal",
  data() {
    return {
      wallet: {},
      buyErrors: [],
      scrollPosition: null,
      maxHeight: window.innerHeight,
      hasDiscount: false,
    };
  },
  props: {
    product: Object,
  },
  computed: {
    isAuthenticated(){
      return (this.$store.state.user)
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
     finalPrice(){
      if (this.hasDiscount){
        return (this.product.price - (0.30*this.product.price));
      }
      return this.product.price;
    },
  },

  mixins: [Product, Wallet],
  methods: {
   
    checkDiscount(){
      this.hasShopDiscount(this.user.attributes.id)
       .then((data) => {
          this.hasDiscount = data.has_shop_discount
        })
        .catch((err) => {
          console.log(err);
        });
      
    },
    updateScroll() {
      if (window.scrollY < this.maxHeight) this.scrollPosition = window.scrollY;
    },
    
    updateWallet() {
      if (this.isAuthenticated) {
        this.getUserWallet()
          .then((response) => response.json())
          .then((data) => {
            this.wallet = data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    tryToBuyItem() {
      if (this.wallet.amount < this.product.price) {
        this.buyErrors.push(
          "Você não possuí Cash para comprar este item. Compre cash direto na Loja Mágica :)"
        );
      }
      this.buy(this.product).then(() => {
        this.$route.go("/inventario");
      });
    },
  },
  beforeMount() {
    this.checkDiscount()
    this.updateWallet();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
};
</script>
