<template>
  <div>
    <div class="main-content">
      <h2>
        <u>Knights of Minecraft</u> <br />
        <div id="shield">🛡</div>
      </h2>
      <p class="about-text px-4 py-0">
        Knights of Minecraft é um servidor Épico de RPG, Hardcore e Factions.
        <br />Se prepare para embarcar em uma aventura imersiva e desafiante,
        junte seus amigos ou faça novos companheiros e forme poderosas Guilds
        para prosperarem e conquistarem o continente de Nova Aurora! E claro,
        isso enquanto você desenvolve seu personagem para se tornar um
        Aventureiro Épico, pronto para enfrentar os desafios desta natureza
        hostil e acabar com aqueles que ousarem cruzar o seu caminho.
        <br /><br />Venha experienciar esta aventura conosco!
      </p>
    </div>

    <div class="gradient">
      <div class="flex-container">
        <div class="split">
          <img
            class="img"
            src="img/learn.png"
            alt="Imagem do Aprenda"
            width="190px"
            height="215px"
          />
          <a href="https://knights-of-minecraft.fandom.com/pt-br/wiki/Knights_of_Minecraft_Wiki" style="text-decoration: none">
            <div class="woodbtn2 imgbtn">Wiki</div>
          </a>
          <hr style="width: 50%" />
          <p>
            Aprenda todas as mecânicas principais do Knights of Minecraft, desde
            as Classes, até as Guilds, Econômia, Eventos, etc.
          </p>
        </div>
        <div class="split">
          <img
            class="img"
            src="img/store.png"
            alt="Image of Store icon"
            width="190px"
            height="215px"
          />
          <a href="/loja" style="text-decoration: none">
            <div class="woodbtn2 imgbtn">Loja</div>
          </a>
          <hr style="width: 50%" />
          <p>
            Visite a Loja Mágica para comprar VIPs, Pets, Montarias e Itens
            Benéficos
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
  },
};
</script>
<style scoped>
.main-content{
  min-height: 30vh;
}
.about-text {
    font-size: 125%;
}
</style>
