<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h3 class="col-12 text-center mx-0">{{ quality.quality_type }}</h3>
    <hr class="col-12 mx-0 px-0" />
    <h4 class="col-12 mx-0 px-0 text-center">Peso no sorteio</h4>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Nome</th>
              <th scope="col">Péssimo (Nv Min - Max)</th>
              <th scope="col">Mal-feito (Nv Min - Max)</th>
              <th scope="col">Bom (Nv Min - Max)</th>
              <th scope="col">Excepcional (Nv Min - Max)</th>
              <th scope="col">Sobrenatural (Nv Min - Max)</th>
            </tr>
          </thead>
          <tbody>

            <tr>
              <td class="px-0 mx-0">Primário</td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_1_min_weight"
                    :id="quality.id+'_main_quality_1_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_1_max_weight"
                    :id="quality.id+'_main_quality_1_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_2_min_weight"
                    :id="quality.id+'_main_quality_2_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_2_max_weight"
                    :id="quality.id+'_main_quality_2_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_3_min_weight"
                    :id="quality.id+'_main_quality_3_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_3_max_weight"
                    :id="quality.id+'_main_quality_3_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_4_min_weight"
                    :id="quality.id+'_main_quality_4_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_4_max_weight"
                    :id="quality.id+'_main_quality_4_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_5_min_weight"
                    :id="quality.id+'_main_quality_5_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.main_quality_5_max_weight"
                    :id="quality.id+'_main_quality_5_max_weight'"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-0 mx-0">Secundário</td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_1_min_weight"
                    :id="quality.id+'_secondary_quality_1_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_1_max_weight"
                    :id="quality.id+'_secondary_quality_1_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_2_min_weight"
                    :id="quality.id+'_secondary_quality_2_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_2_max_weight"
                    :id="quality.id+'_secondary_quality_2_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_3_min_weight"
                    :id="quality.id+'_secondary_quality_3_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_3_max_weight"
                    :id="quality.id+'_secondary_quality_3_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_4_min_weight"
                    :id="quality.id+'_secondary_quality_4_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_4_max_weight"
                    :id="quality.id+'_secondary_quality_4_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_5_min_weight"
                    :id="quality.id+'_secondary_quality_5_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.secondary_quality_5_max_weight"
                    :id="quality.id+'_secondary_quality_5_max_weight'"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-0 mx-0">Sem classe</td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_1_min_weight"
                    :id="quality.id+'_non_class_quality_1_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_1_max_weight"
                    :id="quality.id+'_non_class_quality_1_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_2_min_weight"
                    :id="quality.id+'_non_class_quality_2_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_2_max_weight"
                    :id="quality.id+'_non_class_quality_2_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_3_min_weight"
                    :id="quality.id+'_non_class_quality_3_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_3_max_weight"
                    :id="quality.id+'_non_class_quality_3_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_4_min_weight"
                    :id="quality.id+'_non_class_quality_4_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_4_max_weight"
                    :id="quality.id+'_non_class_quality_4_max_weight'"
                  />
                </div>
              </td>
              <td class="px-0 mx-0">
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_5_min_weight"
                    :id="quality.id+'_non_class_quality_5_min_weight'"
                  /> |
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5 mx-0 px-0"
                    v-model="quality.non_class_quality_5_max_weight"
                    :id="quality.id+'_non_class_quality_5_max_weight'"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="col-12 mx-0 px-0"/>
        <div class="col-12 px-0 mx-0 my-3 py-0 row justify-content-center">
          <h4 class="col-12 mx-0 px-0 my-2">Teste de valores: </h4>
          <p class="col-12 mx-0 px-0 my-2" style="font-size: 10px">*Valores somente para observação</p>

          <p class="col-12 mx-0 px-0"> Jogador Nivel: {{player_level}} </p>
          <div class="col-12 mx-0 px-0 row justify-content-center">
            <input type="range" min="1" max="60" v-model="player_level" class="slider col-4 mx-5 my-0 px-5" id="levelRange">
          </div>

          <div class="col-4 mx-0 px-0 my-2 py-1 simpleborder-1">
            <h5>Classe Primária: </h5>
            Péssimo: {{calculateMainQuality(quality, 1, player_level)}}%
            <br>
            Mal-feito: {{calculateMainQuality(quality, 2, player_level)}}%
           <br>
            Bom: {{calculateMainQuality(quality, 3, player_level)}}%
           <br>
            Excepcional: {{calculateMainQuality(quality, 4, player_level)}}%
           <br>
            Sobrenatural: {{calculateMainQuality(quality, 5, player_level)}}%
          </div>
          <div class="col-4 mx-0 px-0 my-2 py-1 simpleborder-1">
            <h5>Classe Secundária: </h5>
            Péssimo: {{calculateSecondaryQuality(quality, 1, player_level)}}%
           <br>
            Mal-feito: {{calculateSecondaryQuality(quality, 2, player_level)}}%
           <br>
            Bom: {{calculateSecondaryQuality(quality, 3, player_level)}}%
           <br>
            Excepcional: {{calculateSecondaryQuality(quality, 4, player_level)}}%
           <br>
            Sobrenatural: {{calculateSecondaryQuality(quality, 5, player_level)}}%
          </div>
          <div class="col-4 mx-0 px-0 my-2 py-1 simpleborder-1">
            <h5>Sem Classe: </h5>
            Péssimo: {{calculateNonClassQuality(quality, 1, player_level)}}%
           <br>
            Mal-feito: {{calculateNonClassQuality(quality, 2, player_level)}}%
           <br>
            Bom: {{calculateNonClassQuality(quality, 3, player_level)}}%
           <br>
            Excepcional: {{calculateNonClassQuality(quality, 4, player_level)}}%
           <br>
            Sobrenatural: {{calculateNonClassQuality(quality, 5, player_level)}}%
          </div>

        </div>
        <hr class="col-12 mx-0 px-0"/>

      </div>
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateQuality()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>
        </transition-group>

      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>
        </transition-group>

      </div>
  </div>
</template>
<script>
import Quality from '../../mixins/QualityMixin'
export default {
  name: "QualityCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      quality: this.p_quality,
      player_level: 25
    };
  },
  props: {
    p_quality: Object
  },
  mixins: [Quality],
  methods: {
    updateQuality(){
      this.updateQualityRequest(this.quality.id, this.quality)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Qualidade atualizada com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar este valor.")

          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    calculateMainQuality(quality, q, level){
      let main_1 = this.getWeightOnLevel(quality.main_quality_1_min_weight, quality.main_quality_1_max_weight, level)
      let main_2 = this.getWeightOnLevel(quality.main_quality_2_min_weight, quality.main_quality_2_max_weight, level)
      let main_3 = this.getWeightOnLevel(quality.main_quality_3_min_weight, quality.main_quality_3_max_weight, level)
      let main_4 = this.getWeightOnLevel(quality.main_quality_4_min_weight, quality.main_quality_4_max_weight, level)
      let main_5 = this.getWeightOnLevel(quality.main_quality_5_min_weight, quality.main_quality_5_max_weight, level)
      let total = main_1 + main_2 + main_3 + main_4 + main_5
      if (q == 1){
        return parseFloat((main_1/total)*100).toFixed(1)
      } else if (q == 2){
        return parseFloat((main_2/total)*100).toFixed(1)
      } else if (q == 3){
        return parseFloat((main_3/total)*100).toFixed(1)
      } else if (q == 4){
        return parseFloat((main_4/total)*100).toFixed(1)
      } else if (q == 5){
        return parseFloat((main_5/total)*100).toFixed(1)
      }
    },
    calculateSecondaryQuality(quality, q, level){
      let secondary_1 = this.getWeightOnLevel(quality.secondary_quality_1_min_weight, quality.secondary_quality_1_max_weight, level)
      let secondary_2 = this.getWeightOnLevel(quality.secondary_quality_2_min_weight, quality.secondary_quality_2_max_weight, level)
      let secondary_3 = this.getWeightOnLevel(quality.secondary_quality_3_min_weight, quality.secondary_quality_3_max_weight, level)
      let secondary_4 = this.getWeightOnLevel(quality.secondary_quality_4_min_weight, quality.secondary_quality_4_max_weight, level)
      let secondary_5 = this.getWeightOnLevel(quality.secondary_quality_5_min_weight, quality.secondary_quality_5_max_weight, level)
      let total = secondary_1 + secondary_2 + secondary_3 + secondary_4 + secondary_5
      if (q == 1){
        return parseFloat((secondary_1/total)*100).toFixed(1)
      } else if (q == 2){
        return parseFloat((secondary_2/total)*100).toFixed(1)
      } else if (q == 3){
        return parseFloat((secondary_3/total)*100).toFixed(1)
      } else if (q == 4){
        return parseFloat((secondary_4/total)*100).toFixed(1)
      } else if (q == 5){
        return parseFloat((secondary_5/total)*100).toFixed(1)
      }
    },
    calculateNonClassQuality(quality, q, level){
      let non_class_1 = this.getWeightOnLevel(quality.non_class_quality_1_min_weight, quality.non_class_quality_1_max_weight, level)
      let non_class_2 = this.getWeightOnLevel(quality.non_class_quality_2_min_weight, quality.non_class_quality_2_max_weight, level)
      let non_class_3 = this.getWeightOnLevel(quality.non_class_quality_3_min_weight, quality.non_class_quality_3_max_weight, level)
      let non_class_4 = this.getWeightOnLevel(quality.non_class_quality_4_min_weight, quality.non_class_quality_4_max_weight, level)
      let non_class_5 = this.getWeightOnLevel(quality.non_class_quality_5_min_weight, quality.non_class_quality_5_max_weight, level)
      let total = non_class_1 + non_class_2 + non_class_3 + non_class_4 + non_class_5

      if (q == 1){
        return parseFloat((non_class_1/total)*100).toFixed(1)
      } else if (q == 2){
        return parseFloat((non_class_2/total)*100).toFixed(1)
      } else if (q == 3){
        return parseFloat((non_class_3/total)*100).toFixed(1)
      } else if (q == 4){
        return parseFloat((non_class_4/total)*100).toFixed(1)
      } else if (q == 5){
        return parseFloat((non_class_5/total)*100).toFixed(1)
      }
    },

    getWeightOnLevel(min, max, level){
      // min = 1 max = 10 diff = 9
      // level
      let diff = max - min;
      let lvlRate = level/60.0;

      return parseFloat(min+(lvlRate*diff));
    }
  }
};
</script>
