import {get, post, patch, destroy} from '../utils/Request'
export default {
  methods: {
    getPosts(){
      return get("/posts")
      .then((response) => response.json());
    },
    showPost(id){
      return get("/posts/"+id)
      .then((response) => response.json());
    },
    destroyPost(id){
      return destroy("/posts/"+id)
      .then((response) => response);
    },
    createPost(body ={}) {
      return post("/posts/", body)
      .then((response) => {

        return  response.json()
      })
    },
    getLastPost() {
      return get("/posts/last")
      .then((response) => {
        return  response.json()
      })
    },
    updatePost(body ={}) {
      return patch("/posts/", body)
      .then((response) => {

        return  response.json()
      })
    }
  },
}