<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h4 class="col-12 text-center mx-0">{{ skill.name }}</h4>
    <hr class="col-12 mx-0 px-0" />
    Classe: {{ getClassFromCode(skill.code) }}
    <hr class="col-12 mx-0 px-0" />

    <h5 class="col-12 mx-0 px-0 text-center">Atributos</h5>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Descrição</th>
              <th scope="col">Valor Nivel 1</th>
              <th scope="col">Valor Nivel 15</th>
              
              <th scope="col">Pts para Desbloquear</th>
            </tr>
          </thead>
          <tbody v-for="property in skillAttributes.properties" :key="property">
            <tr>
              <th scope="row">{{ property.perk_name }}</th>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="property.base_value"
                    :id="skill.code+'_'+property.perk_code+'_base_value'"
                  /><span class="col-2 text-left"> {{ property.unity }}</span>
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    :id="skill.code+'_'+property.perk_code+'_final_value'"
                    v-on:blur="updatePerkModifier(property)"
                    :value="
                      getFinalValue(property)
                    "
                  /><span class="col-2 text-left"> {{ property.unity }}</span>
                </div>
              </td>

              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="property.points_needed"
                    :id="skill.code+'_'+property.perk_code+'_points_needed'"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateSkill()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>         
        </transition-group>
        
      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>         
        </transition-group>
        
      </div>
  </div>
</template>
<script>
import _ from 'lodash'
import Skill from './../../mixins/SkillMixin'
export default {
  name: "SkillCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      skillAttributes: this.skill
    };
  },
  props: {
    skill: Object,
  },
  mixins: [Skill],
  beforeMount(){
    console.log(this.skillAttributes);
  },
  methods: {
    getClassFromCode(code) {
      if (code.includes("Lad")) return "Ladino";
      if (code.includes("Alq")) return "Alquimista";
      if (code.includes("Mag")) return "Mago";
      if (code.includes("Fer")) return "Ferreiro";
      if (code.includes("Pal")) return "Paladino";
      if (code.includes("Cav")) return "Cavaleiro da Morte";
      if (code.includes("Min")) return "Minerador";
      if (code.includes("Faz")) return "Fazendeiro";
      if (code.includes("Eng")) return "Engenheiro";
      if (code.includes("Len")) return "Lenhador";
    },
    getFinalValue(property) {
      let base = property.base_value
      let progressive = property.progressive
      let perkModifier = property.perk_modifier

      if (progressive) {
        let finalValue = ((1 + (100000 * perkModifier) / 148057) * base).toFixed(4);
        return finalValue;
      } else{ 
        return ((1 + (100000 * -perkModifier) / 148057) * base).toFixed(4);
      }
      
    },
    updatePerkModifier(property) {
      let progressive = property.progressive
      let finalValue = parseFloat(document.getElementById(this.skill.code+'_'+property.perk_code+"_final_value").value).toFixed(4)

      let perkInfluence = ((1.48057*finalValue/property.base_value) - 1.48057).toFixed(4)
      if (!progressive){
        perkInfluence = -((1.48057*finalValue/property.base_value) - 1.48057).toFixed(4)
      }
      property.perk_modifier = perkInfluence
    },
    updateSkill(){
      let perks= {}
      let attributes = {}
      let points_needed = {}
      _.each(this.skill.properties, property =>{
        let prop_name = property.perk_code
        let finalValue = parseFloat(document.getElementById(this.skill.code+'_'+property.perk_code+"_final_value").value).toFixed(4)
        let baseValue = parseFloat(document.getElementById(this.skill.code+'_'+property.perk_code+"_base_value").value).toFixed(4)
        let points = parseInt(document.getElementById(this.skill.code+'_'+property.perk_code+"_points_needed").value)
        property.base_value = baseValue

        let perkInfluence = ((1.48057*finalValue/property.base_value) - 1.48057).toFixed(4)
        if (!property.progressive){
          perkInfluence = -((1.48057*finalValue/property.base_value) - 1.48057).toFixed(4)
        }
        perks[prop_name] = parseFloat(perkInfluence)
        attributes[prop_name] = property.base_value
        points_needed[prop_name] = points;
        property.perk_modifier = perkInfluence
      })

      this.updateRequest({skill: this.skill.code,  perks: perks, attributes: attributes, points_needed: points_needed})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Habilidade atualizada com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar os atributos da habilidade.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
};
</script>
