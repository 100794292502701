<template>
  <div class="col-12 mx-0 px-0 ">
    <div v-for="page in getFirstPages()" :key="page.id">
      <p class="text-left" v-on:click="loadPageInParent(page.id)" > {{page.title}}</p>
      <div v-for="subpage in getSubPages(page.id)" :key="subpage.id">
        <p class="text-left" v-on:click="loadPageInParent(subpage.id)" >- {{subpage.title}}</p>
        <div v-for="subpage2 in getSubPages(subpage.id)" :key="subpage2.id">
           <p class="text-left" v-on:click="loadPageInParent(subpage2.id)" >-- {{subpage2.title}}</p>
          <div v-for="subpage3 in getSubPages(subpage2.id)" :key="subpage3.id">
            <p class="text-left" v-on:click="loadPageInParent(subpage3.id)" >--- {{subpage3.title}}</p>
             <div v-for="subpage4 in getSubPages(subpage3.id)" :key="subpage4.id">
              <p class="text-left" v-on:click="loadPageInParent(subpage4.id)" >---- {{subpage4.title}}</p>
               <div v-for="subpage5 in getSubPages(subpage4.id)" :key="subpage5.id">
                <p class="text-left" v-on:click="loadPageInParent(subpage5.id)" >----- {{subpage5.title}}</p>
                 <div v-for="subpage6 in getSubPages(subpage5.id)" :key="subpage6.id">
                  <p class="text-left" v-on:click="loadPageInParent(subpage6.id)" >------ {{subpage6.title}}</p>
                   <div v-for="subpage7 in getSubPages(subpage6.id)" :key="subpage7.id">
                    <p class="text-left" v-on:click="loadPageInParent(subpage7.id)" >------- {{subpage7.title}}</p>
                     <div v-for="subpage8 in getSubPages(subpage7.id)" :key="subpage8.id">
                      <p class="text-left" v-on:click="loadPageInParent(subpage8.id)" >-------- {{subpage8.title}}</p>
                       <div v-for="subpage9 in getSubPages(subpage8.id)" :key="subpage9.id">
                        <p class="text-left" v-on:click="loadPageInParent(subpage9.id)" >--------- {{subpage9.title}}</p>
                         <div v-for="subpage10 in getSubPages(subpage9.id)" :key="subpage10.id">
                          <p class="text-left" v-on:click="loadPageInParent(subpage10.id)" >---------- {{subpage10.title}}</p>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: "KompediaPageList",
  data() {
    return {
      wikiPages: this.pages
    };
  },
  props: {
    pages: Object,
  },
  beforeMount(){
    console.log(this.pages)
  },
  methods: {
    getFirstPages(){
      let sub_pages = []
      _.each(this.pages, page => {
        if (page.parent_id === null || page.parent_id === undefined || page.parent_id === ""){
          sub_pages.push(page)
        }
      });
      return sub_pages;
    },
    getSubPages(page_id){
      let sub_pages = []
      _.each(this.pages, page => {
        if (page.parent_id == page_id){
          sub_pages.push(page)
        }
      });
      return sub_pages;
    },
    loadPageInParent(page_id){
      this.$parent.loadPage(page_id);
    }
  },
};
</script>
