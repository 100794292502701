<template>
  <div class="col-12 row justify-content-center ml-1 mr-1">
    <div class="col-12">
      <h2 class="mt-2 mb-0"
        style="position: relative; display: block; width: 100%;"
      >
        {{ title }}:
      </h2>
    </div>
    <div v-if="products.length > 0" class="row col-8 mx-0 px-0 my-0 py-0 justify-content-center">
      <div
        v-for="product in products"
        :key="product.id"
        :class="
          'vip-shopcard shopcard-' +
            getProductQualityClass(product.lore) +
            ' col-12'
        "
      >
      <div class="col-12 mx-0 py-2 my-0">
        <span><img :src="'img/products/' + product.image" alt="" /></span>
        <span v-html="filterColor('h5', product.name)"></span>
      </div>

        <hr />
        {{ getProductQuality(product.lore) }}
        <p class="mx-0 px-0 my-0" v-html="splitLore(product.lore)"></p>
        <br />

        <p class="my-2 px-3 py-1 mx-0">
          Duração: 30 dias
        </p>
        <br />
        <p class="my-2 px-3 py-1 mx-0">
          Preço: {{ finalPrice(product.price) }} C
        </p>
        <button
          class="offminebutton mb-0 mt-0 col-4"
          style="width: 100%;"
          disabled
          v-if="!((has_access || beta_left == 0) && product.id == 12)"
        >
          Indisponivel
        </button>
        <p class="my-2 px-3 py-1 mx-0">
          Disponivel novamente após a Beta Aberta
        </p>
      </div>
    </div>
    <h3 v-else>
      Ainda não há produtos sendo vendidos nesta categoria.
    </h3>
  </div>
</template>
<script>
import Product from './../../mixins/ProductMixin'

export default {
  name: "ShopVipCategory",
  data() {
    return {
      products: [],
      hasDiscount: false,
      beta_left: 0,
      has_access: false,
    };
  },
  props: {
    category: String,
    title: String,
  },
  mixins: [Product],
  computed:{
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    finalPrice(price){
      if (this.hasDiscount){
        return (price - (0.30*price));
      }
      return price;
    },
    updateProducts(){
      this.getFromCategory(this.category)
        .then((data) => {
          this.products = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkDiscount(){
      if (!this.isAuthenticated){
        this.hasDiscount=false;
        return;
      }
      this.hasShopDiscount(this.user.attributes.id)
       .then((data) => {
          this.hasDiscount = data.has_shop_discount
        })
        .catch((err) => {
          console.log(err);
        });

    },
    getBetaLimit(){
      this.retrieveBetaLimit()
        .then((data) => {
          this.beta_left = data.left;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAlreadyHasAccess(){
      if (!this.isAuthenticated){
        this.has_access = false;
        return;
      }
      this.hasBetaAccess(this.user.attributes.id)
        .then((data) => {
          this.has_access = data.has_access;
          console.log(this.has_access)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount(){
    this.checkAlreadyHasAccess()
    this.getBetaLimit()
    this.updateProducts()

    this.checkDiscount()
  },
  watch:{
    category () {
      this.updateProducts()
    }
  }
};
</script>

<style scoped>
  .vip-shopcard {
    border: 3px solid #888;
    border-image: url(../../../public/img/inputborder.png);
    border-image-slice: 20.5% 4.40%;
    background-color: rgba(0,0,0, 0);
    margin: 4% auto auto auto;
    color: rgb(250, 201, 56);
    text-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.603);
    padding-bottom: 3%;
    margin-bottom: 3%;
    font-weight: bolder;

    background: url(../../../public/img/glass.png) repeat right top;
    background-size: 120% 120%;
    background-blend-mode: difference;
    background-position: center;
  }
  .vip-shopcard img {
    width: 80px;
    height: 80px;
  }
  .vip-shopcard form {
      font-size: 12px;
  }
</style>
