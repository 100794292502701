<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 justify-content-center mx-0">
      <h2 class="col-7 mx-0 px-0 mt-3 pb-0 text-center"
        style="background-image: url('img/grass.png'); background-size: 30px; background-repeat: repeat-x; background-position: bottom; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801);"
      >
        Guia
      </h2>
      <hr class="col-7 mx-0 px-0 my-0" />
      <h4
        class="col-12 text-center my-0 mb-0"
        style="text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801)"
      >
        Aprenda como funciona as mecânicas de jogo:
      </h4>
      <div class="row justify-content-center col-12">
        <div class="shopcard col-lg-2 mx-2 col-md-3 col-4">
          <h5>Classes</h5>
          <img src="img/classes.png" alt="" />
          <hr />
          <h6>
            <a href="wiki/classes"
              ><button style="border:none;  width: 100%;" class="minebutton">
                Ler
              </button></a
            >
          </h6>
        </div>
        <div class="shopcard col-lg-2 mx-2 col-md-3 col-4">
          <h5>Guildas</h5>
          <img src="img/fort.png" alt="" />
          <hr />
          <h6>
            <a href="wiki/guildas"
              ><button style="border:none;  width: 100%;" class="minebutton">
                Ler
              </button></a
            >
          </h6>
        </div>
        <div class="shopcard col-lg-2 mx-2 col-md-3 col-4">
          <h5>Economia</h5>
          <img src="img/diamond.png" alt="" />
          <hr />
          <h6>
            <button style="border:none;  width: 100%;" class="minebutton">
              --
            </button>
          </h6>
        </div>
        <div class="shopcard col-lg-2 mx-2 col-md-3 col-4">
          <h5>Aventura</h5>
          <img src="img/chest.png" alt="" />
          <hr />
          <h6>
            <a href="wiki/aventura"
              ><button style="border:none;  width: 100%;" class="minebutton">
                Ler
              </button></a
            >
          </h6>
        </div>
        <div class="shopcard col-lg-2 mx-2 col-md-3 col-4">
          <h5>Missões</h5>
          <img src="img/pergaminho.png" alt="" />
          <hr />
          <h6>
            <button style="border:none;  width: 100%;" class="minebutton">
              --
            </button>
          </h6>
        </div>
        <div class="shopcard col-lg-2 mx-2 col-md-3 col-4">
          <h5>Calabouços</h5>
          <img src="img/key.png" alt="" />
          <hr />
          <h6>
            <button style="border:none;  width: 100%;" class="minebutton">
              --
            </button>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Wiki",
  data() {
    return {};
  },
};
</script>
<style scoped>
.shopcard img{
  filter: contrast(175%);
}
.shopcard:hover img{
  filter: contrast(200%);
}
</style>
