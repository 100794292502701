<template>
  <div class="col-12 row justify-content-center ml-1 mr-1">
    <div class="col-12">
      <h2 class="mt-2 mb-0"
        style="position: relative; display: block; width: 100%;"
      >
        {{ title }}:
      </h2>
    </div>
    <div v-if="products.length > 0" class="row col-12 mx-0 px-0 my-0 py-0 justify-content-center">
      <div
        v-for="product in products"
        :key="product.id"
        :class="
          'shopcard shopcard-' +
            getProductQualityClass(product.lore) +
            ' col-lg-2 col-md-3 col-5 mx-lg-5 mx-2 mt-4 px-0 py-0'
        "
      >
        <span v-html="filterColor('h5', product.name)"></span>
        <img :src="'img/products/' + product.image" alt="" />
        <hr />
        {{ getProductQuality(product.lore) }}
        <br /><br />
        <p style="margin: 0% 0% 5% 0%; line-height: 100%;">
          Preço: {{ finalPrice(product.price) }} C
        </p>


        <button
          class="minebutton mb-0"
          style="width: 100%;"
          v-on:click="$emit('open-confirm-modal', product)"
          v-if="!((has_access || beta_left == 0) && product.id == 12)"
        >
          Comprar
        </button>
        <p v-else-if="((has_access) && product.id == 12)">Você já possui acesso</p>

        <div v-if="product.id == 12"> Restam {{beta_left}} vagas </div>
        
      </div>
    </div>
    <h3 v-else>
      Ainda não há produtos sendo vendidos nesta categoria.
    </h3>
  </div>
</template>
<script>
import Product from './../../mixins/ProductMixin'

export default {
  name: "ShopCategory",
  data() {
    return {
      products: [],
      hasDiscount: false,
      beta_left: 0,
      has_access: false,
    };
  },
  props: {
    category: String,
    title: String,
  },
  mixins: [Product],
  computed:{
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    finalPrice(price){
      if (this.hasDiscount){
        return (price - (0.30*price));
      }
      return price;
    },
    updateProducts(){
      this.getFromCategory(this.category)
        .then((data) => {
          this.products = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkDiscount(){
      if (!this.isAuthenticated){
        this.hasDiscount=false;
        return;
      }
      this.hasShopDiscount(this.user.attributes.id)
       .then((data) => {
          this.hasDiscount = data.has_shop_discount
        })
        .catch((err) => {
          console.log(err);
        });
      
    },
    getBetaLimit(){
      this.retrieveBetaLimit()
        .then((data) => {
          this.beta_left = data.left;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAlreadyHasAccess(){
      if (!this.isAuthenticated){
        this.has_access = false;
        return;
      }
      this.hasBetaAccess(this.user.attributes.id)
        .then((data) => {
          this.has_access = data.has_access;
          console.log(this.has_access)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  beforeMount(){
    this.checkAlreadyHasAccess()
    this.getBetaLimit()
    this.updateProducts()

    this.checkDiscount()
  },
  watch:{ 
    category () {
      this.updateProducts()
    }
  }
};
</script>
