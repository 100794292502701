<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 mb-5 justify-content-center mx-0">
      <h2 class="col-7 mx-0 px-0 mt-3 pb-0 text-center">
        Noticias
      </h2>
      <hr class="col-7 mx-0 px-0 my-0" />
      <br />

      <div class="container row justify-content-around mx-0 mt-5 px-0">
        <div
          class="lastposts col-lg-3 row col-11 mx-0 px-0 justify-content-center align-content-start"
        >
          <div class="lpctitle col-12 mx-0 px-0 mt-5 text-center" id="postPart">
            Ultimas postagens:
          </div>
          <hr class="col-12 mx-0 px-0" />
          <button
            v-for="post in pagePosts"
            :key="post.id"
            style="border:none;"
            class="lpc minebutton col-12 mx-0 px-0 my-0"
            v-on:click="loadPost(post.id)"
          >
            {{ post.title }}
          </button>
          <hr class="col-12 mx-0 px-0" />
        </div>
        <div
          id="postvp"
          class="post col-lg-8 col-11 row mx-auto px-0 my-3 justify-content-center align-content-start"
        >
          <div
            v-if="isLoading"
            class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0"
          >
            <img :src="'img/loading.gif'" alt="" class="loader-gif" />
          </div>
          <div
            v-if="!isLoading && actualPost != undefined"
            class="col-12 mx-0 px-0 my-0 py-0 row"
          >
            <h2 class="display-4 text-center col-12 mx-0 px-0">
              {{ actualPost.title }}
            </h2>
            <hr class="col-12 mx-0 px-0" />
            <div class="col-12 mx-0 px-0" v-html="actualPost.body"></div>

            <hr v-if="isAdmin()" class="col-12 mx-0 px-0" />
            <div
              v-if="isAdmin()"
              class="justify-content-center row col-12 mx-0 px-0"
            >
              <i
                class="fas fa-trash-alt fa-2x text-center  clickable-icon col-6"
                v-on:click="deletePost(actualPost.id)"
                v-tippy="{ content: 'Apagar' }"
                style="color: darkred"
              ></i>
            </div>
          </div>
          <div
            v-else-if="!isLoading && actualPost == undefined"
            class="col-12 mx-0 px-0 my-0 py-0"
          >
            Você ainda não selecionou uma postagem para ver. Selecione uma no
            menu ao lado. :)
          </div>
        </div>
        <div style="clear: right"></div>
        <br /><br />

        <div
          class="createPost col-lg-8 col-11 row mx-0 px-0 justify-content-center mt-5"
          v-if="isAdmin()"
        >
          <form ref="createPostForm">
            <br />
            <legend>Nova Postagem</legend>
            <br />
            Titulo: <br />
            <input id="nptitle" type="text" name="title" /><br /><br />

            Conteúdo: <br />
            <div class="textEditor">
              <editor
                v-model="bodyContent"
                api-key="iq4ail00zfbsrpzfag23785l09rqjcltejm6n3pm2ft3sp2e"
                :init="{
                  height: 500,
                  menubar: true,
                  images_upload_url: api_url+'/post_images',
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help',
                }"
              />
            </div>
            <div
              v-if="createErrors.length > 0"
              class="alert alert-danger col-8 mx-auto mt-3 mb-0"
              role="alert"
            >
              <transition-group name="slide-fade" mode="out-in">
                <div v-for="error in createErrors" :key="error">
                  {{ error }}
                </div>
              </transition-group>
            </div>
            <input
              
              class="minebutton my-3"
              v-on:click.prevent="requestCreatePost()"
              value="Postar"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "@tinymce/tinymce-vue";
import PostMixin from "./../mixins/PostMixin";

export default {
  name: "Blog",
  data() {
    return {
      actualPost: undefined,
      pagePosts: {},
      isLoading: true,
      bodyContent: "",
      createErrors: [],
      api_url: process.env.VUE_APP_API_URL,
    };
  },
  components: {
    Editor,
  },
  mixins: [PostMixin],
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    requestCreatePost() {
      let form = this.$refs.createPostForm;
      let title = form.title.value;
      let body = this.bodyContent;
      if (
        [body, title].includes(null) ||
        [body, title].includes(undefined) ||
        [body, title].includes("")
      ) {
        this.createErrors.push(
          "Você não inseriu titulo e corpo do post para cria-lo"
        );
        setTimeout(() => {
          this.createErrors.splice(0, 1);
        }, 5000);
        return;
      }
      this.createPost({ title, body, user_id: this.user.attributes.id }).then(
        () => {
          this.$router.go("/blog");
        }
      );
    },
    deletePost(id) {
      this.isLoading = true;
      this.destroyPost(id).then(() => {
        this.$router.go("/blog");
      });
    },
    loadLastPost() {
      this.isLoading = true;
      this.getLastPost().then((post) => {
        this.actualPost = post;
        this.isLoading = false;
      });
    },
    loadPost(id) {
      this.isLoading = true;
      this.showPost(id).then((post) => {
        this.isLoading = false;
        this.actualPost = post;
      });
    },
    loadAll() {
      this.isLoading = true;
      this.getPosts().then((posts) => {
        this.isLoading = false;
        this.pagePosts = posts;
      });
    },
  },
  mounted() {
    this.loadLastPost();
    this.loadAll();
    console.log(Editor)
  },
};
</script>
