<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 justify-content-center mx-0">
      <h2
        class="col-7 mx-0 px-0 mt-3 pb-0 text-center"
        style="background-image: url('/img/grass.png'); background-size: 30px; background-repeat: repeat-x; background-position: bottom; margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801);"
      >
        Guia
      </h2>
      <hr style="width: 52.5%; margin: 0% 23.75%; " />
      <h4
        id="pagetitle"
        style="margin:0%; text-shadow: 2px 4px 3px rgba(29, 28, 28, 0.801); width: 100%; text-align: center"
      >
        Aprenda como funciona as mecânicas de jogo:
      </h4>
      <br />
      <div class="row col-12 justify-content-center  ">
        <div class="wikiMainInfo">
          <a href="/wiki"
            ><i class="fas fa-times fa-2x backbtn" style="float: right"></i
          ></a>
          <img
            style="filter: contrast(225%); width: 30%; margin-left: 35%"
            src="/img/classes.png"
            alt=""
          /><br />
          <h2 style="text-align: center;">Classes:</h2>
          <hr style="width: 65%" />
          <br />
          <p
            style="text-decoration: underline; text-align: center; margin-top: 0px"
          >
            Clique em um dos Icones para ler sobre a respectiva Classe.
          </p>

          <div class="row justify-content-center classcontainer">
            <div
              class="col-md-1 col-2 clminiatureplus"
              v-on:click="dynamicSelect('Knight')"
            >
              <img src="/img/paladin.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Rogue')"
            >
              <img src="/img/rogue.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Mage')"
            >
              <img src="/img/mage.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Alchemist')"
            >
              <img src="/img/alchemist.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Farmer')"
            >
              <img src="/img/farmer.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Lumberjack')"
            >
              <img src="/img/lumberjack.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Miner')"
            >
              <img src="/img/miner.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Blacksmith')"
            >
              <img src="/img/blacksmith.png" alt="" />
            </div>
            <div
              class="col-md-1 col-2 clminiature"
              v-on:click="dynamicSelect('Engineer')"
            >
              <img src="/img/engineer.png" alt="" />
            </div>
          </div>
          <hr style="width: 90%" />
          <br />
          <div id="bg-image" style="width: 100%; height: auto">
            <component :is="classTab"></component>
          </div>

          <div style="clear: right; clear: left;"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alchemist from '@/components/wiki/classes/Alchemist.vue'
import Blacksmith from '@/components/wiki/classes/Blacksmith.vue'
import Engineer from '@/components/wiki/classes/Engineer.vue'
import Farmer from '@/components/wiki/classes/Farmer.vue'
import Knight from '@/components/wiki/classes/Knight.vue'
import Lumberjack from '@/components/wiki/classes/Lumberjack.vue'
import Mage from '@/components/wiki/classes/Mage.vue'
import Rogue from '@/components/wiki/classes/Rogue.vue'
import Miner from '@/components/wiki/classes/Miner.vue'
import About from "@/components/wiki/classes/About.vue"
export default {
  name: "Classes",
  data() {
    return {
      classTab: "About",
    };
  },
  components: {
    Alchemist,
    Blacksmith,
    Engineer,
    Farmer,
    Knight,
    Mage,
    Miner,
    Rogue,
    Lumberjack,
    About,
  },
  methods: {
    dynamicSelect(selected){
      this.classTab = selected
    },
    
  }
};
</script>
