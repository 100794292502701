<template>
  <div class="row col-12" style="height: max-content">
    <h2 class="col-12 mx-0 px-0">Sua conta:</h2>
    <div class="row col-12 mx-0 px-0">
      <div
        v-if="user.attributes.verified"
        class="col-12 mx-0 px-0 row justify-content-center"
      >
        <div class="col-12">
          <img
            :src="
              'https://minotar.net/helm/' +
              user.attributes.nickname +
              '/100.png'
            "
            class="col-1 px-0 mt-3 text-center"
            style="margin-bottom: -10px; width: 50px"
            alt="Conta Comum"
          />
        </div>
        <h2
          class="col-12 text-center simpleborder-1 my-4"
          style="border-top: none; border-left: none; border-right: none"
        >
          {{ user.attributes.nickname }}
        </h2>
      </div>
      <div class="row col-12 mx-0 px-0 my-2 justify-content-center">
        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/habilidades')">
          <p class="py-1">
            Balancear Habilidades
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/armas')">
          <p class="py-1">
            Balancear Armas
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/armaduras')">
          <p class="py-1">
            Balancear Armaduras
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/atributos-magicos')">
          <p class="py-1">
            Balancear Atributos Mágicos
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/refinamento')">
          <p class="py-1">
            Balancear Refinamento
          </p>
        </div>
        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/qualidades')">
          <p class="py-1">
            Balancear Qualidades
          </p>
        </div>
        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/xp')">
          <p class="py-1">
            Balancear XP
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/chances')">
          <p class="py-1">
            Balancear Chances
          </p>
        </div>
        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/stats')">
          <p class="py-1">
            Balancear Stats e Runas
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/loots')">
          <p class="py-1">
            Gerenciar Loots
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/itens')">
          <p class="py-1">
            Gerenciar Itens Looteaveis/Comerciais
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/itens/categorias')">
          <p class="py-1">
            Gerenciar Categoria de Itens
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/mercadores')">
          <p class="py-1">
            Gerenciar Mercadores
          </p>
        </div>

         <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/pontos-de-contrato')">
          <p class="py-1">
            Gerenciar Pontos de Contrato
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/guildas')">
          <p class="py-1">
            Balancear Sistemas de Guilda
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/stats-de-classe')">
          <p class="py-1">
            Balancear Stats Iniciais de Classe
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/balanceamento/eventos')">
          <p class="py-1">
            Configuração de Eventos
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/changelogs/habilidades')">
          <p class="py-1">
            Logs de Habilidade
          </p>
        </div>

        <div class="col-5 mx-3 my-3 minebutton" v-on:click="goTo('/dashboard')">
          <p class="py-1">
            Dashboard de Admnistração
          </p>
        </div>
      </div>
      <form class="col-12">
        <br />
        <input
          type="submit"
          v-on:click.prevent="logoff()"
          class="minebutton"
          value="Deslogar"
        />
      </form>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminPanel",
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
  },
  methods: {
    logoff() {
      this.$store.commit("cleanUser");
    },
    goTo(page){
      this.$router.push(page);
    }
  },
};
</script>
