<template>
  <div class="classmodalbody">
    <span class="close" onclick="dynamic_Select('classesInfo/aboutClasses')"
      >&times;</span
    >

    <div style="clear: right"></div>

    <div class="classtitle">
      <h1 style="margin: 0px">Ladino</h1>
      <hr />
      <h2 style="margin: 0px; color:purple">O Assassino das Sombras</h2>
    </div>
    <div class="row justify-content-center">
      <div class="charpic col-8 col-md-3">
        <img src="/img/roguechar.png" style="width: 100%" alt="" />
      </div>

      <div class="col-12 col-md-4">
        Ativas:
        <hr style="width: 50%; margin-left: 0px" />

        <ul>
          
          <li v-for="skill in skills" :key="skill"  :class="{ skillArrow: !skill.isArrowFlipped, skillArrowFlipped: skill.isArrowFlipped}"  v-on:click="openTab(skill.skillNumber)">
            <div>
              <h4 class="skillTitle">{{skill.skillName}}</h4>

              <div
                v-if="skill.isSkillBoxOn"
                :id="'skill'+skill.skillNumber"
                :ref="'skill'+skill.skillNumber"
                :class="{ skillBoxOn: skill.isSkillBoxOn}"
                style="display: none; text-align: left;"
              >
                <p style="text-align: center">Descrição da Habilidade:</p>
                <div
                  style="text-align: center"
                  class="row justify-content-center"
                >
                </div>
                <hr style="width: 92.5%" />

                <span v-html="skill.description"></span>
                <hr style="width: 92.5%" />
                <div v-if="skill.howToUse">
                  Como usar:
                  <span v-html="skill.howToUse"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        Passivas:
        <hr style="width: 50%; margin-left: 0px" />
        <ul>
          <li>Instinto de Assassino</li>
          <li>Golpe Traiçoeiro</li>
          <li>Precisão de Caçador</li>
          <li>Flechas Triplas</li>
          <li>Alma de Ender</li>
          <li>Ladrão Profissional</li>
        </ul>
      </div>

      <div style="clear: left;"></div>

      <div>
        <div>
          <hr />
          <p>
            "Ladinos são praticamente assassinos, uno com as sombras, capaz de
            se esgueirarem e desferirem golpes mortais contra suas vitimas lhes
            surpreendendo mórbidamente."<br />
          </p>
          <p>
            A agilidade aflorada e o sadismo fazem do ladino um combatente
            traiçoeiro, que se usa de adagas e arcos para caçar seus inimigos
            enquanto estes dificilmente o veêm chegando.<br />
          </p>
          <hr />
        </div>
        <div>
          <h2 style="text-align: center; margin-top: 0px">Combos</h2>
          <p>
            As Habilidades Ativas são executadas através de combos! <br />
            Abaixo estarão os combos necessários para ativar as habilidades do
            Ladino
          </p>
          <div class="combosubtitle">
            <h4>Legenda:</h4>

            <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> : Olhando
            para cima / <i class="fas fa-arrow-down"> </i
            ><i class="fas fa-eye"></i> : Olhando para baixo /
            <i
              class="fas fa-step-backward"
              style="transform: rotate(270deg)"
            ></i>
            : Shift <br />
            <i class="fas fa-running runact"></i> : Correndo /
            <i class="fas fa-mouse leftclick"></i> : Clique Esquerdo /
            <i class="fas fa-mouse rightclick"></i> : Clique Direito
          </div>
          <br />
          <div class="row justify-content-center">
            <div class="combosubtitle" style="float: left">
              Stealth: <br />
              <i
                class="fas fa-step-backward"
                style="transform: rotate(270deg)"
              ></i>
              +
              <i
                class="fas fa-step-backward"
                style="transform: rotate(270deg)"
              ></i>
            </div>
            <div class="combosubtitle" style="float: left">
              Redemoinho de Flechas: <br />
              <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
              <i class="fas fa-mouse leftclick"></i> +
              <i class="fas fa-mouse rightclick"></i>
            </div>
            <div class="combosubtitle" style="float: left">
              Arremesso Letal: <br />
              <i
                class="fas fa-step-backward"
                style="transform: rotate(270deg)"
              ></i>
              + <i class="fas fa-mouse rightclick"></i> +
              <i class="fas fa-mouse rightclick"></i>
            </div>
            <div class="combosubtitle" style="float: left">
              Salto Oportunista: <br />
              (pulando) <i class="fas fa-running runact"></i> +
              <i class="fas fa-mouse leftclick"></i> +
              <i class="fas fa-mouse leftclick"></i>
            </div>
            <div style="clear: left;"></div>
          </div>
        </div>
        <div style="clear: left;"></div>
        <div style="clear: right;"></div>

        <br />
        <hr />
        <div>
          <h2 style="text-align: center">Video:</h2>

          <iframe
            style="margin-left: 15%; padding:0px;"
            class="wikiMainInfo"
            width="70%"
            height="415"
            src="https://www.youtube.com/embed/sFN8OXknPfk"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Rogue",
  data() {
    return {
      skills: [
        {
          skillNumber: 1,
          skillName: "Modo Furtivo",
          description: `Misture-se com as
          sombras e torne-se invisivel. Se tiver uma bomba de fumaça a
          habilidade é mais efetiva.
          <br />
          Duração: 20 a 35s`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i
                class="fas fa-step-backward"
                style="transform: rotate(270deg)"
              ></i>
              +
              <i
                class="fas fa-step-backward"
                style="transform: rotate(270deg)"
              ></i>`,
        },
        {
          skillNumber: 2,
          skillName: "Redemoinho de Flechas",
          description: "Com rodopios, atira 2 rajadas de flecha para todos os lados enfraquecendo seus alvos. Se estiver Invisivel, joga uma terceira rajada, mais forte.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
              <i class="fas fa-mouse leftclick"></i> +
              <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 3,
          skillName: "Arremesso Letal",
          description: `Arremessa uma
          adaga. Se pegar no alvo causa dá fraqueza. Se estiver Invisivel, o
          efeito negativo é mais forte.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: ` <i
                class="fas fa-step-backward"
                style="transform: rotate(270deg)"
              ></i>
              + <i class="fas fa-mouse rightclick"></i> +
              <i class="fas fa-mouse rightclick"></i>`,
        },

        {
          skillNumber: 4,
          skillName: "Salto Oportunista",
          description: `Se teleporta
          para trás do alvo e o deixa vulnerável. Se estiver Invisivel,
          paralisará o alvo por um pequeno instante de tempo`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `(pulando) <i class="fas fa-running runact"></i> +
              <i class="fas fa-mouse leftclick"></i> +
              <i class="fas fa-mouse leftclick"></i>`,
        },
      ],
    };
  },
  methods: {
    openTab(number) {

      _.each(this.skills, (skill) => {
        if (skill.skillNumber == number) {
          skill.isSkillBoxOn = !skill.isSkillBoxOn;

          skill.isArrowFlipped = !skill.isArrowFlipped;
        } else {
          if (skill.isSkillBoxOn) {
            skill.isSkillBoxOn = false;
          }
          if (skill.isArrowFlipped) {
            skill.isArrowFlipped = false;
          }
        }
      });

    },
  },
};
</script>
