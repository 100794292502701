<template>
  <div class="classmodalbody">
    <span class="close" v-on:click="$parent.dynamicSelect('About')"
      >&times;</span
    >

    <div style="clear: right"></div>

    <div class="classtitle">
      <h1 style="margin: 0px">Ferreiro</h1>
      <hr />
      <h2 style="margin: 0px; color:rgb(138, 138, 138)">Mestre da Forja</h2>
    </div>
    <div class="row justify-content-center">
      <div class="charpic col-8 col-md-3">
        <img src="/img/blacksmithchar.png" style="width: 100%;" alt="" />
      </div>
      <div style="clear: left;"/>

      <div class="col-12 col-md-4">
        Ativas:
        <hr style="width: 50%; margin-left: 0px" />
       <ul>
          
          <li v-for="skill in skills" :key="skill"  :class="{ skillArrow: !skill.isArrowFlipped, skillArrowFlipped: skill.isArrowFlipped}"  v-on:click="openTab(skill.skillNumber)">
            <div>
              <h4 class="skillTitle">{{skill.skillName}}</h4>

              <div
                v-if="skill.isSkillBoxOn"
                :id="'skill'+skill.skillNumber"
                :ref="'skill'+skill.skillNumber"
                :class="{ skillBoxOn: skill.isSkillBoxOn}"
                style="display: none; text-align: left;"
              >
                <p style="text-align: center">Descrição da Habilidade:</p>
                <div
                  style="text-align: center"
                  class="row justify-content-center"
                >
                </div>
                <hr style="width: 92.5%" />

                <span v-html="skill.description"></span>
                <hr style="width: 92.5%" />
                <div v-if="skill.howToUse">
                  Como usar:
                  <span v-html="skill.howToUse"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        Passivas:
        <hr style="width: 50%; margin-left: 0px" />
        <ul>
          <li>Dominio da Forja</li>
          <li>Essências Naturais</li>
          <li>Resistência ao Fogo</li>
          <li>Precisão de Ferreiro</li>
          <li>Manutenção</li>
          <li>Ranhuras Duplas</li>
        </ul>
      </div>
    </div>
    <div style="clear: left;"></div>

    <div>
      <div>
        <hr />
        <p>
          "Ferreiros são, além de profissionais da forja, combatentes robustos
          muito úteis em cambos de batalha"<br />
        </p>

        <p>
          Fabricam armas, armaduras e ferramentas e usam seu martelo em combate
          para dar golpes pesados e quebrar armaduras.<br />
        </p>

        <hr />
      </div>

      <h2 style="text-align: center; margin-top: 0px">Combos</h2>
      <p>
        As Habilidades Ativas são executadas através de combos! <br />
        Abaixo estarão os combos necessários para ativar as habilidades do
        Ferreiro
      </p>
      <div class="combosubtitle">
        <h4>Legenda:</h4>

        <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> : Olhando
        para cima / <i class="fas fa-arrow-down"> </i
        ><i class="fas fa-eye"></i> : Olhando para baixo /
        <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> :
        Shift <br />
        <i class="fas fa-running runact"></i> : Correndo /
        <i class="fas fa-mouse leftclick"></i> : Clique Esquerdo /
        <i class="fas fa-mouse rightclick"></i> : Clique Direito
      </div>
      <br />
      <div class="row justify-content-center">
        <div class="combosubtitle" style="float: left">
          Retoque: <br />
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          +
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Impacto Estrondoso: <br />
          <i class="fas fa-arrow-down"> </i><i class="fas fa-eye"></i> +
          <i class="fas fa-mouse leftclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Amolação Dinâmica: <br />
          <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>
          + <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div class="combosubtitle" style="float: left">
          Golpe Esmagador: <br />
          <i class="fas fa-running runact"></i> +
          <i class="fas fa-mouse rightclick"></i> +
          <i class="fas fa-mouse rightclick"></i>
        </div>
        <div style="clear: left;"></div>
      </div>
    </div>

    <div style="clear: both;"></div>

    <br />
    <hr />

    <div class="row col-12 justify-content-center">
      <h2 class="col-12" style="text-align: center; margin-top: 0px">
        Itens Únicos
      </h2>
      <p class="col-12">
        Aqui estão todos os itens uteis a um Ferreiro ou exclusivos dele:
      </p>
      <div class="itemImage simpleborder-1">
        <img src="/img/itens/engineer/ammo.png" alt="" />
      </div>
      <div class="itemImage simpleborder-1">
        <img src="/img/itens/engineer/blindgrenade.png" alt="" />
      </div>
      <div class="itemImage simpleborder-1">
        <img src="/img/itens/engineer/disarmkit.png" alt="" />
      </div>
      <div class="itemImage simpleborder-1">
        <img src="/img/itens/engineer/eletricshield.png" alt="" />
      </div>
      <div class="itemImage simpleborder-1">
        <img src="/img/itens/engineer/lock.png" alt="" />
      </div>
      <div class="itemImage simpleborder-1">
        <img src="/img/itens/engineer/propulsionsole.png" alt="" />
      </div>

      <div style="clear: both;"></div>
    </div>

    <br />
    <hr />

    <div>
      <h2 style="text-align: center">Video:</h2>

      <iframe
        style="margin-left: 15%; padding:0px;"
        class="wikiMainInfo"
        width="70%"
        height="415"
        src="https://www.youtube.com/embed/RFuOEQcYJWI"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Blacksmith",
  data() {
    return {
      skills: [
        {
          skillNumber: 1,
          skillName: "Retoque",
          description: "O Ferreiro usa seu martelo para retocar temporariamente sua armadura ou de um aliado, dando bonus de armadura. Se tiver uma Runa na mão secundária, aplica efeitos especiais por curto periodo de tempo também.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
                +
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>`,
        },
        {
          skillNumber: 2,
          skillName: "Impacto Estrondoso",
          description: `Golpeia o chão com seu martelo com tanta força que formam labaredas que dão em quem estiver
                        próximo do Ferreiro.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `  <i class="fas fa-arrow-down"> </i><i class="fas fa-eye"></i> + <i class="fas fa-mouse leftclick"></i> +
                <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 3,
          skillName: "Amolação Dinâmica",
          description: `Dá uma leve afiada na arma ou ferramenta de um aliado, aumentando seu dano temporariamente ou a
                        velocidade com que quebra blocos.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: ` <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i
                    class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 4,
          skillName: "Golpe Esmagador",
          description: `Se projeta em direção ao adversário dando-lhe uma martelada muito poderosa que além de dar dano
                        pode destruir partes de uma armadura.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: ` <i class="fas fa-running runact"></i> + <i class="fas fa-mouse rightclick"></i> + <i
                    class="fas fa-mouse rightclick"></i>`,
        },
      ],
    };
  },
  methods: {
    openTab(number) {
      _.each(this.skills, (skill) => {
        if (skill.skillNumber == number) {
          skill.isSkillBoxOn = !skill.isSkillBoxOn;

          skill.isArrowFlipped = !skill.isArrowFlipped;
        } else {
          if (skill.isSkillBoxOn) {
            skill.isSkillBoxOn = false;
          }
          if (skill.isArrowFlipped) {
            skill.isArrowFlipped = false;
          }
        }
      });
    },
  },
};
</script>
