import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Staff from '../views/Staff.vue'
import Shop from '../views/Shop.vue'
import Dashboard from '../views/GeneralDashboard.vue'

import SkillBalanceTable from '../views/skills/BalanceTable.vue'
import SkillLogs from '../views/logs/SkillLogs.vue'

import XPBalanceTable from '../views/xp/BalanceTable.vue'
import StatsBalanceTable from '../views/stats/BalanceTable.vue'
import ItemManageTable from '../views/items/ManageTable.vue'
import ItemCategoryManageTable from '../views/item_categories/ManageTable.vue'
import MerchantManageTable from '../views/merchants/ManageTable.vue'
import ContractMerchantManageTable from '../views/contracts/ManageTable.vue'


import LootManageTable from '../views/loots/ManageTable.vue'

import LootEditor from '../views/loots/LootEditor.vue'
import MerchantEditor from '../views/merchants/MerchantEditor.vue'


import WeaponAttributeBalanceTable from '../views/weapon_attributes/BalanceTable.vue'
import ArmorAttributeBalanceTable from '../views/armor_attributes/BalanceTable.vue'
import MagicAttributeBalanceTable from '../views/magic_attributes/BalanceTable.vue'
import RefinementBalanceTable from '../views/refinements/BalanceTable.vue'
import QualityBalanceTable from '../views/qualities/BalanceTable.vue'
import ChanceBalanceTable from '../views/chances/BalanceTable.vue'
import GuildBalanceTable from '../views/game_configs/GuildConfig.vue'
import ClassesStatsConfig from '../views/game_configs/ClassesStatsConfig.vue'
import GameEventsConfig from '../views/game_configs/GameEventsConfig.vue'

import Progress from '../views/Progress.vue'
import CreateTask from '../views/progress/Create.vue'
import ShowTask from '../views/progress/Show.vue'
import EditTask from '../views/progress/Edit.vue'
import TaskChangelog from '../views/progress/Log.vue'

import DiscordCallback from '../views/callbacks/DiscordLogin.vue'

import Wiki from '../views/Wiki.vue'
import Blog from '../views/Blog.vue'
import Kompedia from '../views/Kompedia.vue'

import WikiClasses from '../views/wiki/Classes.vue'
import WikiAdventure from '../views/wiki/Adventure.vue'
import WikiGuilds from '../views/wiki/Guilds.vue'
import AskPasswordReset from '../views/login/AskPasswordReset.vue'
import PasswordReset from '../views/login/PasswordReset.vue'

import Redirector from '../components/redirects/redirector.vue'
// import DiscordInvite from '../components/redirects/DiscordInvite.vue'
// import TwitchLink from '../components/redirects/TwitchLink.vue'

import btoa from 'btoa'
import axios from 'axios'
import queryString from 'query-string'
import Cookie from 'js-cookie'
import { get } from './../utils/Request'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/esqueci-minha-senha',
    name: 'AskPasswordReset',
    component: AskPasswordReset
  },
  {
    path: '/resetar-senha',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/progresso',
    name: 'Progress',
    component: Progress
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/kompedia',
    name: 'Kompedia',
    component: Kompedia
  },
  {
    path: '/progresso/criar-tarefa',
    name: 'CreateTask',
    component: CreateTask
  },
  {
    path: '/progresso/ver-tarefa/:id',
    name: 'ShowTask',
    component: ShowTask
  },
  {
    path: '/progresso/editar-tarefa/:id',
    name: 'EditTask',
    component: EditTask
  },
  {
    path: '/progresso/tarefas-concluidas',
    name: 'TaskChangelog',
    component: TaskChangelog
  },
  {
    path: '/loja',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/staff',
    name: 'Staff',
    component: Staff
  },
  {
    path: '/balanceamento/habilidades',
    name: 'SkillBalanceTable',
    component: SkillBalanceTable
  },
  {
    path: '/balanceamento/armas',
    name: 'WeaponAttributeBalanceTable',
    component: WeaponAttributeBalanceTable
  },
  {
    path: '/balanceamento/guildas',
    name: 'GuildBalanceTable',
    component: GuildBalanceTable
  },
  {
    path: '/balanceamento/stats-de-classe',
    name: 'ClassesStatsConfig',
    component: ClassesStatsConfig
  },
  {
    path: '/balanceamento/eventos',
    name: 'GameEventsConfig',
    component: GameEventsConfig
  },
  {
    path: '/mercadores',
    name: 'MerchantManageTable',
    component: MerchantManageTable
  },
  {
    path: '/pontos-de-contrato',
    name: 'ContractMerchantManageTable',
    component: ContractMerchantManageTable
  },
  {
    path: '/itens',
    name: 'ItemManageTable',
    component: ItemManageTable
  },
  {
    path: '/itens/categorias',
    name: 'ItemCategoryManageTable',
    component: ItemCategoryManageTable
  },
  {
    path: '/loots',
    name: 'LootManageTable',
    component: LootManageTable
  },
  {
    path: '/loots/editar-loot/:id',
    name: 'LootEditor',
    component: LootEditor
  },
  {
    path: '/mercadores/:id',
    name: 'MerchantEditor',
    component: MerchantEditor
  },
  {
    path: '/balanceamento/armaduras',
    name: 'ArmorAttributeBalanceTable',
    component: ArmorAttributeBalanceTable
  },
  {
    path: '/balanceamento/atributos-magicos',
    name: 'MagicAttributeBalanceTable',
    component: MagicAttributeBalanceTable
  },
  {
    path: '/balanceamento/refinamento',
    name: 'RefinementBalanceTable',
    component: RefinementBalanceTable
  },
  {
    path: '/balanceamento/qualidades',
    name: 'QualityBalanceTable',
    component: QualityBalanceTable
  },
  {
    path: '/balanceamento/chances',
    name: 'ChanceBalanceTable',
    component: ChanceBalanceTable
  },
  {
    path: '/balanceamento/stats',
    name: 'StatsBalanceTable',
    component: StatsBalanceTable
  },
  {
    path: '/changelogs/habilidades',
    name: 'SkillLogs',
    component: SkillLogs
  },
  {
    path: '/balanceamento/xp',
    name: 'XPBalanceTable',
    component: XPBalanceTable
  },
  {
    path: '/wiki',
    name: 'Wiki',
    component: Wiki
  },
  {
    path: '/wiki/classes',
    name: 'WikiClasses',
    component: WikiClasses
  },
  {
    path: '/wiki/aventura',
    name: 'WikiAdventure',
    component: WikiAdventure
  },

  {
    path: '/wiki/guildas',
    name: 'WikiGuilds',
    component: WikiGuilds
  },

  {
    path: '/discord',
    component: Redirector,
    props: { platform: 'discord' }
  },

  {
    path: '/twitch',
    component: Redirector,
    props: { platform: 'twitch' }
  },

  {
    path: '/discord-callback',
    name: 'DiscordCallback',
    component: DiscordCallback,
    beforeEnter: async (to, from, next) => {
      if (to.query.code != null) {
        const code = to.query.code
        const creds = btoa(`${process.env.VUE_APP_BOT_CLIENT_ID}:${process.env.VUE_APP_BOT_CLIENT_SECRET}`)
        await axios
          .post(
            'https://discordapp.com/api/oauth2/token',
            queryString.stringify({
              client_id: process.env.VUE_APP_BOT_CLIENT_ID,
              client_secret: process.env.VUE_APP_BOT_CLIENT_SECRET,
              grant_type: 'authorization_code',
              code: code,
              redirect_uri: process.env.VUE_APP_LOGIN_REDIRECT_URL,
              scope: 'identify email guilds'
            }),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Basic ${creds}`
              }
            }
          )
          .then((result) => {
            const access_token = result.data.access_token
            const refresh_token = result.data.refresh_token

            Cookie.set('discord_access_token', access_token)
            Cookie.set('discord_refresh_token', refresh_token)
          })
          .catch((error) => {
            console.warn(error)
          })

        await get('https://discordapp.com/api/users/@me', null, {
          Authorization: 'Bearer ' + Cookie.get('discord_access_token')
        })
          .then(function (response) {
            return response.text()
          })
          .then(async (text) => {
            const res = JSON.parse(text)
            Cookie.set('discord_id', res.id)
            next()
          })
          .catch(function (error) {
            console.log('Request failed', error)
          })
      } else {
        next('/discord-callback-failed')
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
