<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h4 class="col-12 text-center mx-0">{{ magic_attribute.name }}</h4>
    <hr class="col-12 mx-0 px-0" />
    <p class="col-12 mx-0 px-3"> {{magic_attribute.description }}</p>
    <hr class="col-12 mx-0 px-0" />
    <h5 class="col-12 mx-0 px-0 text-center">Valores</h5>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">{{MinValue()}}</th>
              <th scope="col">{{MaxValue()}}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="magic_attribute.min_value"
                    :id="magic_attribute.id+'_min_value'"
                  />
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="magic_attribute.max_value"
                    :id="magic_attribute.id+'_max_value'"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateMagicAttribute()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>
        </transition-group>

      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>
        </transition-group>

      </div>
  </div>
</template>
<script>
import MagicAttribute from '../../mixins/MagicAttributeMixin'
export default {
  name: "MagicAttributeCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      magic_attribute: this.magic_attr,
      is_armor: false
    };
  },
  props: {
    magic_attr: Object
  },
  mixins: [MagicAttribute],
  methods: {
    MinValue(){
      if (this.magic_attribute.name.includes("Chance Base") || this.magic_attribute.name.includes("Quebrar ao Falhar")
       || (this.magic_attribute.name.includes("Custo de") && (!this.magic_attribute.name.includes("Gema") && !this.magic_attribute.name.includes("Pedra Mágica")))){
        return "Primeiro Aprimoramento"
      }
      if (this.magic_attribute.name.includes("Gema") || this.magic_attribute.name.includes("Pedra Mágica")){
        return "Valor Único";
      }
      return "Valor Mínimo";
    },
    MaxValue(){
      if (this.magic_attribute.name.includes("Chance Base") || this.magic_attribute.name.includes("Quebrar ao Falhar")
      || (this.magic_attribute.name.includes("Custo de") & (!this.magic_attribute.name.includes("Gema") && !this.magic_attribute.name.includes("Pedra Mágica")))) {
        return "Segundo Aprimoramento"
      }
      if (this.magic_attribute.name.includes("Gema") || this.magic_attribute.name.includes("Pedra Mágica")){
        return "-----";
      }
      return "Valor Máximo";
    },
    updateMagicAttribute(){

      let min_value = this.magic_attribute.min_value
      let max_value = this.magic_attribute.max_value
      console.log("Min value: "+min_value)
      console.log("Max value: "+max_value)
      this.updateRequest(this.magic_attribute.id,{
        magic_attribute: {
          min_value, max_value
        }})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Atributo Mágico atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          if (this.is_armor){
           this.updateErrors.push("Houve um erro ao tentar atualizar este atributo.")
          } else {
           this.updateErrors.push("Houve um erro ao tentar atualizar este atributo.")
          }
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  }
};
</script>
