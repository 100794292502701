<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <div class="row col-12 justify-content-right mx-0 px-1 py-0 my-0">
        <i v-on:click="destroyChance()" style="color: darkred; cursor: pointer; text-align: right;" class="fas fa-trash-alt fa col"></i>
    </div>
    <div v-if="name_editor" class="col-12 text-center mx-0">
      <input class="mx-2 px-2 mt-3 mb-0 py-2 simpleborder-1" type="text" v-model="new_chance_key" placeholder="Nome do item/chance" />
    </div>

    <div v-else class="col-12 text-center mx-0">
      <h3 class="col-12 text-center mx-0 px-0 my-0 py-0">{{ chance.chance_key }}</h3>
    </div>

    <span v-if="!name_editor" v-on:click="toggleNameEditor()" class="mx-2">
        <i class="fa-solid fa-pen-to-square"></i>
    </span>
    <span  class="mx-2" v-on:click="updateAndToggleNameEditor()" v-if="name_editor">
      <i class="fa-solid fa-check"></i>
    </span>

    <hr class="col-12 mx-0 px-0" />
    <div class="col-12 row mx-0 px-0">
      <div class="col-12 mx-0 px-0 my-1 py-0">
        <input type="checkbox" :id="chance.chance_key+'by_passive'" :name="chance.chance_key+'by_passive'" class="mx-2" v-model="chance.by_passive">
        <label for="by_passive">Vinculado a passiva?</label>
      </div>

      <!-- Se for chance de passiva -->
      <div v-if="chance.by_passive" class="col-12 mx-0 px-0 my-1 py-0">
        <div class="col-12 mx-0 px-0 my-1 py-0">
          <p>Habilidade</p>
          <select :id="chance.chance_key+'skill_code'" :name="chance.chance_key+'skill_code'" v-model="chance.passive_skill_code">
            <option v-for="skill_name in this.$parent.skill_names"
              :key="skill_name.skill_code" :value="skill_name.skill_code">{{skill_name.name}}</option>
          </select>
        </div>
        <div class="col-12 mx-0 px-0 my-1 py-0">
          <p>Atributo da Habilidade</p>

          <select :id="chance.chance_key+'property'" :name="chance.chance_key+'property'" v-model="chance.passive_property">
            <option v-for="perk_name in filtered_perks_list" :key="perk_name.property"
              :value="filtered_property_name(perk_name.property)">{{perk_name.name}}
            </option>

          </select>

          <div style="font-size: 10px">
            ({{ chance.passive_skill_code }}
            {{ chance.passive_property }})
          </div>
        </div>

        <div class="col-12 mx-0 px-0 my-1 py-0">
          <p>Redução quando sem a Passiva</p>
          <input type="numeric" v-model="chance.strong_reduction" placeholder="0.75 (75%)" class="mx-2"/>
        </div>
      </div>
      <!-- Se não for chance de passiva -->
      <div v-else class="col-12 mx-0 px-0 my-1 py-0">
        <div class="col-12 mx-0 px-0 my-1 py-0">
          <p>Nivel que inicia (do Jogador)</p>
          <input type="numeric" v-model="chance.min_level" placeholder="Ex.: 1" class="mx-2"/>
        </div>
        <div class="col-12 mx-0 px-0 my-1 py-0">
          <p>Nivel que maximiza (do Jogador)</p>
          <input type="numeric" v-model="chance.max_level" placeholder="Ex.: 60" class="mx-2"/>
        </div>
        <div v-if="chance.exclusive_class != 'Todas'" class="col-12 mx-0 px-0 my-1 py-0">
          <p>Redução quando Secundária</p>
          <input type="numeric" v-model="chance.weak_reduction" placeholder="0.75 (75%)" class="mx-2"/>
        </div>
        <div  v-if="chance.exclusive_class != 'Todas'" class="col-12 mx-0 px-0 my-1 py-0">
          <p>Redução quando sem a Classe</p>
          <input type="numeric" v-model="chance.strong_reduction" placeholder="0.25 (25%)" class="mx-2"/>
        </div>
      </div>
      <hr class="col-12 mx-0 px-0 my-0 py-0">
      <!-- Geral -->
      <div class="col-12 mx-0 px-0 my-1 py-0">
        <p>Valor Mínimo</p>
        <input type="numeric" v-model="chance.min_value" placeholder="Chance no nível 1. Ex: 0.5" class="mx-2"/>
      </div>
      <div class="col-12 mx-0 px-0 my-1 py-0">
        <p>Valor Máximo</p>
        <input type="numeric" v-model="chance.max_value" placeholder="Chance no nível máx. Ex: 100" class="mx-2"/>
      </div>
      <div class="col-12 mx-0 px-0 my-1 py-0">
          <p>Classe Especializada</p>

          <select :id="chance.chance_key+'exclusive_class'" :name="chance.chance_key+'exclusive_class'" v-model="chance.exclusive_class">
            <option value="Todas"> Todas</option>
            <option value="Fazendeiro"> Fazendeiro</option>
            <option value="Lenhador"> Lenhador</option>
            <option value="Minerador"> Minerador</option>
            <option value="Ferreiro"> Ferreiro</option>
            <option value="Ladino"> Ladino</option>
            <option value="Paladino"> Paladino</option>
            <option value="Alquimista"> Alquimista</option>
            <option value="Mago"> Mago</option>
            <option value="Engenheiro"> Engenheiro</option>
            <option value="Cavaleiro da Morte"> Cavaleiro da Morte</option>
          </select>
        </div>
    </div>
    <hr class="col-12 mx-0 px-0 mt-2 mb-0 py-0">

    <div class='tag-input col-9 mx-0 px-0 my-4'>
      <div v-for='(tag, index) in chance.tags' :key='tag' class='tag-input__tag' style="font-size: 9px; align-self: left">
        {{ tag }}
        <span @click='removeTag(index)'>x</span>
      </div>
      <input
        type='text'
        placeholder="Digite uma Tag e aperte Enter"
        class='tag-input__text'
        @keydown.enter='addTag'
        @keydown.delete='removeLastTag'
      />
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateChance()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>
        </transition-group>

      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>
        </transition-group>

      </div>
  </div>
</template>
<script>

import Chance from '../../mixins/ChanceMixin'
export default {
  name: "ChanceCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      chance: this.p_chance,
      player_level: 25,
      name_editor: false,
      filtered_perks_list: [],
      skill_code: this.p_chance.passive_skill_code,
      new_chance_key: this.p_chance.chance_key,
    };
  },
  props: {
    p_chance: Object
  },
  mixins: [Chance],
  methods: {
    addTag(event) {
      event.preventDefault()
      let val = event.target.value.trim()
      if (val.length > 0) {
        if (this.chance.tags.length >= 1) {
          for (let i = 0; i < this.chance.tags.length; i++) {
            if (this.chance.tags[i] === val) {
              return false
            }
          }
        }
        this.chance.tags.push(val)
        event.target.value = ''
        console.log(this.chance.tags)
      }
    },
    removeTag(index) {
      this.chance.tags.splice(index, 1)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.chance.tags.length - 1)
      }
    },
    updateAndToggleNameEditor(){
      this.chance.chance_key = this.new_chance_key
      this.name_editor = !this.name_editor
    },
    pluck(arr, key) {
      return arr.map(i => i[key])
    },
    filtered_property_name(property){
      if (property.includes("chancet")){
        return property.replace("chancet", "ChanceT");
      } else {
        return this.capitalizeFirstLetter(property);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    updateChance(){
      this.updateChanceRequest(this.chance.id, this.chance)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Chance atualizada com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar este valor.")

          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    destroyChance(){
      this.destroyChanceRequest(this.chance.id)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Chance removida com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar remover esta chance.")

          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    },
    toggleNameEditor(){
      this.name_editor = !this.name_editor
    },
    filter_perks(){
      this.filtered_perks_list = []
      console.log("Filtrando por:" +this.chance.passive_skill_code)
      let perk_names = this.$parent.perk_names
      for (let i = 0; i<perk_names.length; i++){
        let perk_name = perk_names[i];
        if (perk_name.skill_code == this.chance.passive_skill_code){
          this.filtered_perks_list.push(perk_name)
        }
      }
      return "LadP1";
    }
  },
  beforeMount(){
    this.filter_perks();
  },
  watch: {
    'chance.passive_skill_code'(){
      this.filter_perks()
    }
  }
};
</script>