<template>
  <div v-if="isAuthenticated && $parent.wallet" class="shopProfile col-12 row justify-content-center mx-0 px-0">
    <div class="col-12 row mx-0 px-0">
      <img
        :src="
          'https://minotar.net/helm/' + user.attributes.nickname + '/100.png'
        "
        width="120px"
        style="margin-bottom: -10px;"
        alt="Conta Comum"
      />
    </div>
    <h5 class="col-12" style="margin-bottom: 10px;">{{ user.attributes.nickname }}</h5>
    <div class="col-lg-3 col-8 row mx-0 px-0 cashInfo justify-content-center">
      <div id="cashWallet" class="col-12 py-1">{{ $parent.wallet.amount }} Cash</div>
      <button
        class="col-12 py-1 buybutton text-center px-0 mx-0"
        v-on:click="$parent.openCashSection()"
      >
        Comprar Cash <i class="fas fa-shopping-cart"></i>
      </button>
    </div>
   <div class="col-12 my-3"></div>
    <button
      class="col-lg-2 px-0 col-8 align-self-end  minebutton"
      v-on:click="$parent.changeToInventory()"
      style="z-index: 250"
    >
      Inventário
    </button>
   <div class="col-12 my-3"></div>
    <button
      class="col-lg-2 col-8 align-self-end  minebutton px-0"
      v-on:click="$parent.changeToRedeemCode()"
      style="z-index: 250"
    >
      <i class="fas fa-ticket-alt"></i> Resgatar Código
    </button>
    <hr class="col-12 mx-0 px-0" style="width: 61.8%; margin-bottom: 10px;" />
  </div>

  <div v-else-if="isAuthenticated && !$parent.wallet" class="shopProfile col-12">
    <br />
    <h5 style="margin-bottom: 10px;">
      Houve um erro enquanto buscavamos pela sua carteira. Tente novamente ou
      comunique um administrador.
    </h5>
    <br />
  </div>

  <div v-else-if="!isAuthenticated" class="shopProfile col-12 mx-0 px-0 justify-content-center row">
    <br class="col-12"/>
    <h5 class="col-12 my-2">Você não está na sua conta</h5>
    <div class=" col-6 row cashInfo justify-content-center">
      <button
        class="col-5 minebutton"
        id="myBtn2"
        style="z-index: 300; margin-top: 30px; margin-bottom: 30px"
      >
        Login
      </button>
    </div>

    <br />
  </div>
</template>
<script>
import Wallet from "./../../mixins/WalletMixin";
export default {
  name: "ShopProfile",
  data() {
    return {
      
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  mixins: [Wallet],
};
</script>
