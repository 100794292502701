<template>
    <div class="col-12 row justify-content-center ml-1 mr-1">
      <div class="col-12">
        <h2
          style="margin: 0px auto 0px 0%; position: relative; display: block; width: 100%;"
        >
          Inventário:
        </h2>
        <p class="text-center">- Acesse o jogo para ativar estes itens. Lá, digite /loja para acessar seu inventário de cash</p>
        <p class="text-center">- Itens ativados dentro do jogo não aparecem mais no seu inventário de cash</p>

      </div>

      <div v-for="attachment in attachments" :key="attachment.id" class="shopcard row col-lg-2 col-md-3 col-5 mx-5 px-0 justify-content-center">
        <span class="col-12" v-html="filterColor('span', attachment.product.customname)"></span>
        <img class="col-5 my-3 px-auto" :src="'img/products/'+attachment.product.image" alt="" />
        <hr class="col-12 mx-0 px-0"/>
        <p class="col-12 mx-0 px-0">
          Quantidade: {{attachment.quantity}}
        </p>
        <br />
      </div>
    </div>
</template>
<script>
import Attachment from "./../../mixins/AttachmentMixin";
import Product from "./../../mixins/ProductMixin";

export default {
  name: "Inventory",
  data() {
    return {
      attachments: [],
    };
  },
  mixins: [Product, Attachment],
  computed: {},
  methods: {
    updateAttachments() {
      this.getPlayerItems()

        .then((data) => {
          this.attachments = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.updateAttachments();
  },
  watch: {
    category() {
      this.updateAttachments();
    },
  },
};
</script>
