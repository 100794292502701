import {get, patch} from './../utils/Request'
export default {
  methods: {
    getStats(){
      return get("/stats")
      .then((response) => response.json());
    },
    showStat(id){
      return get("/satats/"+id)
      .then((response) => response.json());
    },
    updateRequest(id, body ={}) {
      return patch("/stats/"+id, body)
      .then((response) => {

        return response
      })
    }
  },
}