<template>
 <h3 class="col-12 mx-0 px-0 my-2">{{contract_name}}</h3>
 <hr class="col-12 mx-0 py-0 px-0 my-0">
 <p class="col-12 mx-0 px-0 my-2">Custo para o Nv.2:
    <input
      autocomplete="false"
      type="numeric"
      class="mx-0"
      v-model="contract_merchant.level_2_cost"
      :id="contract_field+'_'+contract_type+'_level_2_cost'"
    />
  </p>
  <p class="col-12 mx-0 px-0 my-2">Custo para o Nv.3:
    <input
      autocomplete="false"
      type="numeric"
      class="mx-0"
      v-model="contract_merchant.level_3_cost"
      :id="contract_field+'_'+contract_type+'_level_3_cost'"
    />
  </p>
  <p class="col-12 mx-0 px-0 my-2">Custo para o Nv.4:
    <input
      autocomplete="false"
      type="numeric"
      class="mx-0"
      v-model="contract_merchant.level_4_cost"
      :id="contract_field+'_'+contract_type+'_level_4_cost'"
    />
  </p>
  <p class="col-12 mx-0 px-0 my-2">Custo para o Nv.5:
    <input
      autocomplete="false"
      type="numeric"
      class="mx-0"
      v-model="contract_merchant.level_5_cost"
      :id="contract_field+'_'+contract_type+'_level_5_cost'"
    />
  </p>
  <hr class="col-12 mx-0 py-0 px-0 my-0">

 <div class="col-12 mx-0 px-0 py-0 row justify-content-center" v-for="contract_type in contract_types()" :key="contract_type" >
  <h4 class="col-12 mx-0 px-0 my-2" v-if="isLevelAttribute(contract_type)">{{contract_merchant_p[contract_type].name}}</h4>
  <h5 class="col-12 mx-0 px-0 my-2" v-if="isLevelAttribute(contract_type)">Itens: {{contract_merchant_p[contract_type].description}}</h5>


  <p class="col-12 mx-0 px-0 my-2" v-if="isLevelAttribute(contract_type)">Preço Base da Unidade:
    <input
      autocomplete="false"
      type="numeric"
      class="mx-0"
      v-model="contract_merchant[contract_type].base_cost"
      :id="contract_field+'_'+contract_type+'_base_cost'"
    />
  </p>

  <table class="table-dark table-bordered table-hover col-10 dark-box mx-0 px-0" v-if="isLevelAttribute(contract_type)">
    <thead>
      <tr>
        <th scope="col">Level</th>
        <th scope="col">Quantidade</th>
        <th scope="col">Multiplicador de Preço</th>
        <th scope="col">Preço Final</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="level in [1, 2, 3, 4, 5]" :key="'level_'+level">

        <th scope="row"> {{level}} </th>
        <td scope="col">
          <div class="mx-0 px-0 py-0 my-0 justify-content-left text-left">
            <input
              autocomplete="false"
              type="numeric"
              class="mx-0"
              v-model="contract_merchant[contract_type]['level_'+level].quantity"
              :id="contract_field+'_'+contract_type+'_level_'+level+'_quantity'"
            />
          </div>
         </td>
        <td scope="col">
          <div class="mx-0 px-0 py-0 my-0 justify-content-left text-left">
            <input
              autocomplete="false"
              type="numeric"
              class="mx-0"
              v-model="contract_merchant[contract_type]['level_'+level].cost_multiplier"
              :id="contract_field+'_'+contract_type+'_level_'+level+'_cost_multiplier'"
            />
          </div>
        </td>
        <td scope="col"> {{(contract_merchant_p[contract_type].base_cost*contract_merchant_p[contract_type]['level_'+level].cost_multiplier*contract_merchant_p[contract_type]['level_'+level].quantity).toFixed(2)}} </td>

      </tr>

    </tbody>
  </table>
  <hr class="col-12 mx-0 px-0" v-if="isLevelAttribute(contract_type)">
 </div>

  <hr class="col-12 mx-0 py-0 px-0 my-0">
  <button v-on:click="updateGameConfig()" class="col-7 mx-0 px-0 my-3 py-1 minebutton">
    Salvar Configurações
  </button>
  <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>
      </transition-group>

    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>
      </transition-group>
    </div>
</template>
<script>
import GameConfigMixin from '../../mixins/GameConfigMixin'

export default {
  name: "ContractMerchantCard",
  data() {
    return {
      contract_merchant: {},
      updateErrors: [],
      updateMessages: [],
      contractMerchantAttributes: this.contract_merchant,
    };
  },
  props: {
    contract_id: String,
    contract_field: String,
    contract_merchant_p: Object,
  },
  mixins: [GameConfigMixin],
  beforeMount(){
    this.contract_merchant = this.contract_merchant_p
  },
  computed:
  {
    contract_name(){
      console.log(this.contract_field)
      if (this.contract_field === "lumberjack_contract") return "Contrato de Lenhador"
      if (this.contract_field === "blacksmith_contract") return "Contrato de Ferreiro"
      if (this.contract_field === "miner_contract") return "Contrato de Minerador"
      if (this.contract_field === "farmer_contract") return "Contrato de Fazendeiro"
      return "Ponto de Contrato"
    }
  },
  methods: {
    isLevelAttribute(contract_type){
      return !contract_type.includes("_cost");
    },
    levelK(level){
      return 'level_'+level;
    },
    contract_types(){
      if (!this.contract_merchant) return [];
      return Object.keys(this.contract_merchant);
    },
    updateGameConfig(){
      this.updateConfigField(this.contract_id, {config_field: this.contract_field, config_body: this.contract_merchant})
      .then(data =>{
        if (data){
          this.updateMessages.push("Ponto de Contrato atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar este Ponto de Contrato.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
};
</script>
