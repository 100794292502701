<template>
  <div
    class="main-content shopbg row"
    style="background-color: rgba(169, 169, 169, 0.1); margin: 0%; width: 100%; height: 100%;"
  >
    <confirm-modal
      v-if="seeConfirmModal"
      :product="selectedProduct"
      @close="closeConfirmModal()"
      @go-to-cash-section="openCashSection()"
    ></confirm-modal>
    <div class="shopwindow container col-12">
      <div class="row justify-content-center">
        <div class="shopinfo col-12">
          <h2
            style="margin: 0px auto 0px 0%; position: relative; display: block; width: 100%;"
          >
            Loja Mágica {{ scrollPosition }}
          </h2>
          <div class="row col-12 mx-0 px-0">
            <hr
              style="margin-bottom: 0px; border-bottom: 0px"
              class="col-12 mx-0 px-0"
            />
            <shop-profile></shop-profile>

            <div class="col-12 mx-0 px-0 row shopborder">
              <div class="row col-12 mx-0 px-0 justify-content-center">
                <button
                  class="categorybutton col-lg-2 col-4"
                  v-on:click="changeCategory('Planos VIP', 'vip')"
                >
                  VIP
                </button>
                <button
                  class="categorybutton col-lg-2 col-4"
                  v-on:click="changeCategory('Itens Mágicos', 'especiais')"
                >
                  Especiais
                </button>
                <button
                  class="categorybutton col-lg-2 col-4"
                  v-on:click="changeCategory('Pets', 'pets')"
                >
                  Pets
                </button>
                <button
                  class="categorybutton col-lg-2 col-4"
                  v-on:click="changeCategory('Montarias', 'montarias')"
                >
                  Montarias
                </button>
                <button
                  class="categorybutton col-lg-2 col-4"
                  v-on:click="changeCategory('Cosméticos', 'cosmeticos')"
                >
                  Cosméticos
                </button>
              </div>
            </div>

            <div class="col-12 row mx-0 px-0 justify-content-center shopborder">
              <div v-if="purchaseCompleted" class="buyWarning col-8">

                <ul>
                  <li v-for="message in purchaseMessages" :key="message" v-html="message">
                  </li>
                </ul>
              </div>

              <div v-if="purchaseFailed" id="buyWarning" class="buyError col-8">

                <ul>
                  <li v-for="message in purchaseErrors" :key="message">
                    {{message}}
                  </li>
                </ul>
              </div>

              <div
                class="row col-12 mx-0 px-0 justify-content-center"
                id="loadIcon1"
                style="display: none"
              >
              </div>
              <div id="shopTab" class="row col-12 mx-0 px-0">
                <transition name="slide-fade" mode="out-in">
                  <component
                    :is="shopComponent"
                    :title="categoryName"
                    :category="category"
                    @open-confirm-modal="openConfirmModal"
                  ></component>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShopProfile from "@/components/shop/ShopProfile.vue";
import ShopCategory from "@/components/shop/ShopCategory.vue";
import ShopVipCategory from "@/components/shop/ShopVipCategory.vue";

import Inventory from "@/components/shop/Inventory.vue"
import RedeemCode from "@/components/shop/RedeemCode.vue"

import CashSection from "@/components/shop/CashSection.vue";
import ConfirmModal from "../components/shop/ConfirmModal.vue";
import {post} from "./../utils/Request"
import Product from './../mixins/ProductMixin'
import Attachment from './../mixins/AttachmentMixin'
import Wallet from './../mixins/WalletMixin'

export default {
  name: "Shop",
  data() {
    return {
      wallet: {},
      categoryName: "Planos VIP",
      category: "vip",
      shopComponent: "ShopVipCategory",
      selectedProduct: {},
      seeConfirmModal: false,
      purchaseFailed: false,
      purchaseCompleted: false,
      purchaseErrors: [],
      purchaseMessages: [],
    };
  },
  computed:{
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  watch:{
    isAuthenticated () {
      if (this.isAuthenticated) {
        this.$parent.updateWallet()
      }
    }
  },
  methods: {
    updateWallet(){
      if (this.isAuthenticated) {
        this.getUserWallet()
          .then((response) => response.json())
          .then((data) => {
            this.wallet = data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    buyItem(product){
      window.scrollTo({ top: 85, behavior: 'smooth' });

      post("/products/"+product.id+"/buy", {product_id: product.id, user_id: this.user.attributes.id, quantity: 1})
      .then((response) => {
        if (response.ok){
          this.purchaseFailed = false
          this.purchaseCompleted = true;
          this.closeConfirmModal();
          this.updateWallet()
          this.purchaseMessages.push("Você comprou "+this.filterColor("span", product.customname)+"! Cheque seu inventário.")
        } else {
          this.purchaseCompleted = false
          this.purchaseFailed = true;
          this.closeConfirmModal();
          this.purchaseErrors.push("Houve um erro durante a tentativa de compra. Tente novamente em alguns segundos.")
        }
        return response.json()
      })
    },
    changeCategory(title, category) {

      this.shopComponent = "ShopCategory";
      if (category == "vip") {
        this.shopComponent = "ShopVipCategory";
      }
      this.categoryName = title;
      this.category = category;
    },

    changeToInventory() {
      this.shopComponent = "Inventory";
    },
    changeToRedeemCode() {
      this.shopComponent = "RedeemCode";
    },
    openCashSection() {
      this.shopComponent = "CashSection";
      this.closeConfirmModal();
    },
    openConfirmModal(product) {
      this.selectedProduct = product;
      this.seeConfirmModal = true;
    },
    closeConfirmModal() {
      this.seeConfirmModal = false;
    },
  },
  mixins: [Product, Attachment, Wallet],
  components: {
    ShopProfile,
    ShopCategory,
    ShopVipCategory,
    CashSection,
    ConfirmModal,
    Inventory,
    RedeemCode,
  },
  beforeMount(){
    this.updateWallet()
  },
};
</script>
<style scoped>
.modal {
  display: block;
}
.buyError{
  border: 1px solid lightcoral;
  background-color: rgba(240, 128, 128, 0.377);

}
</style>
