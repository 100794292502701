<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">

    <h4 class="col-12 text-center mx-0">{{ loot.name }}</h4>

    <hr class="col-12 mx-0 px-0" />
    
    {{ loot.observation }}

    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="goToEditPage(this.loot.id)">Editar</button>
    </div>
    
    <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>         
      </transition-group>
        
    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>         
      </transition-group>
    </div>
  </div>
</template>
<script>
import Loot from '../../mixins/LootMixin'
export default {
  name: "LootCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      lootAttributes: this.loot,
      observation: undefined,
      name: undefined,
    };
  },
  props: {
    loot: Object,
  },
  mixins: [Loot],
  beforeMount(){
    this.name = this.loot.name
    this.observation = this.loot.observation
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    }
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    goToEditPage(loot_id) {
      if (this.isAdmin()) {
        this.$router.push("/loots/editar-loot/" + loot_id)
      }
    },
    destroyLoot(){
      this.destroyRequest(this.loot.id)
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Loot removido com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
            this.$parent.loadLoots();
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar remove este Loot.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
};
</script>
