<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        Lista de Items
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>

    <div
      class="col-12 align-content-start mt-0 mb-0 py-0 mx-0 px-0 row justify-content-center align-content-start shopborder"
      style="max-height: 100px"
    >
      <h4 class="col-12 text-center mb-4">Filtrar por categoria:</h4>
      <div class="row col-12 mx-0 px-0 justify-content-center align-content-start">
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_filter('all')"
        >
          Todos
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4" v-for="item_category in item_categories" :key="item_category.id"
          v-on:click="change_filter(item_category.id)"
        >
          {{item_category.name}}
        </div>
      </div>
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <div class="col-12 text-center justify-self-center justify-content-center row mx-0 px-0 my-0 py-0" style="border: 1px black;">
         <p class="text-center col-7 mx-0 px-0"> - Use /c loot additem [Nome] para adicionar itens nessa lista </p>
         <p class="text-center col-7 mx-0 px-0"> - O valor de 1 Fragmento de Esmeralda é 0.1 esmeraldas</p>
      </div>

      <input class="col-lg-4 col-7 mx-2 px-2 mt-3 mb-0 py-2 simpleborder-1" type="text" v-model="search_result" placeholder="Procurar por nome..." />

      <div v-if="isAdmin() && is_loading_items" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>

      <div  v-if="isAdmin() && !is_loading_items" class="staff-team row col-12 mx-0 px-0 justify-content-around">
        <template v-for="item in items"
            :key="item.id">
          <div
            v-if="onSearchResult(item.name)"
            class="staticcard col-lg-2 my-3 mx-3 px-0 row align-content-start py-5 justify-content-center"
          >
            <ItemCard v-if="loadedItem" :item_p="item" @reload-items="loadItems()"></ItemCard>
          </div>
        </template>


        <div v-if="items.length == 0" class="col-7">
          <h4>Sem Itens registrados no momento...</h4>
        </div>
      </div>

      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>

    </div>
  </div>
</template>

<script>
import Item from "./../../mixins/ItemMixin";
import ItemCategory from "./../../mixins/ItemCategoryMixin";

import { directive } from "vue-tippy";
import ItemCard from "@/components/items/ItemCard";

export default {
  name: "ItemManageTable",
  props: {},
  components: {
    ItemCard,
  },
  data() {
    return {
      item_list: [],
      item_categories: [],
      editErrors: [],
      editMessages: [],
      is_loading_items: true,
      loadedItem: false,
      filter_selected: "all",
      old_filter_selected: "all",
      search_result: null
    };
  },
  computed: {
    items() {
      return this.item_list;
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    loadItemCategories() {
      this.getItemCategories().then((categories) => {
        this.item_categories = categories;
      });
    },
    loadItems() {
      this.is_loading_items = true;
      this.getItems().then((items) => {
        this.item_list = this.items.concat(items);
        this.loadedItem = true;
        this.is_loading_items = false;
      });
    },
    loadFilteredItems() {
      this.old_filter_selected = this.filter_selected;
      this.is_loading_items = true;
      this.getFilteredItems(this.filter_selected).then((items) => {
        this.item_list = this.items.concat(items);
        this.is_loading_items = false;
      });
    },
    change_filter(filter) {
      this.filter_selected = filter;

      this.item_list = [];
      if (filter == "all"){
        this.loadItems();
      } else {
        this.loadFilteredItems();
      }
    },
    onSearchResult(name){
      if (this.search_result && this.search_result !== ""){
        return name.toLowerCase().includes(this.search_result.toLowerCase())
      }
      return true;
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [Item, ItemCategory],
  beforeMount() {
    this.loadItemCategories();
    this.loadItems();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin,
.programmer,
.lorer,
.builder {
  cursor: pointer;
}
</style>
