import { get, post } from "./../utils/Request";
import Cookie from "js-cookie";
import _ from 'lodash'

export default {
  methods: {
    hasShopDiscount(user_id){
      return get("/users/"+user_id+"/has_shop_discount")
      .then((response) => response.json());
    },
    hasBetaAccess(user_id){
      return get("/products/has-beta-access?user_id="+user_id)
      .then((response) => response.json());
    },
    getFromCategory(category) {
      return get("/products/find-by-category", {
        category,
      }).then((response) => response.json());
    },
    retrieveBetaLimit() {
      return get("/products/beta-tester-limit")
      .then((response) => response.json());
    },
    getAllProducts() {
      return get("/products").then((response) => response.json());
    },
    buy(product) {
      return post("/products/" + product.id + "/buy", {
        user_id: JSON.parse(Cookie.get("user")).attributes.id,
      }).then((response) => response.json());
    },

    filterColor(tag, string) {
      if (string == undefined) return undefined
      if (string.includes("§0")) {
        string = string.replace(/§0/gi, "<"+tag+" class='black'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§1")) {
        string = string.replace(/§1/gi, "<"+tag+" class='blue'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§2")) {
        string = string.replace(/§2/gi, "<"+tag+" class='darkgreen'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§3")) {
        string = string.replace(/§3/gi, "<"+tag+" class='darkaqua'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§4")) {
        string = string.replace(/§4/gi, "<"+tag+" class='darkred'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§5")) {
        string = string.replace(/§5/gi, "<"+tag+" class='darkpurple'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§6")) {
        string = string.replace(/§6/gi, "<"+tag+" class='gold'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§7")) {
        string = string.replace(/§7/gi, "<"+tag+" class='gray'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§8")) {
        string = string.replace(/§8/gi, "<"+tag+" class='darkgray'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§9")) {
        string = string.replace(/§9/gi, "<"+tag+" class='lightblue'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§a")) {
        string = string.replace(/§a/gi, "<"+tag+" class='green'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§b")) {
        string = string.replace(/§b/gi, "<"+tag+" class='aqua'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§c")) {
        string = string.replace(/§c/gi, "<"+tag+" class='red'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§d")) {
        string = string.replace(/§d/gi, "<"+tag+" class='lightpurple'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§e")) {
        string = string.replace(/§e/gi, "<"+tag+" class='yellow'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§o")) {
        string = string.replace(/§o/gi, "<"+tag+" class='italic'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§l")) {
        string = string.replace(/§l/gi, "<"+tag+" class='bold'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§r")) {
        string = string.replace(/§r/gi, "<"+tag+" class='resetColor'>");
        string = string + "</"+tag+">";
      }
      if (string.includes("§f")) {
        string = string.replace(/§f/gi, "<"+tag+" class='white'>");
        string = string + "</"+tag+">";
      }
      return string;
    },
    getProductQualityClass(loretext) {
      let lore = loretext.split(";");
      let cardclass = "common";
      _.each(lore, (line) => {
        if (line.includes("VIP Básico")) {
          cardclass = "common";
        }
        if (line.includes("VIP Intermediário")) {
          cardclass = "rare";
        }
        if (line.includes("VIP Plus")) {
          cardclass = "mitic";
        }
        if (line.includes("Item Mágico")) {
          cardclass = "rare";
        }
        if (line.includes("Qualidade")) {
          if (line.includes("Comum")) cardclass = "common";
          if (line.includes("Raro")) cardclass = "rare";
          if (line.includes("Mítico")) cardclass = "mitic";
          if (line.includes("Lendário")) cardclass = "legendary";
        }
      });
      return cardclass;
    },
    getProductQuality(loretext) {
      let lore = loretext.split(";");
      let quality = "Comum"
      _.each(lore, (line) => {
        if (line.includes("Qualidade")) {
          if (line.includes("Comum")) quality = "Comum";
          if (line.includes("Raro")) quality = "Raro";
          if (line.includes("Mítico")) quality = "Mítico";
          if (line.includes("Lendário")) quality = "Lendário";
        }
        if (line.includes("Item Mágico")) {
          quality = "Item Mágico";
        }
        if (line.includes("VIP")) {
          quality = "Especial";
        }
      });
      return quality;
    },
    splitLore(string) {
      if (string == undefined) return undefined;
      string = string.replace(/;/gi, "<br>");
      return this.filterColor("span", string);
    },
  },
};
