<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h4 class="col-12 text-center mx-0">{{ stat.stat_type }}</h4>
   
    <hr class="col-12 mx-0 px-0" />

    <h5 class="col-12 mx-0 px-0 text-center">Valores</h5>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Atributo Base</th>
              <th scope="col">Runa Pequena</th>
              <th scope="col">Runa Média</th>
              <th scope="col">Runa Grande</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="base_value"
                    
                  />
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="small_rune_value"
                  />
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="medium_rune_value"
                  />
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-5"
                    v-model="large_rune_value"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateStat()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>         
        </transition-group>
        
      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>         
        </transition-group>
        
      </div>
  </div>
</template>
<script>
import Stat from '../../mixins/StatMixin'
export default {
  name: "StatCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      statAttributes: this.stat,
      base_value: undefined,
      small_rune_value: undefined,
      medium_rune_value: undefined,
      large_rune_value: undefined,
    };
  },
  props: {
    stat: Object,
  },
  mixins: [Stat],
  beforeMount(){
    this.base_value = this.stat.base
    this.small_rune_value = this.stat.small_rune
    this.medium_rune_value = this.stat.medium_rune
    this.large_rune_value = this.stat.large_rune
  },
  methods: {

    updateStat(){
      this.updateRequest(this.stat.id, {base: this.base_value, small_rune: this.small_rune_value, medium_rune: this.medium_rune_value, large_rune: this.large_rune_value})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Stats atualizada com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar os valores desse Stat.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
};
</script>
