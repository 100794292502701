<template>
  <div class="classmodalbody">
    <span class="close" v-on:click="$parent.dynamicSelect('about')"
      >&times;</span
    >

    <div style="clear: right"></div>

    <div class="classtitle">
      <h1 style="margin: 0px">Mago</h1>
      <hr />
      <h2 style="margin: 0px; color:lightblue">O Conjurador Elemental</h2>
    </div>
    <div class="row justify-content-center">
      <div class="charpic col-8 col-md-3" style="height: min-content">
        <img src="/img/magechar.png" style="width: 100%;" alt="" />
      </div>

      <div class="col-12 col-md-4">
        Ativas:
        <hr style="width: 50%; margin-left: 0px" />

        <legend>Conjunto de Habilidades 1:</legend>
        <ul>
          <li
            v-for="skill in skills"
            :key="skill"
            :class="{
              skillArrow: !skill.isArrowFlipped,
              skillArrowFlipped: skill.isArrowFlipped,
            }"
            v-on:click="openTab(skill.skillNumber)"
          >
            <div>
              <h4 class="skillTitle">{{ skill.skillName }}</h4>

              <div
                v-if="skill.isSkillBoxOn"
                :id="'skill' + skill.skillNumber"
                :ref="'skill' + skill.skillNumber"
                :class="{ skillBoxOn: skill.isSkillBoxOn }"
                style="display: none; text-align: left;"
              >
                <p style="text-align: center">Descrição da Habilidade:</p>
                <div
                  style="text-align: center"
                  class="row justify-content-center"
                ></div>
                <hr style="width: 92.5%" />

                <span v-html="skill.description"></span>
                <hr style="width: 92.5%" />
                <div v-if="skill.howToUse">
                  Como usar:
                  <span v-html="skill.howToUse"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <legend>Conjunto de Habilidades 2:</legend>
        <ul>
          <li
            v-for="skill in skills2"
            :key="skill"
            :class="{
              skillArrow: !skill.isArrowFlipped,
              skillArrowFlipped: skill.isArrowFlipped,
            }"
            v-on:click="openTab(skill.skillNumber)"
          >
            <div>
              <h4 class="skillTitle">{{ skill.skillName }}</h4>

              <div
                v-if="skill.isSkillBoxOn"
                :id="'skill' + skill.skillNumber"
                :ref="'skill' + skill.skillNumber"
                :class="{ skillBoxOn: skill.isSkillBoxOn }"
                style="display: none; text-align: left;"
              >
                <p style="text-align: center">Descrição da Habilidade:</p>
                <div
                  style="text-align: center"
                  class="row justify-content-center"
                ></div>
                <hr style="width: 92.5%" />

                <span v-html="skill.description"></span>
                <hr style="width: 92.5%" />
                <div v-if="skill.howToUse">
                  Como usar:
                  <span v-html="skill.howToUse"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col-12 col-md-4">
        Passivas:
        <hr style="width: 50%; margin-left: 0px" />
        <ul>
          <li>Sabedoria Ancestral</li>
          <li>Proficiência Arcana</li>
          <li>Controle Dinâmico</li>
          <li>Entendimento dos Alfarrábios</li>
          <li>Desencanto</li>
          <li>Pergaminhos</li>
        </ul>
      </div>

      <div style="clear: left;"></div>

      <div>
        <div>
          <hr />
          <p>
            "Assustadoramente inteligentes e munidos conhecimentos arcanos
            ancestrais, usam energia pura para suprimir seus adversários!"<br />
          </p>
          <p>
            Em constante harmônia com a natureza, os Magos são permitidos
            manipular Ar, Terra, Água, Fogo e Espirito para conjurar
            habilidades.<br />
          </p>
          <hr />
        </div>
        <div>
          <h2 style="text-align: center; margin-top: 0px">Combos</h2>
          <p>
            As Habilidades Ativas são executadas através de combos! <br />
            Abaixo estarão os combos necessários para ativar as habilidades do
            Alquimista
          </p>
          <div class="combosubtitle">
            <h4>Legenda:</h4>

            <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> : Olhando
            para cima / <i class="fas fa-arrow-down"> </i
            ><i class="fas fa-eye"></i> : Olhando para baixo /
            <i
              class="fas fa-step-backward"
              style="transform: rotate(270deg)"
            ></i>
            : Shift <br />
            <i class="fas fa-running runact"></i> : Correndo /
            <i class="fas fa-mouse leftclick"></i> : Clique Esquerdo /
            <i class="fas fa-mouse rightclick"></i> : Clique Direito
          </div>
          <br />
          <fieldset>
            <legend style="text-align: center">
              Conjunto de Habilidades 1:
            </legend>
            <div class="row justify-content-center">
              <div class="combosubtitle" style="float: left">
                Projetil de Terra: <br />
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
                +
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
              </div>
              <div class="combosubtitle" style="float: left">
                Benção de Água: <br />
                <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
                <i class="fas fa-mouse leftclick"></i> +
                <i class="fas fa-mouse rightclick"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                Tornado: <br />
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
                + <i class="fas fa-mouse rightclick"></i> +
                <i class="fas fa-mouse rightclick"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                Rosáceas Elementais: <br />
                <i class="fas fa-running runact"></i> +
                <i class="fas fa-mouse rightclick"></i> +
                <i class="fas fa-mouse rightclick"></i>
              </div>

              <div style="clear: left;"></div>
            </div>
          </fieldset>

          <fieldset>
            <legend style="text-align: center">
              Conjunto de Habilidades 2:
            </legend>
            <div class="row justify-content-center">
              <div class="combosubtitle" style="float: left">
                Armadilha Elemental: <br />
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
                +
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
              </div>
              <div class="combosubtitle" style="float: left">
                Colunas Infernais: <br />
                <i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> +
                <i class="fas fa-mouse leftclick"></i> +
                <i class="fas fa-mouse rightclick"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                Meteorito: <br />
                <i
                  class="fas fa-step-backward"
                  style="transform: rotate(270deg)"
                ></i>
                + <i class="fas fa-mouse rightclick"></i> +
                <i class="fas fa-mouse rightclick"></i>
              </div>
              <div class="combosubtitle" style="float: left">
                Impulso: <br />
                <i class="fas fa-running runact"></i> +
                <i class="fas fa-mouse rightclick"></i> +
                <i class="fas fa-mouse rightclick"></i>
              </div>

              <div style="clear: left;"></div>
            </div>
          </fieldset>
        </div>
        <div style="clear: left;"></div>
        <div style="clear: right;"></div>

        <br />
        <hr />
        <div>
          <h2 style="text-align: center">Video:</h2>

          <iframe
            style="margin-left: 15%; padding:0px;"
            class="wikiMainInfo"
            width="70%"
            height="415"
            src="https://www.youtube.com/embed/t9qnIrv7IIA"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Mage",
  data() {
    return {
      skills: [
        {
          skillNumber: 1,
          skillName: "Projetil de Terra",
          description: `Conjura um projétil de terra e o arremessa em direção ao alvo.`,
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>`,
        },
        {
          skillNumber: 2,
          skillName: "Benção de Água",
          description: "Cura e revigora o corpo de aliados próximos.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 3,
          skillName: "Tornado",
          description:
            "Conjura um tornado que prende no ar por alguns segundos os inimigos proximos.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 4,
          skillName: "Rosáceas Elementais",
          description:
            "Conjura todos os elementos em sua forma perfeita, projetando-os de forma devastadora.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-running runact"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
      ],
      skills2: [
        {
          skillNumber: 5,
          skillName: "Armadilha Elemental",
          description:
            "Arremessa um emeranhado de teias com o ar para prender o alvo.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-step-backward" style="transform: rotate(270deg)"></i>`,
        },
        {
          skillNumber: 6,
          skillName: "Colunas Infernais",
          description:
            "Conjura colunas de fogo direto em inimigos ao redor do Mago.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-arrow-up"> </i><i class="fas fa-eye"></i> + <i class="fas fa-mouse leftclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 7,
          skillName: "Meteorito",
          description: "Conjura um meteorito direto em cima da cabeça do alvo.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-step-backward" style="transform: rotate(270deg)"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
        {
          skillNumber: 8,
          skillName: "Impulso",
          description:
            "Conjura todos os elementos e usa essa energia para se projetar para frente e levitar por alguns segundos.",
          isSkillBoxOn: false,
          isArrowFlipped: false,
          howToUse: `<i class="fas fa-running runact"></i> + <i class="fas fa-mouse rightclick"></i> + <i class="fas fa-mouse rightclick"></i>`,
        },
      ],
    };
  },
  methods: {
    openTab(number) {
      _.each(this.skills, (skill) => {
        if (skill.skillNumber == number) {
          skill.isSkillBoxOn = !skill.isSkillBoxOn;

          skill.isArrowFlipped = !skill.isArrowFlipped;
        } else {
          if (skill.isSkillBoxOn) {
            skill.isSkillBoxOn = false;
          }
          if (skill.isArrowFlipped) {
            skill.isArrowFlipped = false;
          }
        }
      });
      _.each(this.skills2, (skill) => {
        if (skill.skillNumber == number) {
          skill.isSkillBoxOn = !skill.isSkillBoxOn;

          skill.isArrowFlipped = !skill.isArrowFlipped;
        } else {
          if (skill.isSkillBoxOn) {
            skill.isSkillBoxOn = false;
          }
          if (skill.isArrowFlipped) {
            skill.isArrowFlipped = false;
          }
        }
      });
    },
  },
};
</script>
