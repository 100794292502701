<template>
  <div class=" col-12 row mx-0 px-0 main-content ">
    <h2 class="col-12 text-center">Ver Tarefa</h2>
    <div class="staff staff-team row col-12 mx-0 px-0 justify-content-around">
      <div class="col-6 row justify-content-center">
        <h3 class="col-9 mx-0 px-0 text-center">{{ task.name }}</h3>
        <br />

        <div v-if="task.category == 'building'" class="builder col-11">
          Construção
        </div>
        <div v-else-if="task.category == 'lore'" class="lorer col-11">
          Roteiro
        </div>
        <div v-else-if="task.category == 'dev'" class="programmer col-11">
          Programação
        </div>
        <div style="font-size: 12px" class="my-2 col-9 mx-0 px-0">
          <div class="mt-0">Descrição:</div>
          <div class="textEditor col-12 mx-0 px-0">
            >
            {{ task.description }}
            <br />
          </div>
        </div>
        <div style="font-size: 12px" class="my-4 col-9 mx-0 px-0">
          Lider da Tarefa: {{ task.leader }}
        </div>
        <br />
        <hr class="mx-0 px-0 col-12" />
        <div class="col-8 mx-0 px-0 justify-content-left text-left py-0 my-0">
          <h4 class="text-center mb-5">Checklist:</h4>
          <div
            class="justify-content-center"
            v-for="checkItem in cardChecklist[0].checkItems"
            :key="checkItem.id"
          >
            <span v-if="checkItem.state == 'complete'" class="mr-3 text-left"
              ><i class="far fa-check-square"></i
            ></span>
            <span v-else class="mr-3 text-left"
              ><i class="far fa-square"></i
            ></span>
            <span class="ml-3 text-right">{{ checkItem.name }}</span>
          </div>
        </div>
        <hr class="mx-0 px-0 col-12" />

        <h4 class="mb-5 mt-2 col-12">Progresso:</h4>

        <div class="meter mfull mt-1 col-11 mx-0 px-0">
          <div
            class="meter md"
            :style="{
              width: parseInt(getProgress()) + '% !important',
            }"
          >
            <div class="skills">{{ parseInt(getProgress()) }}%</div>
          </div>
        </div>
 <hr class="mx-0 px-0 col-12" />
        <div class="col-9 justify-content-center mx-0 px-0 text-center">
          <h3 class="text-center">Membros</h3>
          <ol class="col-12 text-left mx-0 px-5">
            <div
              v-for="member in members"
              :key="member"
              class="mx-0 my-0 py-0 px-0"
            >
              <li class="mx-0 px-0 my-2 py-0">
                <img
                  class="my-0 ml-0 mr-2 px-0 py-0 rounded"
                  :src="
                    'https://minotar.net/helm/' +
                      member.nickname +
                      '/100.png'
                  "
                  style="width: 25px; height; 25px;"
                />
                <span>{{ member.nickname }} </span>
              </li>
            </div>
          </ol>
        </div>
        <hr class="mx-0 px-0 col-12" />
        <div class="col-9 justify-content-center mx-0 px-0 text-center">
          <h3 class="text-center">Voluntários</h3>
          <ol class="col-12 text-left mx-0 px-5">
            <div
              v-for="volunteer in volunteers"
              :key="volunteer"
              class="mx-0 my-0 py-0 px-0"
            >
              <li v-if="volunteer.approved" class="mx-0 px-0 my-2 py-0">
                <img
                  class="my-0 ml-0 mr-2 px-0 py-0 rounded"
                  :src="
                    'https://minotar.net/helm/' +
                      volunteer.nickname +
                      '/100.png'
                  "
                  style="width: 25px; height; 25px;"
                />
                <span>{{ volunteer.nickname }} </span>

                <i v-if="isAuthenticated && user.attributes.admin"
                  class="fas fa-trash-alt fa-2x mx-5 clickable-icon"
                  v-on:click="denyVolunteer(task.id, volunteer.id, true, volunteer.discord_id)"
                  v-tippy="{ content: 'Remover voluntário' }"
                  style="color: darkred"
                ></i>
              </li>
            </div>
          </ol>
        </div>
        <hr class="mx-0 px-0 col-12" />
        <div
          v-if="isAuthenticated && user.attributes.admin"
          class="col-9 justify-content-center mx-0 px-0 text-center"
        >
          <h3 class="text-center">Voluntários Pendentes</h3>
          <ol class="col-12 text-left mx-0 px-5">
            <div
              v-for="volunteer in volunteers"
              :key="volunteer"
              class="mx-0 my-0 py-0 px-0"
            >
              <li v-if="!volunteer.approved" class="mx-0 px-0 my-2 py-0">
                <img
                  class="my-0 ml-0 mr-2 px-0 py-0 rounded"
                  :src="
                    'https://minotar.net/helm/' +
                      volunteer.nickname +
                      '/100.png'
                  "
                  style="width: 25px; height; 25px;"
                />
                <span>{{ volunteer.nickname }} </span>
                <i
                  class="fas fa-check fa-2x mx-5 clickable-icon"
                  v-on:click="acceptVolunteer(task.id, volunteer.id, volunteer.discord_id, task.name)"
                  v-tippy="{ content: 'Aceitar' }"
                  style="color: darkgreen"
                ></i>
                <i
                  class="fas fa-trash-alt fa-2x mx-5 clickable-icon"
                  v-on:click="denyVolunteer(task.id, volunteer.id)"
                  v-tippy="{ content: 'Negar' }"
                  style="color: darkred"
                ></i>
              </li>
            </div>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post, destroy } from "../../utils/Request";
import Task from "../../mixins/TaskMixin";
import _ from "lodash";
export default {
  name: "Show",
  data() {
    return {
      cardChecklist: "",
      task: {},
      pending_volunteers: {},
      bot_api_url: process.env.VUE_APP_BOT_API_URL,
    };
  },
  mixins: [Task],
  methods: {
    getCardChecklist() {
      get(
        "https://api.trello.com/1/cards/" +
          this.task.trello_card +
          "/checklists?key=461775ab1ea8a50c527472a368629c14&token=891ec56ec9f94c58fc10b810c906dc32b121da97107992e5fbb2e738964eb593"
      )
        .then((response) => response.json())
        .then((data) => {
          this.cardChecklist = data;
        });
    },
    getProgress() {
      let completedCount = 0;
      let incompletedCount = 0;

      _.each(this.checkItems, (item) => {
        if (item.state == "complete") completedCount++;
        else incompletedCount++;
      });

      let total = completedCount + incompletedCount;

      return (completedCount / total) * 100;
    },
    denyVolunteer(task_id, user_id, is_removing = false, discord_id=null) {

      this.denyTaskVolunteer(task_id, user_id).then(() => {
        if (is_removing){
          destroy(this.bot_api_url+"/role", {
            userId: discord_id,
            taskName: this.task.name,
          })
        }
        this.getTask();
      });
    },
    acceptVolunteer(task_id, user_id, discord_id, task_title) {
      this.acceptTaskVolunteer(task_id, user_id)
        .then((response) => response.json())
        post(this.bot_api_url+"/role", {
          userId: discord_id,
          taskName: task_title
        })
        
        this.getTask();

    },
    getTask() {
      this.showTask(this.$route.params.id).then((data) => {
        this.task = data[0];
        this.getCardChecklist();
      });
    },
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
    volunteers() {
      if (this.task != {}) return this.task.volunteers;
      else return {};
    },
    members() {
      if (this.task != {}) return this.task.members;
      else return {};
    },
    checkItems() {
      return this.cardChecklist[0].checkItems;
    },
  },
  beforeMount() {
    this.getTask();
  },
};
</script>
<style scoped>
  a, a:hover, a:focus, a:visited, a:link {
    text-decoration: none;
    color: inherit;
  }
  .ma {width: 92.3%; background-image: linear-gradient(to right, rgb(247, 224, 188) , rgb(255, 153, 0));}
  .mb {width: 50%; background-image: linear-gradient(to right, #96b696 , #00b100);}
  .mc {width: 48%; background-image: linear-gradient(to right, #acc7f1 , #0663f8);}
  .md {width: 79%; background-image: linear-gradient(to right, #75505c , #57001d);}
  .me {width: 100%; background-image: linear-gradient(to right, #456360 , #004e46);}
</style>

