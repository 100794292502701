<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 mb-5 justify-content-center mx-0">
      <h2 class="col-7 mx-0 px-0 mt-3 pb-0 text-center">
        Dashboard de Admnistração
      </h2>
      <hr class="col-7 mx-0 px-0 my-0" />
      <br />

      <div class="container row col-12 justify-content-around mx-0 mt-5 px-0">
        <div
          class="lastposts col-lg-3 row col-11 mx-0 px-0 justify-content-center align-content-start"
        >
          <div class="lpctitle col-12 mx-0 px-0 mt-5 text-center" id="postPart">
            Visualizar:
          </div>
          <hr class="col-12 mx-0 px-0" />
          <button
            style="border:none;"
            class="lpc minebutton col-12 mx-0 px-0 my-0"
          >
            VIPs
          </button>
          <hr class="col-12 mx-0 px-0" />
        </div>
        <div
          id="postvp"
          class="post col-lg-8 col-11 row mx-auto px-0 my-3 justify-content-center align-content-start"
        >
          <VipDashboard/>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import VipDashboard from "@/components/dashboard/VipDashboard.vue";

export default {
  name: "Dashboard",
  data() {
    return {

      isLoading: true,
      selected_menu: undefined,

    };
  },
  components: {
    VipDashboard
  },

  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },

    loadAll() {

    },
  },
};
</script>
