<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        Changelog de Habilidades
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>
    <div
      class="col-12 align-content-start mt-0 mb-0 py-0 mx-0 px-0 row justify-content-center align-content-start shopborder"
      style="max-height: 100px"
    >
      <h4 class="col-12 text-center mb-4">Filtrar por classe:</h4>
      <div class="row col-12 mx-0 px-0 justify-content-center align-content-start">
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('all')"
        >
          Todas
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Lad')"
        >
          Ladino
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Alq')"
        >
          Alquimista
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Fer')"
        >
          Ferreiro
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Faz')"
        >
          Fazendeiro
        </div>
         <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Len')"
        >
          Lenhador
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Min')"
        >
          Minerador
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Eng')"
        >
          Engenheiro
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Pal')"
        >
          Paladino
        </div>
        <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Cav')"
        >
          Cavaleiro da Morte
        </div>
         <div
          class="minebutton px-5 col-lg-2 col-4"
          v-on:click="change_class('Mag')"
        >
          Mago
        </div>
      </div>
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>
      <div v-if="isAdmin() && is_loading_skill_logs" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>
      <div  v-if="isAdmin() && !is_loading_skill_logs" class="staff-team row col-12 mx-0 px-0 justify-content-around">

        <div
          v-for="skill_log in skill_logs"
          :key="skill_log.id"
          class="staticcard col-lg-3 col-md-4 col-sm-5 my-2 mx-2 px-0 row align-content-start py-5 justify-content-center"
        >
          <SkillLogCard :skill_log="skill_log" @reload-skill_logs="loadSkillLogs()" v-if="!is_loading_skill_logs"></SkillLogCard>
        </div>

        <div v-if="skill_logs.length == 0" class="col-7">
          <h4>Sem skill_logs registradas no momento...</h4>
        </div>
      </div>
      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>
    </div>
  </div>
</template>

<script>
import SkillLogMixin from "./../../mixins/SkillLogMixin";
import SkillNameMixin from "./../../mixins/SkillNameMixin";
import PerkNameMixin from "./../../mixins/PerkNameMixin";

import { directive } from "vue-tippy";
import SkillLogCard from "@/components/logs/SkillLogCard";

export default {
  name: "SkillLogBalanceTable",
  props: {},
  components: {
    SkillLogCard,
  },
  data() {
    return {
      skill_log_list: [],
      editErrors: [],
      editMessages: [],
      is_loading_skill_logs: true,
      class_selected: "all",
      old_class_selected: "all",
      perk_names: {},
      skill_names: {}
    };
  },
  computed: {
    skill_logs() {
      return this.skill_log_list;
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    change_class(skill_code_prefix) {
      this.class_selected = skill_code_prefix;
      this.skill_log_list = [];

      this.loadSkillLogs();
    },
    loadPerkNamesThenSkillLogs() {
      this.old_class_selected = this.class_selected;
      this.is_loading_skill_logs = true;
      this.getSkillNames().then((skill_names) => {
        this.skill_names = skill_names
        this.getPerkNames().then((perk_names) => {
          this.perk_names = perk_names
          this.getSkillLogs(this.class_selected).then((skill_logs) => {
            this.skill_log_list = this.skill_logs.concat(skill_logs);
            this.is_loading_skill_logs = false;
          });
        });
      });
    },
    loadSkillLogs() {
      this.old_class_selected = this.class_selected;
      this.is_loading_skill_logs = true;
      this.getSkillLogs(this.class_selected).then((skill_logs) => {
        this.skill_log_list = this.skill_logs.concat(skill_logs);
        this.is_loading_skill_logs = false;
      });
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [SkillLogMixin, SkillNameMixin, PerkNameMixin],
  beforeMount() {
    this.loadPerkNamesThenSkillLogs();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 92.3%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 50%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 48%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 79%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.staff-team > div:hover {
  cursor: pointer;
  filter: brightness(125%);
  filter: contrast(110%);
  position: relative;
  top: -5px;
}
.admin,
.programmer,
.lorer,
.builder {
  cursor: pointer;
}
</style>
