<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 justify-content-center mx-0">
      <div class="about-staff col-12 mx-0 px-0">
        <div class="abt-epr">
          <h1 class="fade">Rede Nevercraft</h1>
          <p>
            A Staff da Rede Nevercraft, desenvolvedora atual do KoM, é
            experiente, profissional, dedicada e inteligente. <br />
            Nós trabalhamos com metas e objetivos claros em prol de desenvolver
            servidores de alta qualidade para Minecraft. <br />
            Os 3 Diretores, Erick Ferreira, Izael Junior e Cleberson Saller, são
            também os principais desenvolvedores dos jogos, isto, somado a uma
            seleção de alto nivel para os cargos, garante que produzimos
            conteúdo de qualidade para vocês.
          </p>
        </div>

        <div class="prev-epr">
          <h2>Habilidades valorizadas:</h2>

          <div class="meter-container">
            <p>Gerenciamento</p>
            <div class="meter mfull" v-tippy="{content: managementTippy}">
              <div class="meter ma">
                <div class="skills">81%</div>
              </div>
            </div>
          </div>
          <div class="meter-container">
            <p>Desenvolvimento em Java</p>
            <div id="mmb" class="meter mfull" v-tippy="{content: javaTippy}">
              <div class="meter mb">
                <div class="skills">92%</div>
              </div>
            </div>
          </div>
          <div class="meter-container">
            <p>Desenvolvimento para Web</p>
            <div id="mmc" class="meter mfull" v-tippy="{content: webTippy}">
              <div class="meter mc">
                <div class="skills">87%</div>
              </div>
            </div>
          </div>
          <div class="meter-container">
            <p>Planejamento de Mecânicas de Jogo</p>
            <div class="meter mfull" v-tippy="{content: planningTippy}">
              <div class="meter md">
                <div class="skills">96%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="staff col-12 row mx-0 px-0">
      <h2 class="col-12 text-center">Nosso time</h2>
      <div class="staff-team row col-12 mx-2 justify-content-around">
        <div class="col-3">
          <h3>Dillrander</h3>
          <div class="owner">Diretor Desenvolvedor</div>
          <img
            src="https://minotar.net/helm/dillrander/100.png"
            style="width: 100px; height; 100px"
          />
        </div>

        <div class="col-3">
          <h3>Leazi</h3>
          <div class="owner">Diretor Roteirista</div>
          <img
            src="https://minotar.net/helm/leazi/100.png"
            style="width: 100px; height; 100px"
          />
        </div>

        <div class="col-3">
          <h3>Saller</h3>
          <div class="owner">Diretor Logístico</div>
          <img
            src="https://minotar.net/helm/Saller/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
      </div>
      <div class="staff-team row">
        <div class="col-3">
          <h3>PePastre</h3>
          <div class="admin">Administrador Balanceador</div>

          <img
            src="https://minotar.net/helm/Pastre02/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
        <div class="col-3">
          <h3>FelipeBan</h3>
          <div class="manager">Coordenador</div>

          <img
            src="https://minotar.net/helm/FelipeBan/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
        <div class="col-3">
          <h3>Portuga</h3>
          <div class="builder">Construtor</div>

          <img
            src="https://minotar.net/helm/BerZ/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
        <div class="col-3">
          <h3>Stoven</h3>
          <div class="manager">Criador de Conteúdo</div>

          <img
            src="https://minotar.net/helm/Stoven/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
        <div class="col-3">
          <h3>RaidezBr</h3>
          <div class="admin">Balanceador</div>

          <img
            src="https://minotar.net/helm/RaidezBr/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
        <div class="col-3">
          <h3>Fairexd</h3>
          <div class="admin">Balanceador</div>

          <img
            src="https://minotar.net/helm/Fairexd/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
        <div class="col-3">
          <h3>Betata</h3>
          <div class="programmer">Programador</div>

          <img
            src="https://minotar.net/helm/betata/100.png"
            style="width: 100px; height; 100px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Staff",
  data() {
    return {
      managementTippy: 'Porquê temos em nossa equipe pessoas de alta capacidade técnica e criativa.',
      javaTippy: 'Porquê usamos de conhecimentos avançados e possuimos sede de aprendizagem, produzindo conteudo eficiente e interessante.',
      webTippy: 'Porquê nos preocupamos em abrir nosso horizonte de conhecimentos para trazer servidores ainda mais comunicativos com os jogadores.',
      planningTippy: 'Porquê possuímos uma vida de experiência com ótimos jogos e um bom planejamento para tornar os nossos um destes.',

    };
  },
};
</script>
<style scoped>
  .ma {width: 81%; background-image: linear-gradient(to right, rgb(247, 224, 188) , rgb(255, 153, 0));}
  .mb {width: 92%; background-image: linear-gradient(to right, #96b696 , #00b100);}
  .mc {width: 87%; background-image: linear-gradient(to right, #acc7f1 , #0663f8);}
  .md {width: 96%; background-image: linear-gradient(to right, #75505c , #57001d);}
</style>
