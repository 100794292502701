<template>
    <div class="col-12 row justify-content-center ml-1 mr-1">
      <div class="col-12">
        <h2
          style="margin: 5px auto 0px 0%; position: relative; display: block; width: 100%;"
        >
           <i class="fas fa-ticket-alt"></i> Resgatar Código:
        </h2>
        <p class="text-center mt-3 mb-5">Retire os itens que você ganhou em sorteios usando o código aqui</p>
      </div>

      <input class="col-6 mx-5" type="text" name="code" id="code" v-model="code">

      <button class="col-3 mx-5 px-0 text-center minebutton" v-on:click="doRedeem()"> Resgatar </button>

       <div
        v-if="redeemErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in redeemErrors" :key="error">{{ error }}</div>         
        </transition-group>
        
      </div>
      <div
        v-if="redeemMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in redeemMessages" :key="message">{{ message }}</div>         
        </transition-group>
        
      </div>

      <hr class="col-12 mx-0 px-0"/> 

      <div class="col-12 mx-0 px-0 row justify-content-center" v-if="isAdmin">

        <h3 class="col-12 mx-0 text-center px-0 mt-2 mb-5">Criar novo Código de Resgate:</h3>

        <p class="col-12 text-center mt-3 mb-5">Crie códigos de resgate baseado nos produtos da loja. Sessão exclusiva da Staff</p>
       
        <select class="col-6 mx-5 px-0 mb-4 text-left" name="product" id="product_id" v-model="product_id">
            <option v-for="product in products" :key="product.id" :value="product.id"> 
            {{product.category}} > {{product.name.slice(2)}}
            </option>
        </select>

        <div class="col-12"></div>

        <button class="col-3 mx-5 px-0 text-center minebutton" v-on:click="newRedeem()" v-if="isAuthenticated"> Criar </button>
        
         <div class="col-12"></div>

        <div
            v-if="createErrors.length > 0"
            class="alert alert-danger col-8 mx-5 mt-4"
            role="alert"
        >
            <transition-group name="slide-fade" mode="out-in">
            <div v-for="error in createErrors" :key="error">{{ error }}</div>         
            </transition-group>
            
        </div>

        <div
            v-if="createMessages.length > 0"
            class="alert alert-success col-8 mx-5 mt-4"
            role="alert"
        >
            <transition-group name="slide-fade" mode="out-in">
            <div v-for="message in createMessages" :key="message">{{ message }}</div>         
            </transition-group>
        </div>

        <h3 class="col-12 mx-0 text-center my-4">Códigos de Resgate ativos:</h3>

        <div class="col-12 mx-0 px-0 text-center justify-self-center">
            <input type="checkbox" name="only_unused" id="only_unused" v-model="onlyUnused">
            <label class="ml-4" for = "only_unused"> Somente não utilizados </label>
        </div>
      
        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box mb-3">
          <thead>
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Produto</th>
              <th scope="col">Já resgatado?</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="redeem in filtered_redeem_codes" :key="redeem.id">
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  {{redeem.code}}
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  {{getProductCategory(redeem.product_id)}} > {{getProductName(redeem.product_id)}}
                </div>
              </td>
              <td>
                <div class="row col-12 justify-content-center mx-0 px-0">
                  {{redeem.redeemed}}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>
</template>
<script>
import Attachment from "./../../mixins/AttachmentMixin";
import Product from "./../../mixins/ProductMixin";
import RedeemCode from "./../../mixins/RedeemCodeMixin";
import _ from "lodash";

export default {
  name: "RedeemCode",
  data() {
    return {
      products: [],
      redeem_codes: [],
      product_id: undefined,
      redeemErrors: [],
      redeemMessages: [],
      createErrors: [],
      createMessages: [],
      code: '',
      onlyUnused: false
    };
  },
  mixins: [Product, Attachment, RedeemCode],
  computed: {

    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    filtered_redeem_codes(){
      return this.redeem_codes.filter((redeem_code) =>{
          if (this.onlyUnused){
            return !redeem_code.redeemed
          } else {
              return true;
          }
      });
    }
  },
  methods: {
    newRedeem(){
        this.createRedeem(this.product_id).
        then(() => {
            this.createMessages.push("Código de resgate criado com sucesso!")
            setTimeout(() => {
                this.createMessages.splice(0, 1);
            }, 5000);
           this.loadRedeems();
        }).catch((err) => {
          this.createErrors.push("Não foi possível criar o código de resgate :( Detalhe: "+err)
            setTimeout(() => {
                this.createErrors.splice(0, 1);
            }, 5000);
           this.loadRedeems();
        });
    },
    loadRedeems(){
        this.getAllRedeemCodes()
        .then((data) => {
          this.redeem_codes = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    doRedeem() {
      this.redeem(this.user.attributes.id, this.code)
        .then((data) => {
          if (data.error){
            this.redeemErrors.push(data.error)
            setTimeout(() => {
                this.redeemErrors.splice(0, 1);
            }, 5000);
          } else {
            this.redeemMessages.push("Código resgatado com sucesso! Confira seu item novo no inventário do site")
            setTimeout(() => {
                this.redeemMessages.splice(0, 1);
            }, 5000);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadProducts(){
         this.getAllProducts()
        .then((data) => {
          this.products = data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getProductName(product_id){
        let name = "";
      _.each(this.products, product => {
        if (product.id == product_id){
          name = product.name
          return;
        }
      });
      return name.slice(2);
    },
    getProductCategory(product_id){
        let category = "";
      _.each(this.products, product => {
        if (product.id == product_id){
          category = product.category
          return;
        }
      });
      return category;
    },
  },
  beforeMount() {
    this.loadProducts();
    this.loadRedeems();
  },
};
</script>
