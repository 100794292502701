<template>

  <table class="table-dark table-bordered table-hover col-12 dark-box mx-0 px-0">
    <thead>
      <tr>
        <th scope="col">Paramêtro</th>
        <th scope="col">Valor</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="property in properties()" :key="property">
        <td scope="col-9 mx-0 text-left"> {{ genericConfigAttributes.config[property].name }} </td>
        <td class="col-3 justify-content-center mx-0 px-0">
          <div class="mx-0 px-0 py-0 my-0 justify-content-left text-left">
            <input
              autocomplete="false"
              type="numeric"
              class="col-5 ml-2"
              v-model="genericConfigAttributes.config[property].value"
              :id="genericConfigAttributes.id+'_'+property+'_value'"
            /> {{ genericConfigAttributes.config[property].unity }}
          </div>
        </td>
      </tr>

    </tbody>
  </table>
  <hr class="col-12 mx-0 py-0 px-0 my-0">
  <button v-on:click="updateGameConfig()" class="col-7 mx-0 px-0 my-3 py-1 minebutton">
    Salvar Configurações
  </button>
  <div
      v-if="updateErrors.length > 0"
      class="alert alert-danger col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="error in updateErrors" :key="error">{{ error }}</div>
      </transition-group>

    </div>

    <div
      v-if="updateMessages.length > 0"
      class="alert alert-success col-8 mx-5 mt-4"
      role="alert"
    >
      <transition-group name="slide-fade" mode="out-in">
        <div v-for="message in updateMessages" :key="message">{{ message }}</div>
      </transition-group>
    </div>
</template>
<script>
import GameConfig from './../../mixins/GameConfigMixin'
export default {
  name: "GameConfigCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      genericConfigAttributes: this.game_config,
      changes: {}
    };
  },
  props: {
    game_config: Object,
  },
  mixins: [GameConfig],
  methods: {
    properties(){
      console.log(this.genericConfigAttributes)
      if (!this.genericConfigAttributes || !this.genericConfigAttributes.config) return [];
      return Object.keys(this.genericConfigAttributes.config);
    },
    updateGameConfig(){
      this.updateGameConfigRequest(this.genericConfigAttributes.id, {game_config: {config: this.genericConfigAttributes.config}})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Configuração de jogo atualizadas com sucesso! Status: "+data.status)
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          this.updateErrors.push("Houve um erro ao tentar atualizar estas configurações.")
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
};
</script>
