<template>
  <div class="classmodalbody">
    <br />
    <h3>Mecânicas de Classe:</h3>
    <hr style="margin-left: 0%; width: 30%" />
    <div style="text-align: left">
      As classes são o pilar do Knights of Minecraft; certamente são a essência
      do jogo e o que o torna mais atraente e divertido.
      <br />
      Existem até agora 9 classes distintas que englobam todas as diferentes
      funções dentro do jogo:<br /><br />
      <p style="text-align: center;">
        <span style="color:rgb(0, 153, 255)"> Paladino</span>,
        <span style="color:purple">Ladino</span>,
        <span style="color:lightblue">Mago</span>,
        <span style="color:darkgreen">Alquimista</span>,
        <span style="color:rgb(255, 230, 128)">Fazendeiro</span>,
        <span style="color:rgb(107, 145, 61)">Lenhador</span>,
        <span style="color:lightgray">Minerador</span>,
        <span style="color:rgb(138, 138, 138)">Ferreiro</span>,
        <span style="color:rgb(255, 51, 51)">Engenheiro</span>.
      </p>
      <hr />
      O jogo com cada uma das classes é totalmente único, diversificado e
      desafiador. Cada classe influência o mundo da sua maneira, com seu
      conjunto de habilidades ativas e passivas únicas, que fazem cada jogador
      ter a sua importância e ser único, podendo mesclar até duas classes
      primárias e duas classes secundárias para adequar bem o seu estilo de
      jogo.
      <br />
      Escolher uma classe como primária lhe permite desbloquear todas as
      habilidades ativas e passivas dela, além de ter maiores chances de
      realizar as tarefas especificas da classe. Escolher uma classe como
      secundária lhe permite desbloquear todas as habilidades passivas dela.
      <br /><br />
      Para ler mais sobre cada classe, navegue nesta pagina, clicando nos icones
      de cada uma delas para ler sobre.
    </div>
    <br />
  </div>
</template>
<script>
export default {
  name: "AboutClasses",
  data() {
    return {};
  },
};
</script>
