<template>
  <div class="col-12 row mx-0 px-0">
    <div class="col-12">
      <h2
        style="margin: 0px auto 0px 0%; position: relative; display: block; width: 100%;"
      >
        Pacotes de Cash:
      </h2>
    </div>

    <div v-if="isVerified" class="shopcard col-lg-2 col-md-3 col-5">
      <h5>{{ smallest.name }}</h5>
      <img :src="'img/products/csh.png'" alt="" />
      <hr />
      <p class="my-3">Preço: R${{ smallest.price }}</p>
      <div id="smallest-checkout" v-on:click="scrollToTop()"></div>
    </div>

    <div v-if="isVerified" class="shopcard col-lg-2 col-md-3 col-5">
      <h5>{{ intermediate.name }}</h5>
      <img :src="'/img/products/csh2.png'" alt="" />
      <hr />
      <p class="my-3">Preço: R${{ intermediate.price }}</p>
      <div id="intermediate-checkout" v-on:click="scrollToTop()"></div>
    </div>

    <div v-if="isVerified" class="shopcard col-lg-2 col-md-3 col-5">
      <h5>{{ big.name }}</h5>
      <img :src="'/img/products/csh3.png'" alt="" />
      <hr />
      <p class="my-3">Preço: R${{ big.price }}</p>
      <div id="big-checkout" v-on:click="scrollToTop()"></div>
    </div>

    <div v-if="isVerified" class="shopcard shopcard-rare col-lg-2 col-md-3 col-5">
      <h5>{{ biggest.name }}</h5>
      <img :src="'/img/products/csh3.png'" alt="" />
      <hr />
      <p class="my-3">Preço: R${{ biggest.price }}</p>
      <div id="biggest-checkout" v-on:click="scrollToTop()"></div>
    </div>
    <div
      v-if="!isVerified"
      class="text-center justify-self-center mx-auto my-3"
    >
      Sua conta ainda não está verificada. Verifique-a e depois compre Cash.
    </div>
  </div>
</template>
<script>
import { post } from "./../../utils/Request";

export default {
  name: "CashSection",
  data() {
    return {
      smallest: {},
      intermediate: {},
      big: {},
      biggest: {},
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
    isVerified() {
      if (this.isAuthenticated) {
        return this.user.attributes.verified;
      }
      return false;
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      const smallest = {
        tier: "smallest",
        name: "90 Cash",
        price: 5.0,
        quantity: 1,
        user_id: this.user.attributes.id,
      };
      this.smallest = smallest;
      const intermediate = {
        tier: "intermediate",
        name: "380 Cash",
        price: 20.0,
        quantity: 1,
        user_id: this.user.attributes.id,
      };
      this.intermediate = intermediate;
      const big = {
        tier: "big",
        name: "950 Cash",
        price: 50.0,
        quantity: 1,
        user_id: this.user.attributes.id,
      };
      this.big = big;
      const biggest = {
        tier: "biggest",
        name: "2500 Cash",
        price: 120.0,
        quantity: 1,
        user_id: this.user.attributes.id,
      };
      this.biggest = biggest;
      post("/payments/preferences", {
        items: [smallest, intermediate, big, biggest],
      })
        .then((response) => response.json())
        .then((data) => {
          this.prepareCheckoutButton(data.smallest, "#smallest-checkout");
          this.prepareCheckoutButton(
            data.intermediate,
            "#intermediate-checkout"
          );
          this.prepareCheckoutButton(data.big, "#big-checkout");
          this.prepareCheckoutButton(data.biggest, "#biggest-checkout");
        });
    }
  },
  methods: {
    prepareCheckoutButton(preference_id, element_id) {
      let lowestBag = document.createElement("script");
      lowestBag.src =
        "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js";
      lowestBag.type = "text/javascript";
      lowestBag.dataset.preferenceId = preference_id;
      lowestBag.style.position = "relative";
      lowestBag.style.top = window.scrollY;
      document.querySelector(element_id).appendChild(lowestBag);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
