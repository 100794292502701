import {get, patch} from './../utils/Request'
export default {
  methods: {
    getPerkNames(){
      return get("/perk_names")
      .then((response) => response.json());
    },
    showPerkName(id){
      return get("/perk_names/"+id)
      .then((response) => response.json());
    },
    updatePerkNameRequest(body ={}) {
      return patch("/perk_names/", body)
      .then((response) => {

        return response
      })
    }
  },
}
