<template>
  <div class=" col-12 row mx-0 px-0 main-content ">
    <h2 class="col-12 text-center">Nova Tarefa</h2>
    <div class="staff staff-team row col-12 mx-0 px-0 justify-content-around">
      <div class="col-3">
        <form
          class="col-12 row justify-content-center align-content-around py-1 mx-0 px-0"
          ref="createTaskForm"
        >
          <p class="col-9 mx-0 px-0 text-center">Titulo:</p>
          <input
            id="name"
            class="col-9 mx-0 mb-2 px-0"
            type="text"
            name="name"
          /><br />

          <div class="builder my-4 py-0 pb-1 px-0 col-9 mx-0 px-0">
            <div class="mt-0">Categoria:</div>
            <select id="category" name="category">
              <option value="building">Construção</option>
              <option value="lore">Roteiro</option>
              <option value="dev">Programação</option>
            </select>
          </div>
          <div style="font-size: 12px" class="my-2 col-9 mx-0 px-0">
            <div class="mt-0">Descrição:</div>
            <div class="textEditor col-12 mx-0 px-0">
              <textarea
                name="description"
                class="col-12 mx-0 px-0"
                style="min-height: 100px"
                maxlength="255"
                id="description"
              ></textarea
              ><br />
            </div>
          </div>
           <div style="font-size: 12px" class="my-4 col-9 mx-0 px-0">
            Lider da Tarefa:
            <select id="leader" name="leader" v-bind:value="leader">
              <option v-for="admin_user in admin_users"
                :key="admin_user" :value="admin_user.nickname">{{admin_user.nickname}}</option>
            </select>
          </div>

          <div style="font-size: 12px" class="my-2 col-9 mx-0 px-0">
            Card no Trello:
            <select id="trelloCard" name="trello_card">
              <option
                v-for="card in cardsOnBoard"
                :key="card.id"
                :value="card.id"
                >{{ card.name }}</option
              >
            </select>
          </div>
          <button
            class="minebutton col-8 my-3"
            v-on:click.prevent="sendCreateTaskForm()"
          >
            Criar Tarefa
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "./../../utils/Request";
export default {
  name: "TaskCreate",
  data() {
    return {
      cardsOnBoard: [],
      boardSelected: "",
      admin_users: [],
    };
  },
  methods: {
    getBoardCards() {
      get(
        "https://api.trello.com/1/boards/i19iMX2n/cards?key=461775ab1ea8a50c527472a368629c14&token=891ec56ec9f94c58fc10b810c906dc32b121da97107992e5fbb2e738964eb593"
      )
        .then((response) => response.json())
        .then((data) => {
          this.cardsOnBoard = data;
        });
    },
    getAdminUsers() {
      get("/users/admin-users")
        .then((response) => response.json())
        .then((data) => {
          this.admin_users = data;
        });
    },
    sendCreateTaskForm() {
      let form = this.$refs.createTaskForm;
      this.registerErrors = [];
      let taskname = form.name.value;
      let category = form.category.value;
      let description = form.description.value;
      let leader = form.leader.value;
      let trello_card = form.trello_card.value;
      let need_help = false;

      if (
        [taskname, category, description, leader, trello_card].includes(null) ||
        [taskname, category, description, leader, trello_card].includes(
          undefined
        )
      ) {
        return;
      }
      post("/tasks", {
        name: taskname,
        category,
        description,
        leader,
        trello_card,
        need_help,
      })
        .then((response) => response.json())
        .then(() => {
          this.$router.push("/progresso");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  beforeMount() {
    this.getBoardCards();
    this.getAdminUsers();
  },
};
</script>
