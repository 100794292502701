import {get, patch, destroy, post} from './../utils/Request'
export default {
  methods: {
    getMerchantItemCategories(){
      return get("/merchant_items")
      .then((response) => response.json());
    },
    showMerchantItemCategory(id){
      return get("/merchant_items/"+id)
      .then((response) => response.json());
    },
    createMerchantItemRequest(body ={}) {
      return post("/merchant_items", body)
      .then((response) => {

        return response
      })
    },
    updateMerchantItemRequest(id, body ={}) {
      return patch("/merchant_items/"+id, body)
      .then((response) => {

        return response
      })
    },
    deleteMerchantItemRequest(id, body ={}) {
      return destroy("/merchant_items/"+id, body)
      .then((response) => {

        return response
      })
    },
  },
}
