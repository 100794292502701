<template>
  <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-around">
    <h4 class="col-12 text-center mx-0">{{ weapon_attribute.weapon_type }}</h4>
    <hr class="col-12 mx-0 px-0" />
    Qualidade: {{ getQualityFromCode(weapon_attribute.quality) }}
    <hr class="col-12 mx-0 px-0" />

    <h5 class="col-12 mx-0 px-0 text-center">Atributos</h5>
    <div class="col-12 row mx-0 px-0">
      <div class="row justify-content-center col-12 row mx-0 px-0">

        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Paramêtro</th>
              <th scope="col">Valor Mínimo</th>
              <th scope="col">Valor Máximo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="property in vanilla_attributes()" :key="property">
              <td scope="col-9 mx-0 text-left"> {{ weapon_attribute.vanilla_attributes[property].name }} </td>
              <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-10 mx-auto px-0"
                    v-model="weapon_attribute.vanilla_attributes[property].min_value"
                    :id="weapon_attribute.id+'_'+property+'_min_value'"
                  />
                </div>
              </td>
               <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-10 mx-auto px-0"
                    v-model="weapon_attribute.vanilla_attributes[property].max_value"
                    :id="weapon_attribute.id+'_'+property+'_max_value'"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
       <hr class="col-12 mx-0 px-0">

        <h5 class="col-12 mx-0 px-0 text-center">Requisitos</h5>

        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Paramêtro</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="property in requirements()" :key="property">
              <td scope="col-9 mx-0 text-left"> {{ weapon_attribute.requirements[property].name }} </td>
              <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    :type="getType(property)"
                    class="col-10 mx-auto px-0"
                    v-model="weapon_attribute.requirements[property].value"
                    :id="weapon_attribute.id+'_'+property+'_min_value'"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <hr class="col-12 mx-0 px-0">

        <h5 class="col-12 mx-0 px-0 text-center">Atributos Mágicos</h5>

        <table class="table-dark table-bordered table-hover col-lg-10 col-12 dark-box">
          <thead>
            <tr>
              <th scope="col">Paramêtro</th>
              <th scope="col">Valor Mínimo</th>
              <th scope="col">Valor Máximo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="property in magic_attributes()" :key="property">
              <td scope="col-9 mx-0 text-left"> {{ property }} </td>
              <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-10 mx-auto px-0"
                    v-model="weapon_attribute.magic_attributes[property].min_value"
                    :id="weapon_attribute.id+'_'+property+'mt_min_value'"
                  />
                </div>
              </td>
               <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-10 mx-auto px-0"
                    v-model="weapon_attribute.magic_attributes[property].max_value"
                    :id="weapon_attribute.id+'_'+property+'mt_max_value'"
                  />
                </div>
              </td>
              <td class="col-3 justify-content-center mx-0 px-0">
                <i class="fas fa-times fa-2x mx-2 px-0 py-2" v-on:click="removeMagicAttribute(property)"></i>
              </td>
            </tr>
            <tr class="table-success">
              <td scope="col-9 mx-0 text-left">
                 <select id="new_magic_attribute" name="new_magic_attribute" v-model="new_magic_attribute" class="col-12 mx-2 px-0" style="width: 300px">
                  <option v-for="magic_attribute in this.$parent.magic_attributes_list" :key=magic_attribute.id :value=magic_attribute.name>
                    {{magic_attribute.name}}
                  </option>
                </select>
              </td>
              <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-10 mx-auto px-0"
                    v-model="new_min_value"
                    id="new_min_value"
                  />
                </div>
              </td>
               <td class="col-3 justify-content-center mx-0 px-0">
                <div class="mx-0 px-0 py-0 my-0 justify-content-center text-center">
                  <input
                    autocomplete="false"
                    type="numeric"
                    class="col-10 mx-auto px-0"
                    v-model="new_max_value"
                    id="new_max_value"
                  />
                </div>
              </td>
              <td class="col-3 justify-content-center mx-0 px-0">
                <i class="far fa-plus-square fa-2x mx-2 px-0 py-2" v-on:click="addMagicAttribute()"></i>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 mx-0 px-0 row justify-content-center my-3">
        <button class="minebutton mx-0 px-3" v-on:click="updateWeaponAttribute()">Aplicar</button>
    </div>
     <div
        v-if="updateErrors.length > 0"
        class="alert alert-danger col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in updateErrors" :key="error">{{ error }}</div>
        </transition-group>

      </div>
      <div
        v-if="updateMessages.length > 0"
        class="alert alert-success col-8 mx-5 mt-4"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="message in updateMessages" :key="message">{{ message }}</div>
        </transition-group>

      </div>
  </div>
</template>
<script>
import WeaponAttribute from '../../mixins/WeaponAttributeMixin'

export default {
  name: "WeaponAttributeCard",
  data() {
    return {
      updateErrors: [],
      updateMessages: [],
      weapon_attribute: this.weapon,
      is_armor: false,
      new_magic_attribute: undefined,
      new_min_value: 0,
      new_max_value: 0,
    };
  },
  props: {
    weapon: Object
  },
  mixins: [WeaponAttribute],
  methods: {
    removeMagicAttribute(mt){
      if (!this.weapon_attribute.magic_attributes || !this.weapon_attribute.magic_attributes[mt]){
        return;
      }
      delete this.weapon_attribute.magic_attributes[mt]
    },
    addMagicAttribute(){
      if (!this.weapon_attribute.magic_attributes){
        this.weapon_attribute.magic_attributes = {}
      }
      this.weapon_attribute.magic_attributes[this.new_magic_attribute] = { min_value: this.new_min_value, max_value: this.new_max_value, chance: 0}
    },
    getType(property){
      if (property == "REQUIRED_CLASS"){
        return "text";
      } else {
        return "numeric";
      }
    },
    magic_attributes() {
      if (!this.weapon_attribute || !this.weapon_attribute.magic_attributes) return [];

      return Object.keys(this.weapon_attribute.magic_attributes);
    },
    vanilla_attributes() {
      if (!this.weapon_attribute || !this.weapon_attribute.vanilla_attributes) return [];

      return Object.keys(this.weapon_attribute.vanilla_attributes);
    },
    requirements() {
      if (!this.weapon_attribute || !this.weapon_attribute.requirements) return [];

      return Object.keys(this.weapon_attribute.requirements);
    },
    getQualityFromCode(code) {
      if (code == "1") return "Péssimo";
      if (code == "2") return "Mal-feito";
      if (code == "3") return "Bom";
      if (code == "4") return "Excepcional";
      if (code == "5") return "Sobrenatural";
    },
    getSecondAttribute(){
      if (this.is_armor){
        return "Resistência da Armadura";
      }
      return "Velocidade de Ataque";
    },
    getFirstAttribute(){
      if (this.is_armor){
        return "Defesa";
      }
      return "Dano";
    },
    updateWeaponAttribute(){
      let vanilla_attributes = this.weapon_attribute.vanilla_attributes
      let requirements = this.weapon_attribute.requirements

      this.updateRequest(this.weapon_attribute.id,{
        weapon_attribute: {
          vanilla_attributes: vanilla_attributes, requirements: requirements
        }})
      .then(data =>{
        if (data.status){
          this.updateMessages.push("Item atualizado com sucesso!")
          setTimeout(() => {
            this.updateMessages.splice(0, 1);
          }, 5000);
        } else {
          if (this.is_armor){
           this.updateErrors.push("Houve um erro ao tentar atualizar os atributos desta armadura.")
          } else {
           this.updateErrors.push("Houve um erro ao tentar atualizar os atributos desta arma.")
          }
          setTimeout(() => {
            this.updateErrors.splice(0, 1);
          }, 5000);
        }
      })
    }
  },
  beforeMount(){
    if (this.weapon_attribute.weapon_type.includes("chestplate")
      || this.weapon_attribute.weapon_type.includes("leggings")
      || this.weapon_attribute.weapon_type.includes("boots")
      || this.weapon_attribute.weapon_type.includes("helmet")) {
        this.is_armor = true;
      }
  }
};
</script>
