<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center">
    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div class="staff-team row col-12 mx-0 px-0 justify-content-around">
        <div v-if="successLogin && successJoin" class="col-7">
          <h4>Você entrou no nosso servidor de voluntários do Discord.</h4>
        </div>
        <div v-else-if="successLogin && !succesJoin" class="col-7">
          <h4>
            Houve um erro ao adicionar você no nosso servidor de voluntários do
            Discord.
          </h4>
        </div>
        <div v-else-if="!successLogin" class="col-7">
          <h4>Houve um erro ao logar na sua conta do Discord.</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import { patch } from "./../../utils/Request";

export default {
  name: "DiscordCallback",
  props: {},
  data() {
    return {
      successJoin: true,
      successLogin: true,
      is_loading: false,
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    updateUserDiscordID() {
      if (
        Cookie.get("discord_id") == "" ||
        Cookie.get("discord_id") == "undefined" ||
        Cookie.get("discord_id") == null
      ) {
        this.successLogin = false;
        return;
      }
      patch("/users/" + this.user.attributes.id, {
        discord_id: Cookie.get("discord_id"),
      })
        .then((response) => {
          let json = response.json();
          return json;
        })
        .then((data) => {
          if (data.error || data.errors) {
            this.successLogin = false;
          } else {
            this.insertUserInGuild();
          }
        });
    },
    insertUserInGuild() {
      patch(
        "https://discordapp.com/api/guilds/826891441137516555/members/" +
          this.user.attributes.discord_id,
        {
          access_token: Cookie.get("discord_access_token"),
        },
        { Authorization: "Bot " + process.env.VUE_APP_BOT_TOKEN }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          if (err) {
            this.successJoin = false;
          }
        });
    },
  },
  beforeMount() {
    if (!this.user.attributes.discord_id) {
      this.updateUserDiscordID();
    } else {
      this.insertUserInGuild();
    }
  },
};
</script>

<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
</style>
