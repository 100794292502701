<template>
  <div class="col-12 row mx-0 px-0 justify-content-center">
    <div class="main-content row col-11 px-0 justify-content-center mx-0">
      <div class="col-12">
        <div>
          <h2>Progresso para o Lançamento da Beta</h2>
          <p>
            Acompanhe por aqui as ultimas tarefas a serem executadas para o lançamento da Beta
          </p>
        </div>

        <div class="prev-epr">
          <h2>Visão Geral:</h2>
          <div class="col-12 row px-0 mx-0 justify-content-center">
            <div class="col-6">
              <div class="meter-container">
                <p>Classes e Mecânicas Principais</p>
                <div class="meter mfull" v-tippy="{ content: classes_info }">
                  <div class="meter ma tippy">
                    <div class="skills">99.75%</div>
                  </div>
                </div>
              </div>
              <div class="meter-container">
                <p>Calabouços e Fendas</p>
                <div id="mmb" class="meter mfull">
                  <div class="meter mb">
                    <div class="skills">95%</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="meter-container">
                <p>Missões</p>
                <div id="mmc" class="meter mfull">
                  <div class="meter mc">
                    <div class="skills">85%</div>
                  </div>
                </div>
              </div>
              <div class="meter-container">
                <p>Construções</p>
                <div class="meter mfull">
                  <div id="mmd" class="meter md">
                    <div class="skills">95%</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 mx-0 px-0">
              <div class="meter-container">
                <p>Planejamento</p>
                <div id="mme" class="meter mfull">
                  <div class="meter me">
                    <div class="skills">100%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="staff col-12 row justify-content-center mx-0">
      <div
        v-if="deleteErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in deleteErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <div
        v-if="setAsCompletedErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in setAsCompletedErrors" :key="error">
            {{ error }}
          </div>
        </transition-group>
      </div>
      <div
        v-if="deleteMessages.length > 0"
        class="alert alert-success col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="msg in deleteMessages" :key="msg">{{ msg }}</div>
        </transition-group>
      </div>
      <h2 class="col-12 text-center">Tarefas Atuais</h2>
      <div
        v-if="isLoading"
        class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0"
      >
        <img :src="'img/loading.gif'" alt="" class="loader-gif" />
      </div>
      <div
        v-if="!isLoading"
        class="staff-team row col-12 mx-2 justify-content-around"
      >
        <div
          v-for="task in tasks"
          :key="task.id"
          v-show="!task.finished"
          class="col-lg-4 col-xl-3 col-md-6 mx-2 px-0 row align-content justify-content-center"
        >
          <div class="col-12 row mx-0 px-0 my-0 py-0 justify-content-center">
            <h4 class="col-12 mx-0">{{ task.name }}</h4>
            <div v-if="task.category == 'building'" class="builder col-11">
              Construção
            </div>
            <div v-else-if="task.category == 'lore'" class="lorer col-11">
              Roteiro
            </div>
            <div v-else-if="task.category == 'dev'" class="programmer col-11">
              Programação
            </div>
            <div v-else class="admin col-11">Sem Categoria</div>

            <div style="font-size: 12px" class="description my-4 col-12">
              {{ filterDescription(task.description) }}
            </div>
            <div style="font-size: 12px" class="mt-4 col-12">
              Lider da Task: {{ task.leader }}
            </div>
            <p style="font-size: 12px" class="my-0 col-12">
              Membros: {{ calculateMembers(task) }}
            </p>
            <p style="font-size: 12px" class="mb-4 col-12">
              Voluntários: {{ calculateVolunteers(task) }}
            </p>

            <p
              v-if="isAdmin() && calculatePendingVolunteers(task) > 0"
              style="font-size: 12px"
              class="mb-4 col-12"
            >
              <i style="color: orange" class="fas fa-exclamation-circle"></i>
              Voluntários Pendentes: {{ calculatePendingVolunteers(task) }}
            </p>

            <div class="mb-0 mt-2 col-12">Progresso:</div>

            <div class="meter mfull mt-1 col-11 row mx-0 px-0 mb-5">
              <div
                class="meter md"
                :style="{
                  width:
                    getTaskProgress(getCard(task.trello_card)) + ' !important',
                }"
              >
                <div class="skills" style="z-index: 100">
                  {{ getTaskProgress(getCard(task.trello_card)) }}
                </div>
              </div>
            </div>

            <div
              class="col-12 mx-0 row px-0 justify-content-center"
              v-if="isAuthenticated"
            >
              <div
                class="col-12 mx-0 px-0 justify-content-center"
                v-if="task.need_help"
              >
                <h6
                  v-if="!isVerified"
                  class="offbutton mb-2 px-0 col-8"
                  style="font-size: 85%"
                >
                  Verifique sua conta p/ voluntariar-se
                </h6>

                <div
                  v-if="
                    isVerified &&
                    (!hasDiscordID || (hasDiscordID && !isInVolunteerDiscord))
                  "
                  class="mb-2 px-0 col-12 row mx-0 justify-content-center"
                  style="font-size: 85%"
                >
                  Antes de voluntariar-se, entre no Discord de Voluntários:
                  <a :href="discordLoginUrl" class="minebutton mb-2 px-0 col-8">
                    Entrar
                  </a>
                </div>

                <button
                  v-if="
                    isVerified &&
                    hasDiscordID &&
                    isInVolunteerDiscord &&
                    !isVolunteerOnTask(task.id, user.attributes.id)
                  "
                  v-on:click="volunteer(task.id, user.attributes.id)"
                  class="minebutton mb-2 px-0 col-8"
                >
                  Volutariar-se
                </button>

                <h6
                  v-else-if="
                    isVerified &&
                    isVolunteerOnTask(task.id, user.attributes.id) &&
                    isWaitingVolunteeringApproval(
                      task.id,
                      user.attributes.id
                    ) &&
                    isInVolunteerDiscord &&
                    hasDiscordID
                  "
                  class="mb-2 col"
                >
                  Esperando aprovação como voluntário...
                </h6>
                <h6
                  v-if="
                    isVerified &&
                    isVolunteerOnTask(task.id, user.attributes.id) &&
                    !isWaitingVolunteeringApproval(
                      task.id,
                      user.attributes.id
                    ) &&
                    isInVolunteerDiscord &&
                    hasDiscordID
                  "
                  class="mb-2 col"
                >
                  Você é voluntário nesta tarefa!
                </h6>
              </div>
              <h6 v-else class="mb-2 col-">Não precisa-se de voluntários</h6>
            </div>

            <a
              :href="'/progresso/ver-tarefa/' + task.id"
              class="minebutton mb-0 mt-3 px-0 col-8"
            >
              Ver
            </a>
            <a
              v-if="isAdmin()"
              :href="'/progresso/editar-tarefa/' + task.id"
              class="minebutton mb-2 mt-3 px-0 col-8"
            >
              Editar
            </a>
            <div
              v-if="isAdmin()"
              class="col-12 mx-0 px-3 justify-content-center mt-4 py-0 text-center"
            >
              <hr class="col-12 mx-0 px-0" />
              <i
                class="fas fa-check fa-2x mx-5 clickable-icon"
                v-on:click="completeTask(task.id)"
                v-tippy="{ content: 'Marcar como concluido' }"
                style="color: darkgreen"
              ></i>
              <i
                class="fas fa-trash-alt fa-2x mx-5 clickable-icon"
                v-on:click="deleteTask(task.id)"
                v-tippy="{ content: 'Deletar' }"
                style="color: darkred"
              ></i>
            </div>
          </div>
        </div>

        <div v-if="tasks.length == 0" class="col-7">
          <h4>Sem tasks no momento...</h4>
        </div>

        <div
          v-if="isAdmin()"
          class="col-lg-4 col-xl-3 col-md-6 mx-2 px-0 row align-content-around justify-content-center"
        >
          <h4 class="col-12">Criar Tarefa</h4>
          <a href="progresso/criar-tarefa" style="color: white !important">
            <div class="col-12 px-4 minebutton-large">
              <i class="far fa-plus-square fa-4x mx-0 px-0 py-2"></i>
            </div>
          </a>
        </div>

       
        <div class="col-11 staff-team mx-0 py-2 px-0 justify-content-center">
          <h3 class="col-12 my-3">
            Tarefas concluidas: {{ getCompletedTasksCount }}
          </h3>
          <a
            href="progresso/tarefas-concluidas"
            style="color: white !important"
          >
            <div class="col-12 px-4 mx-0 minebutton">Ver todas</div>
          </a>
        </div>
        
        <!--
        <hr class="col-12 mx-0 px-0" />
        <h2 class="col-12 mx-0 px-0 mt-4 text-center">
          Maiores contribuintes:
        </h2>
        <div class="col-3 row mx-0 px-0 staff-team align-content-start">
          <h3 class="col-12 text-center my-2 py-0">Como lider:</h3>
          <hr class="col-12 mx-0 px-0" />
          <ol class="col-12 text-left my-2 mx-0 px-5">
            <li v-for="leader in topLeaders" :key="leader">
              <img
                class="my-0 ml-0 mr-2 px-0 py-0 rounded"
                :src="'https://minotar.net/helm/' + leader.leader + '/100.png'"
                style="width: 25px; height; 25px;"
              />
              <span
                >{{ leader.leader }}:
                {{ taskQuantityText(leader.quantity) }}</span
              >
            </li>
          </ol>
        </div>
        <div class="col-3 row mx-0 px-0 staff-team align-content-start">
          <h3 class="col-12 text-center my-2 py-0">Como membro:</h3>
          <hr class="col-12 mx-0 px-0" />

          <ol class="col-12 text-left my-2 mx-0 px-5">
            <li v-for="member in topMembers" :key="member">
              <img
                class="my-0 ml-0 mr-2 px-0 py-0 rounded"
                :src="'https://minotar.net/helm/' + member.user + '/100.png'"
                style="width: 25px; height; 25px;"
              />
              <span
                >{{ member.user }}:
                {{ taskQuantityText(member.quantity) }}</span
              >
            </li>
          </ol>
        </div>
        <div class="col-3 row mx-0 px-0 staff-team align-content-start">
          <h3 class="col-12 text-center my-2 py-0">Como voluntário:</h3>
          <hr class="col-12 mx-0 px-0" />

          <ol class="col-12 text-left my-2 mx-0 px-5">
            <li
              v-for="volunteer in topVolunteers"
              :key="volunteer"
              class="mx-0 px-0 my-2 py-0"
            >
              <img
                class="my-0 ml-0 mr-2 px-0 py-0 rounded"
                :src="'https://minotar.net/helm/' + volunteer.user + '/100.png'"
                style="width: 25px; height; 25px;"
              />
              <span
                >{{ volunteer.user }}:
                {{ taskQuantityText(volunteer.quantity) }}</span
              >
            </li>
          </ol>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import Task from "./../mixins/TaskMixin";
import { directive } from "vue-tippy";
import _ from "lodash";
import { get, post } from "./../utils/Request";

export default {
  name: "Progress",
  props: {},
  data() {
    return {
      tasks: [],
      classes_info:
        "Progresso do desenvolvimento das Classes, Habilidades ativas, passivas e itens que compõem o jogo.",
      cardsOnBoard: [],
      deleteErrors: [],
      deleteMessages: [],
      setAsCompletedErrors: [],
      isLoading: true,
      isInDiscord: false,
      isInVolunteerDiscord: false,
      bot_api_url: process.env.VUE_APP_BOT_API_URL,
      discordLoginUrl: process.env.VUE_APP_BOT_LOGIN_URL,
    };
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
    hasDiscordID() {
      if (this.user.attributes.discord_id) {
        return true;
      } else {
        return false;
      }
    },
    discordID() {
      return this.user.attributes.discord_id;
    },
    isVerified() {
      if (this.isAuthenticated) return this.user.attributes.verified;
      return false;
    },
    getCompletedTasksCount() {
      let count = 0;
      _.each(this.tasks, (task) => {
        if (task.finished) {
          count++;
        }
      });
      return count;
    },
    topLeaders() {
      let leaders = {};
      _.each(this.tasks, (task) => {
        if (task.finished) {
          if (!(task.leader in leaders)) {
            leaders[task.leader] = 1;
          } else {
            let alreadyCompleted = leaders[task.leader];
            alreadyCompleted++;
            leaders[task.leader] = alreadyCompleted;
          }
        }
      });
      let topLeaders = [];
      _.each(Object.keys(leaders), (leader) => {
        topLeaders.push({ leader: leader, quantity: leaders[leader] });
      });
      topLeaders.sort(function (a, b) {
        return parseFloat(b.quantity) - parseFloat(a.quantity);
      });

      return topLeaders;
    },
    topMembers() {
      let members = {};
      _.each(this.tasks, (task) => {
        if (task.finished) {
          _.each(task.members, (task_member) => {
            let user = task_member.user
            if (!(user.nickname in members)) {
              members[user.nickname] = 1;
            } else {
              let alreadyCompleted = members[user.nickname];
              alreadyCompleted++;
              members[user.nickname] = alreadyCompleted;
            }
          });
        }
      });
      let topMembers = [];
      _.each(Object.keys(members), (member) => {
        topMembers.push({
          user: member,
          quantity: members[member],
        });
      });
      topMembers.sort(function (a, b) {
        return parseFloat(b.quantity) - parseFloat(a.quantity);
      });

      return topMembers;
    },
    topVolunteers() {
      let volunteers = {};
      _.each(this.tasks, (task) => {
        if (task.finished) {
          console.log(task)
          _.each(task.task_volunteers, (task_volunteer) => {
            let user = task_volunteer.user
            if (task_volunteer.approved) {
              if (!(user.nickname in volunteers)) {
                volunteers[user.nickname] = 1;
              } else {
                let alreadyCompleted = volunteers[user.nickname];
                alreadyCompleted++;
                volunteers[user.nickname] = alreadyCompleted;
              }
            }
          });
        }
      });
      let topVolunteers = [];
      _.each(Object.keys(volunteers), (volunteer) => {
        topVolunteers.push({
          user: volunteer,
          quantity: volunteers[volunteer],
        });
      });
      topVolunteers.sort(function (a, b) {
        return parseFloat(b.quantity) - parseFloat(a.quantity);
      });

      return topVolunteers;
    },
  },
  methods: {
    filterDescription(description) {
      if (description.length >= 255) {
        description = description.slice(0, 254) + "...";
        return description;
      }
      return description;
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    isVolunteerOnTask(task_id, user_id) {
      var found = false;
      _.each(this.tasks, (task) => {
        if (task.id == task_id) {
          _.each(task.task_volunteers, (user) => {
            if (user.id == user_id) {
              found = true;
            }
          });
        }
      });
      return found;
    },
    isWaitingVolunteeringApproval(task_id, user_id) {
      var found = false;
      _.each(this.tasks, (task) => {
        if (task.id == task_id) {
          _.each(task.task_volunteers, (user) => {
            if (user.id == user_id && !user.approved) {
              found = true;
            }
          });
        }
      });
      return found;
    },
    volunteer(task_id, user_id) {
      this.askToVolunteer(task_id, user_id).then(() => {
        post(this.bot_api_url + "/task/notify", {
          userId: user_id,
          taskId: task_id,
        });
        this.loadTasks();
      });
    },
    deleteTask(id) {
      this.deleteRequest(id).then((data) => {
        if (data.ok) {
          for (var i = 0; i < this.tasks.length; i++) {
            if (this.tasks[i].id == id) {
              this.tasks.splice(i, 1);
            }
          }
          this.deleteMessages.push("Task " + id + " deletada com sucesso");
          setTimeout(() => {
            this.deleteMessages.splice(0, 1);
          }, 5000);
        } else {
          this.deleteErrors.push(
            "Houve um erro ao tentar deletar a Task " + id
          );
          setTimeout(() => {
            this.deleteErrors.splice(0, 1);
          }, 5000);
        }
      });
    },
    completeTask(id) {
      this.updateRequest(id, { finished: true }).then((data) => {
        if (data.ok) {
          this.$router.go("/progresso");
        } else {
          this.setAsCompletedErrors.push(
            "Houve um erro ao tentar marcar a Task " + id + " como completa"
          );
          setTimeout(() => {
            this.setAsCompletedErrors.splice(0, 1);
          }, 5000);
        }
      });
    },
    taskQuantityText(quantity) {
      if (quantity == 1) {
        return "1 tarefa";
      }
      let text = quantity + " tarefas";
      return text;
    },
    getBoardCards() {
      get(
        "https://api.trello.com/1/boards/i19iMX2n/cards?key=461775ab1ea8a50c527472a368629c14&token=891ec56ec9f94c58fc10b810c906dc32b121da97107992e5fbb2e738964eb593"
      )
        .then((response) => response.json())
        .then((data) => {
          this.cardsOnBoard = data;
        });
    },
    getIsInDiscord() {
      if (this.hasDiscordID) {
        get(
          this.bot_api_url + "/user/?userId=" + this.user.attributes.discord_id
        )
          .then((response) => response.json())
          .then((data) => {
            this.isInVolunteerDiscord = data.status;
          });
      } else this.isInVolunteerDiscord = false;
    },
    getCard(card_id) {
      let card = {};
      _.each(this.cardsOnBoard, (trello_card) => {
        if (trello_card.id == card_id) {
          card = trello_card;
        }
      });
      return card;
    },
    loadTasks() {
      this.getTasks().then((tasks) => {
        this.tasks = tasks;
        this.isLoading = false;
      });
    },
    calculateMembers(task) {
      if (task.members) return task.members.length + 1;
      else return 1;
    },
    calculateVolunteers(task) {
      let count = 0;

      if (task.volunteers) {
        _.each(task.volunteers, (volunteer) => {
          if (volunteer.approved) {
            count++;
          }
        });
      }
      return count;
    },
    calculatePendingVolunteers(task) {
      let count = 0;

      if (task.volunteers) {
        _.each(task.volunteers, (volunteer) => {
          if (!volunteer.approved) {
            count++;
          }
        });
      }
      return count;
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [Task],
  beforeMount() {
    this.loadTasks();
    this.getBoardCards();
    this.getIsInDiscord();
  },
};
</script>
<style scoped>
a,
a:hover,
a:focus,
a:visited,
a:link {
  text-decoration: none;
  color: inherit;
}
.ma {
  width: 99.75%;
  background-image: linear-gradient(
    to right,
    rgb(247, 224, 188),
    rgb(255, 153, 0)
  );
}
.mb {
  width: 95%;
  background-image: linear-gradient(to right, #96b696, #00b100);
}
.mc {
  width: 85%;
  background-image: linear-gradient(to right, #acc7f1, #0663f8);
}
.md {
  width: 95%;
  background-image: linear-gradient(to right, #75505c, #57001d);
}
.me {
  width: 100%;
  background-image: linear-gradient(to right, #456360, #004e46);
}
.builder,
.programmer,
.lorer {
  max-height: 40px;
  min-height: 40px;
  height: 40px;
}
.minebutton,
.offbutton {
  max-height: 30px;
  min-height: 30px;
  height: 30px;
}
.minebutton-large {
  max-height: 60px;
  min-height: 30px;
  height: 60px;
}
.description {
  max-height: 100px;
  min-height: 100px;
  height: 100px;
}
.loader-gif {
  max-width: 250px;
  min-width: 250px;
  width: 250px;
}
</style>
