import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { plugin as VueTippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling
const app = createApp(App)
app.use(store)
app.use(router)
app.use(
  VueTippy,
  {
    directive: 'tippy',
    component: 'tippy',
    defaultProps: {
      placement: 'bottom',
      theme: 'material',
      maxWidth: 650,
      animation: 'scale'
    },
  }
)

app.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

app.mount('#app')
