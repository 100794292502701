import { createStore } from 'vuex'
import Cookie from 'js-cookie'
export default createStore({
    state: {
        user: Cookie.get("user"),
    },
    mutations: {
        setUser(state, newValue){
            Cookie.set("user", newValue, { expires: 14 })
            state.user = Cookie.get("user")
        },
        cleanUser(state){
            Cookie.remove("user")
            state.user = Cookie.get("user")
        }
    },
    actions: {},
    modules: {}
})