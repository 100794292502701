<template>
  <div class="col-12 row mx-0 px-0 py-0 my-0 justify-content-center align-content-start">
    <div class="col-12 row justify-content-center my-auto align-content-start">
      <h2 class="col-7 mx-0 px-0 pb-0 text-center my-4">
        Tabela de Balanceamento de Chances
      </h2>
      <hr class="col-12 mx-0 px-0 my-0" />
    </div>

    <div
      class="staff col-12 my-0 row justify-content-center align-content-start mx-0"
    >
      <div
        v-if="editErrors.length > 0"
        class="alert alert-danger col-8 mx-5"
        role="alert"
      >
        <transition-group name="slide-fade" mode="out-in">
          <div v-for="error in editErrors" :key="error">{{ error }}</div>
        </transition-group>
      </div>

      <input class="col-lg-4 col-7 mx-2 px-2 mt-3 mb-0 py-2 simpleborder-1" type="text" v-model="search_result" placeholder="Procurar por nome..." />
      <select class="col-lg-4 col-7 mx-2 px-2 mt-3 mb-0 py-2 simpleborder-1" v-model="class_search_result">
          <option value="empty"> Sem filtro de Classe</option>
          <option value="Fazendeiro"> Fazendeiro</option>
          <option value="Lenhador"> Lenhador</option>
          <option value="Minerador"> Minerador</option>
          <option value="Ferreiro"> Ferreiro</option>
          <option value="Ladino"> Ladino</option>
          <option value="Paladino"> Paladino</option>
          <option value="Alquimista"> Alquimista</option>
          <option value="Mago"> Mago</option>
          <option value="Engenheiro"> Engenheiro</option>
          <option value="Cavaleiro da Morte"> Cavaleiro da Morte</option>
          <option value="Todas"> Todas</option>
      </select>

      <div class='tag-input col-9 mx-0 px-0 my-4'>
        <div v-for='(tag, index) in tags' :key='tag' class='tag-input__tag' style="font-size: 9px; align-self: left">
          {{ tag }}
          <span @click='removeTag(index)'>x</span>
        </div>
        <input
          type='text'
          placeholder="Digite uma Tag e aperte Enter para filtrar"
          class='tag-input__text col-12 mx-0'
          @keydown.enter='addTag'
          @keydown.delete='removeLastTag'
        />
      </div>
      <div class='col-9 mx-0 px-0 my-4'>
        <input type="checkbox" id="empty_tags" name="empty_tags" class="mx-2" v-model="empty_tags_search">
        <label class="mr-4" for="empty_tags">Sem tags</label>
        <input type="checkbox" id="non_configured" name="non_configured" class="mx-2" v-model="non_configured_search">
        <label for="non_configured">Não Configurados</label>
      </div>

      <div v-if="isAdmin() && is_loading_chances" class="col-12 text-center justify-self-center mx-auto px-0 my-0 py-0">
        <img :src="'img/loading.gif'" alt="" class="loader-gif">
      </div>
      <div  v-if="isAdmin() && !is_loading_chances" class="staff-team row col-12 mx-0 px-0 justify-content-around">
        <template v-for="chance in chances" :key="chance.id">
          <div
            v-if="onSearchResult(chance)"
            class="staticcard col-lg-3 my-2 mx-2 px-0 row align-content-start py-5 justify-content-center"
          >
            <ChanceCard :p_chance="chance" @reload-chances="loadChances()"></ChanceCard>
          </div>
        </template>

        <div v-if="chances.length == 0" class="col-7">
          <h4>Sem Chances registradas no momento...</h4>
        </div>
      </div>
      <div v-else-if="!isAdmin()">Você não é um administrador, você não tem permissão para acessar esta página</div>
    </div>
  </div>
</template>

<script>
import Chance from "./../../mixins/ChanceMixin";
import { directive } from "vue-tippy";
import ChanceCard from "@/components/chances/ChanceCard";
import SkillNameMixin from "./../../mixins/SkillNameMixin";
import PerkNameMixin from "./../../mixins/PerkNameMixin";

export default {
  name: "ChanceBalanceTable",
  props: {},
  components: {
    ChanceCard,
  },
  data() {
    return {
      chance_list: [],
      editErrors: [],
      editMessages: [],
      is_loading_chances: true,
      search_result: undefined,
      class_search_result: "empty",
      perk_names: {},
      skill_names: {},
      tags: [],
      empty_tags_search: false,
      non_configured_search: false
    };
  },
  computed: {
    chances() {
      return this.chance_list;
    },
    user() {
      return JSON.parse(this.$store.state.user);
    },
    isAuthenticated() {
      return this.$store.state.user;
    },
  },
  methods: {
    addTag(event) {
      event.preventDefault()
      let val = event.target.value.trim()
      if (val.length > 0) {
        if (this.tags.length >= 1) {
          for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i] === val) {
              return false
            }
          }
        }
        this.tags.push(val)
        event.target.value = ''
        console.log(this.tags)
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1)
      }
    },
    isAdmin() {
      if (this.isAuthenticated) {
        return this.user.attributes.admin;
      } else return false;
    },
    loadChances() {

      this.is_loading_chances = true;

    },
    loadPerkNamesThenChances() {
      this.is_loading_chances = true;
      this.getSkillNames().then((skill_names) => {
        this.skill_names = skill_names
        this.getPerkNames().then((perk_names) => {
          this.perk_names = perk_names
          this.getChances().then((chances) => {
            this.chance_list = this.chances.concat(chances);
            this.is_loading_chances = false;
          });
        });
      });
    },
    onSearchResult(chance){
      let has_text_search = this.search_result && this.search_result !== "";
      let in_text_search = false

      let has_class_filter = this.class_search_result && this.class_search_result !== "" && this.class_search_result !== "empty";
      let in_class_filter = false

      let has_tags_filter = this.tags && this.tags.length>=1;

      if (has_text_search) {
        in_text_search = chance.chance_key.toLowerCase().includes(this.search_result.toLowerCase())
      }
      if (has_class_filter) {
        in_class_filter = chance.exclusive_class.toLowerCase().includes(this.class_search_result.toLowerCase())
      }
      if (this.non_configured_search){
        if (chance.updated_at != chance.created_at) return false;
      }

      if (this.empty_tags_search){
        if (chance.tags && chance.tags.length != 0) return false;
      } else
      if (has_tags_filter) {
        console.log("Oi")
        if (chance.tags) {
          console.log("Has chance tags")
          for (let i = 0; i < this.tags.length; i++){
            let tag = this.tags[i];
            console.log("tag "+tag)

            if (!chance.tags.includes(tag)){
              return false;
            }
          }
        } else {
          return false;
        }
      }
      let should_skip = (has_text_search && !in_text_search) || (has_class_filter && !in_class_filter)
      if (should_skip) {
        return false;
      }
      return true;
    },
  },
  directives: {
    "v-tippy": directive,
  },
  mixins: [Chance, SkillNameMixin, PerkNameMixin],
  beforeMount() {
    this.loadPerkNamesThenChances();
  },
};
</script>
