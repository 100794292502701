import {get, patch} from './../utils/Request'
export default {
  methods: {
    getSkillLogs(from_class){
      return get("/skill_logs/from-class?class="+from_class)
      .then((response) => response.json());
    },
    showSkillLog(id){
      return get("/skill_logs/"+id)
      .then((response) => response.json());
    },
    updateSkillLogRequest(body ={}) {
      return patch("/skill_logs/", body)
      .then((response) => {

        return response
      })
    }
  },
}
