import {get} from './../utils/Request'
import Cookie from 'js-cookie'

export default {
  methods: {
    getUserWallet(){
      return get("/wallets/user-wallet", {user_id: JSON.parse(Cookie.get("user")).attributes.id})
    },

  },
}